//Original GUI. Load irth.js before it

import {displays} from "./texts";
import Relatives from "../Define/Relatives";
import Mazhab from "../Define/Mazhab";
import HeirsFractions from "../Define/HeirsFractions";
import Fraction from "../../Fraction";
import Preferences from "../Preferences";
import Common from "../Common";
import Environment from "../Define/Environment";
import TestCases from "./TestCases";
import CaseStatus from "../CaseStatus";

class UI {

    static myCase;

    static testCase = -1;

    static resultsWindow;

    static totalUsd = null;


    /**
     *    Places a value in a GUI element
     *    @param    element a GUI element
     *    @param    value value to place in elem
     */
    static outputValue(element, value) {
        //console.log("this.put(" + elem + "," + val + ")\n");
        if (Environment.isModern) {
            document.getElementById(element).innerHTML = value;
        } else if (Environment.isIE) {
            document.all[element].innerText = value;
        }
        this.outputTotalValue(element, value);
    }

    static outputTotalValue(element, share) {
        if (this.totalUsd === null) {
            this.totalUsd = parseInt(document.getElementById('irth-total-value').value);
        }
        const shareTotal = Fraction.calculateFractionValueInUsd(this.totalUsd, share);
        document.getElementById(element + 'usd').innerHTML = shareTotal;
        this.addToResultSummary(element, share, shareTotal);
    }

    static addToResultSummary(element, share, shareTotal) {

        if (share === '') return;


        const resTbl = document.getElementById('res-tbl');
        const elemTd = document.getElementById(element);
        const elemRow = elemTd.closest('tr');

        const cols = elemRow.querySelectorAll('td');

        const newRow = document.createElement('tr');

        const newHeirsCol = document.createElement('td');
        newHeirsCol.textContent = cols[0].textContent;

        newHeirsCol.textContent = newHeirsCol.textContent.replace('Junior', '');

        const newNumCol = document.createElement('td');
        newNumCol.textContent = cols[1].querySelector('input')?.value;

        if (newNumCol.textContent === 'on') {
            newNumCol.textContent = 'Yes';
        }

        const newShareCol = document.createElement('td');
        newShareCol.textContent = share;

        const newTotalCol = document.createElement('td');
        newTotalCol.textContent = shareTotal;

        newRow.append(newHeirsCol);
        newRow.append(newNumCol);
        newRow.append(newShareCol);
        newRow.append(newTotalCol);

        resTbl.querySelector('tbody').append(newRow);

    }

    static clearResTable() {
        const resTbl = document.getElementById('res-tbl');
        resTbl.querySelector('tbody').innerHTML = '';
    }

    /**
     * Populates the UI with values passed via the query string.
     * Format of the query string:
     *   ?hc=<comma-separated counts of heirs>&sc=<0..9>&rv=<0|1|2>&aw=<0|1>&bq=<rational fraction>
     *   e.g., ?hc=0,2,3,1,1,1&sc=3&rv=2&aw=1&bq=1/3
     * Note: It is not necessary to include subsequent heirs whose count is zero.
     */
    static populateFromQueryString() {
        let args = Common.getArgs();
        if (!args) return;
        let hc = args["hc"];
        if (!hc) return;
        let harray = [0];	//handle Relatives.bequest later
        let hca = hc.split(',');
        for (let i = 1; i < hca.length; i++)
            harray.push(hca[i] * 1);	//concat
        let len = harray.length;
        for (let i = len; i <= Relatives.servant; i++)
            harray.push(0);		//fill the rest

        let allowr = true;
        if (args["rv"]) {
            allowr = ((args["rv"] - 0) === 1) || ((args["rv"] - 0) === 2)
        }
        harray.push(allowr);

        let sc = Mazhab.Hanafi;
        if (args["sc"] && (args["sc"] - 0) >= 0 && (args["sc"] - 0) <= Mazhab.numschools) sc = args["sc"] - 0;
        harray.push(sc);

        let allowaw = true;
        if (args["aw"]) {
            allowaw = ((args["aw"] - 0) !== 0);
        }
        harray.push(allowaw);

        //Now handle Relatives.bequest
        let bq = HeirsFractions.none, bqstr = "";
        if (args["bq"]) {
            bqstr = args["bq"];
            bq = Fraction.toRational(bqstr);
            if (Fraction.isGt(bq, HeirsFractions.third)) bq = HeirsFractions.third;
        }
        if (Fraction.isGt(bq, HeirsFractions.none))
            harray[0] = toString(bq);
        else harray[0] = 0;

        this.populateForm(harray);
    }

    /**
     *    Pops an information window
     *    @param    url URL to load in the window. May be a blank
     *    @param    width Width, in pixels, of the window
     *    @param    height Height, in pixels, of the window
     *    @param    left Left position, in pixels, of the window
     *    @param    up Top position, in pixels, of the window
     *    @param    name string, optional. A name to give the window (callers may also use it for document title)
     *    @return    window, a reference to the information window
     */
    static infoWindow(url, width, height, left, up, name) {
        let w = window.open(url, (name ? name : ""),
            "titlebar=0,directories=0,status=0,toolbar=0,location=0,resizable=0,width=" + width + ",height=" + height + ",left=" + left + ",screenX=" + left + ",top=" + up + ",screenY=" + up);
        if (!w.opener) w.opener = window.self;
        return w;
    }

    /**
     *    @return    string: DOCTYPE HTML
     */
    static getDocumentTypeHeader() {
        return "<!DOCTYPE HTML>";
    }

    /**
     *    @return    string: saved from HTML
     */
    static getSavedFromHeader(source) {
        let prefix1 = "<!-- ";
        let prefix2 = "";
        let postfix = source ? source : "";
        let count = (prefix2 + postfix).length;
        return prefix1 + (count > 99 ? "(0" : "(00") + count + ")" + prefix2 + postfix + " -->";
    }

    /**
     *    @return    string: META and LINK HTML tags
     */
    static getMetaTags() {
        let mt = "<meta http-equiv=\"X-UA-Compatible\" content=\"IE=EmulateIE7\"/>";
        mt += "<meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\"/>";
        mt += "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"/>";
        mt += "<link rel=\"shortcut icon\" href=\"./favicon.ico\" type=\"image/x-icon\"/>";
        mt += "<link rel=\"stylesheet\" href=\"./res/result.css\"/>";
        return mt;
    }

    /**
     *    @param    title    string, HTML document title
     *    @return    string TITLE HTML tag
     */
    static getDocTitle(title) {
        return "<title>" + (title ? title : "") + "</title>";
    }

    /**
     *    @param    title string, HTML document title
     *    @return    string HTML HEAD tag, including TITLE
     *    @see    #this.getDocTitle(string)
     */
    static getDocHeader(title) {
        return "<html><head>" + this.getDocTitle(title) + this.getMetaTags() + "</head>";
    }

    /**
     *    @return    string: BODY tag with lang, dir and class attributes
     */
    static getBodyTag() {
        let lang = Preferences.getPageLanguage();
        let dir = Preferences.isPageLanguageDirectionRTL() ? "rtl" : "ltr";
        if (lang)
            return "<body lang=" + lang + " dir=" + dir + " " + displays["_bodyclass_"] + ">";
        else return "<body " + displays["_attrlang_"] + " " + displays["_attrdir_"] + " " + displays["_bodyclass_"] + ">";
    }

    /**
     *    @return    string: BODY and HTML end tags
     */
    static getDocFooter() {
        return "</body></html>";
    }

    /**
     *    Corrects the float according to writing direction
     *    @param    rtl string, "rtl" for right-to-left, or "ltr" for left-to-right (default)
     */
    static floatDirection(rtl) {
        //Correct _floatfix_
        document.body.dir = (rtl ? 'rtl' : 'ltr');
        let floatfix = document.getElementsByClassName('floatfix');
        let alignfix = document.getElementsByClassName('alignfix');
        let copyrightlogofix = document.getElementById('CopyrightLogo');
        if (floatfix && floatfix.length > 0)
            for (let i = 0; i < floatfix.length; i++)
                floatfix[i].style['float'] = (rtl ? 'right' : 'left');
        if (alignfix && alignfix.length > 0)
            for (let i = 0; i < alignfix.length; i++)
                alignfix[i].style['text-align'] = (rtl ? 'right' : 'left');
        if (copyrightlogofix) copyrightlogofix.style['float'] = (rtl ? 'left' : 'right');
    }

    /**
     *    Switch to a supported language
     *    @param    languageCode string, target language code, e.g., "fa" for Farsi
     */
    static switchLanguage(languageCode) {
        Preferences.setTranslationCookie("");
        if ("en" === languageCode || "ar" === languageCode || "id" === languageCode) {
            window.location.href = "../" + languageCode;
        } else {
            Preferences.setTranslationCookie(languageCode);
            let dir = ("fa" === languageCode || "ur" === languageCode || "di" === languageCode || "ug" === languageCode || "ps" === languageCode || "rh" === languageCode || "he" === languageCode);
            window.location.href = "../???/???.html?lang=" + languageCode + (dir ? "&dir=rtl" : "");
        }
    }

    /**
     *    Sets the selected language in language drop-down menu
     *    @param    languageCode string, language code, e.g., "es"
     */
    static setSelectedLanguage(languageCode) {
        let sel = Common.getElement("TranslateSelector");
        for (let i = 0; i < sel.options.length; i++) {
            if (sel.options[i].value === languageCode) {
                sel.options[i].selected = true;
                break;
            }
        }
    }

    /**
     *    Sets direction of text writing and element positioning to "rtl" if so passed in the query string
     */
    static setPageDirection() {
        let lang = Preferences.getPageLanguage();
        if ("en" === lang || "ar" === lang || "id" === lang) { // noinspection UnnecessaryReturnStatementJS
            return;
        }		//already taken care of
        else this.floatDirection(Preferences.isPageLanguageDirectionRTL());
    }

    /**
     *    Initialize the UI. Populate from the query string if enetered
     */
    static pageInit() {
        this.clearForm();
        this.clearPreferences();
        this.clearBequest();
        window.status = displays["_thanks_"];
        Common.getElement("HelpSelector").options.selectedIndex = 0;
        let lang = Preferences.getPageLanguage();
        if (!lang || "" === lang || "en" === lang || "ar" === lang || "id" === lang)
            Preferences.setTranslationCookie("");
        else {
            Preferences.setPageLanguage();
            this.setPageDirection();
            Preferences.setTranslationCookie(lang);
            Common.getElement("HelpSelector").options[3].disabled = true;	//Downlaod
        }
        this.setSelectedLanguage(lang ? lang : "en");
        let classic = Common.getElement("VersionsSelector").options[0];
        let wizard = Common.getElement("VersionsSelector").options[1];
        classic.value += lang ? "?lang=" + lang : "";
        wizard.value += lang ? "?lang=" + lang : "";
        if (Preferences.isPageLanguageDirectionRTL()) {
            classic.value += "&dir=rtl";
            wizard.value += "&dir=rtl";
        }
        if (Environment._JavascriptVersion < 1.1) {
            let w = this.infoWindow("", 480, 400, 160, 150, "BadJavaScript");
            w.document.open();
            let s = this.getDocumentTypeHeader();
            s += this.getSavedFromHeader();
            s += this.getDocHeader(displays["_IRTH2_"]);
            s += this.getBodyTag();
            w.document.write(s);
            w.document.write(displays["_mustJS_"]);
            w.document.write(this.getDocFooter());
            w.document.close();
            w.focus();
        }
        //Equate heights - Didn't work :-(
        let c1 = Common.getElement("Heirs1");
        let c2 = Common.getElement("Heirs2");
        let c3 = Common.getElement("Heirs3");
        let ch = Math.Fraction.max(Math.Fraction.max(c1.offsetHeight, c2.offsetHeight), c3.offsetHeight);
        c1.style.height = ch;
        c2.style.height = ch;
        c3.style.height = ch;
        let c4 = Common.getElement("Prefs1");
        let c5 = Common.getElement("Prefs2");
        let c6 = Common.getElement("Prefs3");
        let cp = Math.Fraction.max(Math.Fraction.max(c4.offsetHeight, c5.offsetHeight), c6.offsetHeight);
        c4.style.height = cp;
        c5.style.height = cp;
        c6.style.height = cp;

        this.populateFromQueryString();	//does nothing if query string is null or incorrectly formatted
        //console.log(reorderCasesHeirs());
    }

    /**
     *    @return    the current leading identifier of the window status message
     */
    static getStatusPrefix() {
        let s = "";
        if (Preferences.school !== 0) s += "[" + Mazhab.schoolNames[Preferences.school] + "]";
        if (Preferences.allowRuddToSpouses === true) s += "[" + displays["_fullrev_"] + "]";
        else if (Preferences.allowRudd === true) s += "[" + displays["_rev_"] + "]";
        else s += "[" + displays["_norev_"] + "]";
        if (Preferences.allowAwl === true) s += "[" + displays["_rediv_"] + "]";
        else s += "[" + displays["_norediv_"] + "]";
        if (UI.myCase.isBequest()) s += "[" + displays["_bequest_"] + "]";
        return s;
    }

    /**
     *    Sets the window status message
     *    @param    s string. The window status message to display
     */
    static setStatus(s) {
        window.status = this.getStatusPrefix() + " " + s;
    }

    /**
     *    Sets variables from input values
     *    @see    #UI.myCase.__lstHeirs__
     *    @see    #UI.myCase.bequestShare
     */
    static readInputData() {

        for (let i = Relatives.son; i < Relatives.treasury; i++) {
            if (i === Relatives.father || i === Relatives.mother || i === Relatives.husband || i === Relatives.wife || i === Relatives.gfather || i === Relatives.gmotherF || i === Relatives.gmotherM) {
                if (document.forms['MeicFrm'].elements['num' + i].checked === true) UI.myCase.__lstHeirs__[i] = 1;
                else UI.myCase.__lstHeirs__[i] = 0;
            } else UI.myCase.__lstHeirs__[i] = document.forms['MeicFrm'].elements['num' + i].value * 1;
        }
        UI.myCase.bequestShare = Fraction.toRational(document.forms['MeicFrm'].elements['bequestField'].value);
        if (Fraction.isGt(UI.myCase.bequestShare, HeirsFractions.none)) {
            UI.myCase.__lstHeirs__[Relatives.bequest] = 0;
        } else {
            UI.myCase.__lstHeirs__[Relatives.bequest] = 0;
        }
        UI.myCase.__lstHeirs__[Relatives.treasury] = 0;
        Common.debug("===>this.readInputData: " + UI.myCase.getHeirList());
        UI.myCase.getHeirRange();
        let schoolSelector = document.forms["PreferencesForm"].elements["SchoolSelector"];
        Preferences.school = schoolSelector.options[schoolSelector.selectedIndex].value;
        Preferences.allowRuddToSpouses = document.forms["PreferencesForm"].elements["Undersub"][1].checked === true;
        Preferences.allowRudd = document.forms["PreferencesForm"].elements["Undersub"][0].checked === true || document.forms["PreferencesForm"].elements["Undersub"][1].checked === true;
        Preferences.allowAwl = document.forms["PreferencesForm"].elements["Oversub"][0].checked === true;
    }

    /**
     *    Corrects user's input values, e.g., non-numeric number of heirs
     */
    static checkInputData() {	//returns boolean
        let i;
        for (i = Relatives.son; i < Relatives.treasury; i++) {
            if (!UI.myCase.__lstHeirs__[i]) UI.myCase.__lstHeirs__[i] = 0;
            if (isNaN(UI.myCase.__lstHeirs__[i])) {
                console.error("Non-numeric input next to " + UI.myCase.formatHeir(i) + " corrected to 0\n");
                Common.detail("<b>Correction:</b>Non-numeric number of " + UI.myCase.formatHeir(i) + " corrected to 0");
                UI.myCase.__lstHeirs__[i] = 0;
                document.forms['MeicFrm'].elements["num" + i].value = 0;
                //Fault tolerance. Don't return false
            } else if ((UI.myCase.__lstHeirs__[i] > 1) && ((i === Relatives.husband) || (i === Relatives.father) || (i === Relatives.mother) || (i === Relatives.gfather) ||
                (i === Relatives.gmotherF) || (i === Relatives.gmotherM))) {
                UI.myCase.__lstHeirs__[i] = 1;
                Common.detail(UI.myCase.formatHeir(i) + ": " + displays["_correct1_"]);
                document.forms['MeicFrm'].elements["num" + i].value = 1;
            }//Redundant now that these input fields are checkboxes
        }
        if ((UI.myCase.__lstHeirs__[Relatives.husband] > 0) && (UI.myCase.__lstHeirs__[Relatives.wife] > 0)) {
            UI.myCase.__lstHeirs__[Relatives.wife] = 0;
            Common.detail(UI.myCase.formatHeir(i) + ": " + displays["_correct0_"]);
            document.forms['MeicFrm'].elements['num5'].value = 0;
        }//Redundant now that the two fields are checkboxes that act like radio buttons
        if (UI.myCase.__lstHeirs__[Relatives.wife] > 4) {
            UI.myCase.__lstHeirs__[Relatives.wife] = 4;
            Common.detail(UI.myCase.formatHeir(i) + ": " + displays["_correct4_"]);
            document.forms['MeicFrm'].elements['num6'].value = 4;
        }//Only happens with programmatic entry, e.g., test cases

        if (Fraction.isNegative(UI.myCase.bequestShare)) UI.myCase.bequestShare = HeirsFractions.none;
        if (Fraction.isGt(UI.myCase.bequestShare, HeirsFractions.third)) {
            UI.myCase.bequestShare = HeirsFractions.third;
            Common.detail(UI.myCase.formatHeir(i) + ": " + displays["_correct1-3_"]);
        }
        return true;
    }

    /**
     * Event handler for selecting a reversion rule based on the chosen juristic school.
     */
    static setRudd() {
        if (document.forms['PreferencesForm'].elements['schoolSelector'].value === Mazhab.Maliki || document.forms['PreferencesForm'].elements['schoolSelector'].value === Mazhab.Zahiri)
            document.forms['PreferencesForm'].elements['Undersub'][2].checked = true;
        else document.forms['PreferencesForm'].elements['Undersub'][0].checked = true;
    }

    /**
     *    Event handler for selecting an oversubscription rule
     *    based of the selection of a juristic Preferences.school
     */
    static setAwl() {
        if (document.forms['PreferencesForm'].elements['schoolSelector'].value === Mazhab.Zahiri || document.forms['PreferencesForm'].elements['schoolSelector'].value === Mazhab.Jaafari)
            document.forms['PreferencesForm'].elements['Oversub'][1].checked = true;
        else document.forms['PreferencesForm'].elements['Oversub'][0].checked = true;
    }

    /**
     *    @return    a query string representation of the case at hand. Useful for reloading after screen size change
     */
    static caseToQueryString() {
        this.readInputData();
        let hc = "hc=0";	//First element is Relatives.bequest and always 0
        let sc = Preferences.school;
        let rv = (Preferences.allowRuddToSpouses ? 2 : (Preferences.allowRudd ? 1 : 0));
        let aw = (Preferences.allowAwl ? 1 : 0);
        let bq = !Fraction.isZero(UI.myCase.bequestShare) ? toString(UI.myCase.bequestShare) : "0";
        for (let h = Relatives.son; h < Relatives.treasury; h++)
            hc += "," + UI.myCase.__lstHeirs__[h];
        hc += "&sc=" + sc;
        hc += "&rv=" + rv;
        hc += "&aw=" + aw;
        hc += "&bq=" + bq;
        return hc;
    }

    /**
     *    Show a help dialog that explains what "junior" means in the context of grandmothers
     */
    static showGmGen() {
        let w = this.infoWindow("gmgen.html", "GmGenWindow", 480, 400, 160, 150);
        if (typeof window.focus != "undefined") w.focus();
    }

    /**
     *    Gets which grandmother is junior to the other, if they're not of the same generation
     *    @return    1, if the Relatives.mother of Relatives.father (or higher) is junior to the Relatives.mother of Relatives.mother,
     *            2, if it's the other way around, or
     *            0, if both are of the same generation (the default)
     */
    static getGrandMaGeneration() {
        let g = 0;
        if (document.forms['MeicFrm'].elements['gmfy'].checked) g = 1;
        if (document.forms['MeicFrm'].elements['gmmy'].checked) g = 2;
        Common.debug("GM level = " + g);
        return g;
    }

    /**
     *    @return    string: html code for the ruler line used as a table output header for displayed test cases
     */
    static getTableHeader() {
        return "<tr style='font-weight: bold'><td>&nbsp;</td><td>" + displays["_tabCase_"] + "</td><td>" + displays["_tabSn_"] + "</td><td>" + displays["_tabDr_"] + "</td><td>" + displays["_tabFr_"] + "</td><td>" + displays["_tabMr_"] + "</td><td>" + displays["_tabHb_"] + "</td><td>" + displays["_tabWf_"] + "</td><td>" + displays["_tabBr_"] + "</td><td>" + displays["_tabSr_"] + "</td><td>" + displays["_tabSm_"] + "</td><td>" + displays["_tabSs_"] + "</td><td>" + displays["_tabDs_"] + "</td><td>" + displays["_tabGF_"] + "</td><td>" + displays["_tabGf_"] + "</td><td>" + displays["_tabUn_"] + "</td><td>" + displays["_tabUf_"] + "</td><td>" + displays["_tabBf_"] + "</td><td>" + displays["_tabSf_"] + "</td><td>" + displays["_tabOr_"] + "</td><td>" + displays["_tabNu_"] + "</td><td>" + displays["_tabNf_"] + "</td><td>" + displays["_tabGm_"] + "</td><td>" + displays["_tabCz_"] + "</td><td>" + displays["_tabCf_"] + "</td><td>" + displays["_tabSv_"] + "</td><td>" + displays["_tabTr_"] + "</td><td>" + displays["_tabSc_"] + "</td><td>" + displays["_tabRv_"] + "</td><td>" + displays["_tabAw_"] + "</td><td>" + displays["_tabBq_"] + "</td></tr>";
    }

    /**
     *    HTML header of tabulated results document
     *    @return    string: html code
     *    @see    #this.getTableHeader()
     */
    static getTabulatedPageHeader() {
        return this.getDocumentTypeHeader() +
            this.getSavedFromHeader() +
            this.getDocHeader(displays["_Results_"]) +
            this.getBodyTag() +
            displays['_improvetranslation_'] +
            displays['_autotranslatedetails_'] +
            displays['_RTLarrows_'] +
            "<table border='1' style='overflow-x:auto;'" +
            ">" +
            this.getTableHeader();
    }

    /**
     *    Get html code for a row in a tabulated results document
     *    @param    __case__ int, case number
     *    @return    string html code
     */
    static getTabulatedRow(__case__) {
        let multiplier;
        //First row: Heirs and preferences
        let results = "<tr style='background: #fffacd'><td>" + displays["_tabHeirs_"] + "</td>";
        results += "<td align='right'>" + (__case__ || __case__ >= 0 ? (__case__ + 1) : "&nbsp;") + "</td>";
        for (let i = Relatives.son; i < Relatives.treasury; i++) {
            if (UI.myCase.__lstHeirs__[i] > 0) results += "<td align='right'>" + UI.myCase.__lstHeirs__[i] + "</td>";
            else results += "<td align='right'>&nbsp;</td>";
        }
        //Treasury
        results += "<td>&nbsp;</td>";
        //School, Rv, Aw, Bq
        results += "<td>" + Mazhab.schoolNames[Preferences.school] + "</td>";
        let rev;
        if (Preferences.allowRuddToSpouses === true) rev = displays["_tabFull_"];
        else if (Preferences.allowRudd === true) rev = displays["_tabYes_"];
        else rev = displays["_tabNo_"];
        results += "<td>" + rev + "</td>";
        let awl;
        if (Preferences.allowAwl === true) awl = displays["_tabYes_"];
        else awl = displays["_tabNo_"];
        results += "<td>" + awl + "</td>";
        if (Fraction.isZero(UI.myCase.bequestShare)) results += "<td>0</td>";
        else if (Fraction.isOne(UI.myCase.bequestShare)) results += "<td>" + displays["_tabALL_"] + "</td>";
        else results += "<td>" + toString(UI.myCase.bequestShare) + "</td>";
        results += "</tr>";
        //Second row: Shares
        results += "<tr><td>" + displays["_tabShares_"] + "</td>";
        results += "<td>&nbsp;</td>";
        for (let i = Relatives.son; i < Relatives.treasury; i++) {
            if (!Fraction.isValidRational(UI.myCase.has(i))) console.warn(__case__ + ":" + UI.myCase.has(i));
            if (UI.myCase.__lstHeirs__[i] > 0) multiplier = new Fraction(1, UI.myCase.__lstHeirs__[i]);
            else multiplier = HeirsFractions.whole;
            if (Fraction.isZero(UI.myCase.has(i))) {
                if (UI.myCase.__lstHeirs__[i] > 0) results += "<td align='right'>0</td>";
                else results += "<td align='right'>&nbsp;</td>";
            } else if (Fraction.isOne(UI.myCase.has(i))) results += "<td>" + displays["_tabALL_"] + "</td>";
            else results += "<td align='right'>" + toString(Fraction.multiply(UI.myCase.has(i), multiplier)) + "</td>";
        }
        if (Fraction.isZero(UI.myCase.has(Relatives.treasury))) results += "<td>0</td>";
        else if (Fraction.isOne(UI.myCase.has(Relatives.treasury))) results += "<td>" + displays["_tabALL_"] + "</td>";
        else results += "<td>" + toString(UI.myCase.has(Relatives.treasury)) + "</td>";
        results += "<td colspan='4'>&nbsp;</td></tr>";
        return results;
    }

    /**
     *    Get the html code for the footer of a tabulated results document
     *    @return    string: html code
     */
    static getTabulatedPageFooter() {
        return "</table><br/></body></html>";
    }

    /**
     *    Whether this.resultsWindow exists, is open and its document is ready for writing
     *    @return    boolean, true if all conditions are true
     **/
    static isResultsWindowOpen() {
        return (this.resultsWindow != null && this.resultsWindow.closed === false && this.resultsWindow.document);
    }

    /**
     *    Whether this.resultsWindow does not exist, is closed or its document is not available for writing
     *    @return    boolean, true if any of the conditions is true
     **/
    static isResultsWindowClosed() {
        return (this.resultsWindow === null || this.resultsWindow?.closed === true || !this.resultsWindow?.document)
    }

    /**
     *    Ensure this.resultsWindow exists, is open and its document is ready to be written
     **/
    static openResultsWindow(title) {
        if (this.isResultsWindowClosed())
            this.resultsWindow = window.open("", title);
        if (this.resultsWindow.document) this.resultsWindow.document.open();
    }

    /**
     *    Ensure this.resultsWindow and its document are closed and the window is annulled
     **/
    static closeResultsWindow() {
        if (this.isResultsWindowOpen()) {
            if (this.resultsWindow.document) this.resultsWindow.document.close();
            this.resultsWindow.close();
            this.resultsWindow = null;
        }
    }

    /**
     *    @param    textToLog string, HTML text to log
     *    @return    string an empty string. This is so that textToLog can be reset for the caller
     */
    static logPeriodically(textToLog) {
        if (this.isResultsWindowClosed()) {
            this.openResultsWindow(displays["_Results_"]);
            this.resultsWindow.document.write(this.getTabulatedPageHeader());
        }
        this.resultsWindow.document.write((textToLog ? textToLog : ""));
        return "";	//Usage: results = this.logPeriodically(results);
    }

    /**
     *    Run a range of test cases
     *    @param    c1 int, beginning case number
     *    @param    c2 int, ending case number
     *    @param    verbose boolean. True if details are included. Default false
     */
    static runTests(c1, c2, verbose) {
        if (this.isResultsWindowClosed()) {
            this.openResultsWindow(displays["_Results_"]);
            this.resultsWindow.document.write(this.getTabulatedPageHeader());
        }
        let results = this.logPeriodically();	//opens this.resultsWindow if closed or null and resets results
        let ci = 0;
        for (let c = c1; c <= c2; c++) {
            Common.detail("<br/><b>" + displays["_testcase_"] + c + "</b>");
            UI.myCase = new CaseStatus();
            let carray = TestCases.getTestCase(c);
            Common.debug(carray);
            //Array of 25 to 28 elements starting with element 0 for Relatives.bequest
            //Element 25 is not for Relatives.treasury because it's always 0, rather it's for Rv (default 1)
            //Element 26 is for Sc (default 1) and element 27 is Aw (default 1)
            for (let i = Relatives.son; i < Relatives.treasury; i++) {	//1..24
                UI.myCase.__lstHeirs__[i] = carray[i] - 0;
            }
            UI.myCase.__lstHeirs__[Relatives.bequest] = 0;
            UI.myCase.__lstHeirs__[Relatives.treasury] = 0;
            UI.myCase.deprive(Relatives.treasury);
            Common.debug("UI.myCase.__lstHeirs__ = " + UI.myCase.__lstHeirs__.join());
            if (carray.length > (Relatives.servant + 3)) {
                //This element(27) is reserved for aw
                Preferences.allowAwl = carray[(Relatives.servant + 3)] !== 0;
            } else {
                Preferences.allowAwl = true;
            }
            if (carray.length > (Relatives.servant + 2)) Preferences.school = carray[Relatives.servant + 2] - 0;
            //This element (26) is reserved for Sc
            else Preferences.school = Mazhab.Hanafi;
            if (carray.length > (Relatives.servant + 1)) {
                //This element (25) is reserved for Rv (since Relatives.treasury is always 0)
                Preferences.allowRuddToSpouses = carray[(Relatives.servant + 1)] > 1;
                Preferences.allowRudd = carray[(Relatives.servant + 1)] > 0;
            } else {
                Preferences.allowRuddToSpouses = false;
                Preferences.allowRudd = true;
            }
            if (carray[0] !== 0) {
                UI.myCase.bequestShare = Fraction.toRational(carray[0]);
            } else {
                UI.myCase.bequestShare = HeirsFractions.none;
            }

            Common.detail(displays["_Heirs_"] + ": " + UI.myCase.getHeirList());
            Common.detail(displays["_School_"] + ": " + Mazhab.schoolNames[Preferences.school]);
            Common.detail(displays["_Reversion_"] + ": " + (Preferences.allowRudd === true ? displays["_allowed_"] : displays["_notallowed_"]));
            Common.detail(displays["_Reversion-s_"] + ": " + (Preferences.allowRuddToSpouses === true ? displays["_allowed_"] : displays["_notallowed_"]));
            Common.detail(displays["_Redivision_"] + ": " + (Preferences.allowAwl === true ? displays["_allowed_"] : displays["_notallowed_"]));
            Common.detail(displays["_Bequest_"] + ": " + toString(UI.myCase.bequestShare));

            if (UI.myCase.calculateHeirsShares()) {
                //UI.myCase.sum = HeirsFractions.whole, so make sure any -ve share marks left are voided
                for (let i = Relatives.son; i <= Relatives.treasury; i++) {
                    if (UI.myCase.__lstHeirs__[i] > 0 && Fraction.isNegative(UI.myCase.has(i))) UI.myCase.gets(i, HeirsFractions.none);
                }
            }

            results += this.getTabulatedRow(c);
            if (++ci % 10 === 0) results += this.getTableHeader();
            results = this.logPeriodically(results);		//Empties results after logging
            Common.debug("Processed test case #" + c);
        }
        results += "<tr><td colspan='31'>" + displays["_tabLegend_"] + "</td></tr>";
        results += "</table>";
        if (verbose) results += Common.debugText;
        results += this.getDocFooter();
        // noinspection JSUnusedAssignment
        results = this.logPeriodically(results);

        if (this.resultsWindow.document) this.resultsWindow.document.close();
        if (typeof this.resultsWindow.focus != "undefined") this.resultsWindow.focus();
        document.forms['MeicFrm'].elements['DetailsButton'].disabled = false;
    }

    /**
     *    Run all the test cases in terse mode
     *    @see    #this.runTests(int,int,boolean)
     */
    static runAllTests() {
        this.runTests(0, TestCases.lastCase(), false);
    }

    /**
     *    Display share distribution in the detailed results window in tabular form
     */
    static outputSharesResultTabulated() {
        if (this.isResultsWindowClosed()) {
            this.openResultsWindow(displays["_Results_"]);
        } else if (this.resultsWindow.document) this.resultsWindow.document.open();
        let results = this.getTabulatedPageHeader() +
            this.getTabulatedRow() +
            this.getTabulatedPageFooter();

        this.resultsWindow.document.write(results);
        this.resultsWindow.document.close();
        document.forms['MeicFrm'].elements['DetailsButton'].disabled = false;
    }

    static getResultsDocumentHeader() {
        return this.getDocumentTypeHeader() +
            this.getSavedFromHeader() +
            this.getDocHeader(displays["_Results_"]) +
            this.getBodyTag();
    }

    /**
     *    Display share distribution in a Common.detail document on another window
     */
    static displayResultExplanationNS() {		//Details window. Also, older, non-IE browsers
        if (this.isResultsWindowClosed()) {
            this.openResultsWindow(displays["_Results_"]);
            //this.resultsWindow.document.open();	//already done by this.openResultsWindow()
        } else {
            this.resultsWindow.document.open();	//clears any content
        }
        let results = this.getResultsDocumentHeader();
        results += displays['_autotranslatedetails_'];
        results += displays['_improvetranslation_'];
        results += displays['_RTLarrows_'];
        console.log(results);
        results += "<b>" + displays["_START_"] + "</b><br/>";
        results += "<h2>" + displays["_THEHEIRS_"] + "</h2>";
        results += UI.myCase.getHeirList() + "<br/>";

        results += "<h2>" + displays["_Preferences_"] + "</h2>";
        results += displays["_School_"] + ": " + Mazhab.schoolNames[Preferences.school] + "<br/>";
        results += displays["_Reversion_"] + ": " + (Preferences.allowRudd === true ? displays["_allowed_"] : displays["_notallowed_"]) + "<br/>";
        results += displays["_Reversion-s_"] + ": " + (Preferences.allowRuddToSpouses === true ? displays["_allowed_"] : displays["_notallowed_"]) + "<br/>";
        results += displays["_Redivision_"] + ": " + (Preferences.allowAwl === true ? displays["_allowed_"] : displays["_notallowed_"]) + "<br/>";
        results += displays["_Bequest_"] + ": " + UI.myCase.toLangFraction(UI.myCase.bequestShare) + "<br/>";

        results += "<h2>" + displays["_DISTRIBUTION_"] + "</h2>";
        results += displays["_asfollows_"] + displays["_comma_"] + "<br/>";
        let multiplier = HeirsFractions.whole;
        let num = 0;
        for (let i = Relatives.son; i < Relatives.treasury; i++) {
            num = UI.myCase.__lstHeirs__[i] - 0;
            if (num > 1) {
                if (num === 2) {
                    if (Fraction.isZero(UI.myCase.has(i))) {
                        results += "<b>" +
                            UI.myCase.formatHeir(i, true) +
                            "</b> " +
                            UI.myCase.formatWord("get0", 2, UI.myCase.getHeirGender(i)) +
                            "<br/>";
                    } else {
                        results += "<b/>" +
                            UI.myCase.formatHeir(i, true, '') +
                            "</b> " +
                            UI.myCase.formatWord("get", 2, UI.myCase.getHeirGender(i)) +
                            " <b>" +
                            UI.myCase.toLangFraction(Fraction.multiply(UI.myCase.has(i), HeirsFractions.half)) +
                            "</b> " +
                            UI.myCase.formatWord("each", 2, UI.myCase.getHeirGender(i)) +
                            "<br/>";
                    }
                } else {
                    if (Fraction.isZero(UI.myCase.has(i))) {
                        results += "<b>" +
                            UI.myCase.formatHeir(i, true, '') +
                            "</b> " +
                            UI.myCase.formatWord("get0", num, UI.myCase.getHeirGender(i)) +
                            "<br/>";
                    } else {
                        multiplier = new Fraction(1, num);
                        results += "<b>" + (num) + " " +
                            UI.myCase.formatHeir(i, false, '') +
                            "</b> " +
                            UI.myCase.formatWord("get", num, UI.myCase.getHeirGender(i)) +
                            " <b>" +
                            UI.myCase.toLangFraction(Fraction.multiply(UI.myCase.has(i), multiplier)) +
                            "</b> " +
                            UI.myCase.formatWord("each", num, UI.myCase.getHeirGender(i)) +
                            "<br/>";
                    }
                }
            } else if (num === 1)
                if (Fraction.isZero(UI.myCase.has(i))) {
                    results += "<b>" +
                        UI.myCase.formatHeir(i, true, '') +
                        "</b> " +
                        UI.myCase.formatWord("gets0", 1, UI.myCase.getHeirGender(i)) +
                        "<br/>";
                } else {
                    results += "<b>" +
                        UI.myCase.formatHeir(i, true, '') +
                        "</b> " +
                        UI.myCase.formatWord("gets", 1, UI.myCase.getHeirGender(i)) +
                        " <b>" +
                        UI.myCase.toLangFraction(UI.myCase.has(i)) +
                        "</b><br/>";
                }
        }
        if (!Fraction.isZero(UI.myCase.has(Relatives.treasury))) {
            results += "<b>" +
                displays["_Treasury_"] +
                "</b> " +
                displays["_gets_"] +
                " <b>" +
                UI.myCase.toLangFraction(UI.myCase.has(Relatives.treasury)) +
                "</b><br/>";
        }
        if (!Fraction.isZero(UI.myCase.bequestShare)) {
            results += "<b>" +
                displays["_Bequest_"] +
                ":</b> " +
                " <b>" +
                UI.myCase.toLangFraction(UI.myCase.bequestShare) +
                "</b><br/>";
        }

        results += Common.debugText;
        results += "<b>" + displays["_END_"] + "</b><br/><br/>";

        //this.resultsWindow.document.open();
        this.resultsWindow.document.write(results);
        this.resultsWindow.document.write("</body></html>");
        this.resultsWindow.document.close();
        if (typeof this.resultsWindow.focus != "undefined") this.resultsWindow.focus();
    }

    /**
     *    Display share distribution on the main window
     */
    static outputSharesResult() {
        this.clearShares();
        //this.displayResultExplanationNS();
        //document.forms['MeicFrm'].elements['DetailsButton'].disabled = false;
        if (Environment.isModern || Environment.isIE) {
            let multiplier = HeirsFractions.whole;
            for (let i = Relatives.son; i < Relatives.treasury; i++) {
                if ((UI.myCase.__lstHeirs__[i] - 0) > 1) {
                    multiplier = new Fraction(1, UI.myCase.__lstHeirs__[i]);
                    if (i === Relatives.wife) this.outputValue('get' + i, UI.myCase.toLangFraction(UI.myCase.has(i)));
                    else if (Fraction.isZero(UI.myCase.has(i)))
                        this.outputValue('get' + i, UI.myCase.formatWord("noshare", UI.myCase.__lstHeirs__[i], UI.myCase.getHeirGender(i)));
                    else
                        this.outputValue('get' + i, UI.myCase.toLangFraction(Fraction.multiply(UI.myCase.has(i), multiplier)) + " " +
                            UI.myCase.formatWord("each", UI.myCase.__lstHeirs__[i], UI.myCase.getHeirGender(i)));
                } else if (UI.myCase.__lstHeirs__[i] === 1)
                    if (Fraction.isZero(UI.myCase.has(i)))
                        this.outputValue('get' + i, UI.myCase.formatWord("noshare", 1, UI.myCase.getHeirGender(i)));
                    else this.outputValue('get' + i, UI.myCase.toLangFraction(UI.myCase.has(i)));
                else this.outputValue('get' + i, "");
            }
            if (!Fraction.isZero(UI.myCase.bequestShare)) {
                document.forms['MeicFrm'].elements['bequestField'].value = Fraction.toString(UI.myCase.bequestShare);
                UI.outputTotalValue('bequest1', document.forms['MeicFrm'].elements['bequestField'].value);
            }
            if (!Fraction.isZero(UI.myCase.has(Relatives.treasury)))
                this.outputValue('get' + Relatives.treasury, UI.myCase.toLangFraction(UI.myCase.has(Relatives.treasury)));
        }
        window.focus();
    }

    /**
     *    Calculate button
     */
    static calculate() {
        Common.debugText = "";
        Common.detail("<h2>" + displays["_Details_"] + "</h2>");
        Common.detail("<b>" + displays["_Initializing_"] + "</b>");
        UI.myCase = new CaseStatus();
        Common.detail("<b>" + displays["_Collecting_"] + "</b>");
        this.readInputData();		//populates UI.myCase.__lstHeirs__[] from num input array
        if (this.checkInputData()) {
            Common.detail("<b>" + displays["_Calculating_"] + "</b>");
            if (UI.myCase.assertTrue(UI.myCase.calculateHeirsShares(), displays["_calcerror_"])) {
                this.setStatus(displays["_calcok_"]);
            } else {
                this.setStatus(displays["_calcnotok_"]);
            }
            Common.detail("<b>" + displays["_Displaying_"] + "</b>");
            this.outputSharesResult();
            return true;
        } else {
            alert(displays["_inputinvalid_"]);
            return false;
        }
    }

    /**
     *    Erase input in the heir category columns
     */
    static clearHeirs() {
        Common.debug("===>this.clearHeirs()");
        for (let j = Relatives.son; j < Relatives.treasury; j++) {
            if (j === Relatives.father || j === Relatives.mother || j === Relatives.husband || j === Relatives.wife || j === Relatives.gfather || j === Relatives.gmotherF || j === Relatives.gmotherM)
                document.forms['MeicFrm'].elements['num' + j].checked = false;
            else document.forms['MeicFrm'].elements['num' + j].value = "";
        }
        document.forms['MeicFrm'].elements['gmfy'].checked = false;
        document.forms['MeicFrm'].elements['gmmy'].checked = false;
    }

    /**
     *    Erase input in the Relatives.bequest fraction cell
     */
    static clearBequest() {
        document.forms['MeicFrm'].elements['bequestField'].value = "";
    }

    /**
     *    Erase displayed output in the main window of share distribution
     */
    static clearShares() {
        if (Environment.isModern || Environment.isIE) {
            for (let i = Relatives.son; i <= Relatives.treasury; i++) {
                this.outputValue('get' + i, "");
            }
            this.clearBequest();
            Common.debug("Shares cleared");
        }
    }

    /**
     *    Reset the preference of juristic Preferences.school, and oversubscription and under-subscription rules
     */
    static clearPreferences() {
        document.forms['PreferencesForm'].elements['SchoolSelector'][0].checked = true;
        document.forms['PreferencesForm'].elements['Undersub'][0].checked = true;
        document.forms['PreferencesForm'].elements['Oversub'][0].checked = true;
        Common.debug("Preferences reset");
    }

    /**
     *    Erase all inputs on the main form
     */
    static clearForm() {
        this.clearHeirs();
        this.clearShares();
        document.forms['MeicFrm'].elements['TestCaseSelector'].selectedIndex = 0;
        if (Environment.isModern || Environment.isIE) this.outputValue('caseNum', "");
        else document.forms['MeicFrm'].elements['caseNum'].value = "";
        document.forms['MeicFrm'].elements['DetailsButton'].disabled = true;
        this.clearPreferences();
        this.closeResultsWindow();
        //location.search = "";		//causes constant reloading!
        Common.debug("Form cleared");
        //console.clear();
    }

    /**
     * Populate the forms from an array.
     * @param heirCase Array of strings with a length of 25 to 28:
     *           Element 0: Relatives.bequest, e.g., "3/4" or "0".
     *           Elements 1 to 24: Number of heirs in each heir category in the order displayed on the main window.
     *           Element 25: Reversion rule, where 1 represents named heirs except spouse, 2 represents all named heirs, and 0 represents none.
     *           Element 26: Juristic Preferences.school, ranging from 0 to 8, representing different juristic schools (HeirsFractions.none, Mazhab.Hanafi, Mazhab.Maliki, Mazhab.Shafii, Mazhab.Hanbali, Mazhab.Egypt, Mazhab.Zahiri, Mazhab.Jaafari, Mazhab.Ibadhi).
     *           Element 27: Oversubscription rule, where 0 represents consensus and 1 represents Mazhab.Zahiri/Mazhab.Jaafari.
     */
    static populateForm(heirCase) {
        let harray = heirCase;
        for (let h = 1; h < heirCase.length; h++) {
            if (isNaN(heirCase[h])) harray[h] = 0;
            else if (heirCase[h] < 0) harray[h] = 0;
            else harray[h] = heirCase[h];
        }
        harray[0] = heirCase[0];	//It can be a string representing a rational fraction
        for (let i = Relatives.son; i <= Relatives.servant && i < harray.length; i++)
            if (i === Relatives.father || i === Relatives.mother || i === Relatives.husband || i === Relatives.wife || i === Relatives.gfather || i === Relatives.gmotherF || i === Relatives.gmotherM)
                document.forms['MeicFrm'].elements['num' + i].checked = ((harray[i] - 0) > 0);
            else if ((harray[i] - 0) > 0)
                document.forms['MeicFrm'].elements['num' + i].value = harray[i];
            else document.forms['MeicFrm'].elements['num' + i].value = "";
        if (harray[0] !== 0)
            document.forms['MeicFrm'].elements['bequestField'].value = harray[0];
        else document.forms['MeicFrm'].elements['bequestField'].value = "";
        if (harray.length > (Relatives.servant + 3)) {	//27=Aw
            if (harray[Relatives.servant + 3] === 0) //No Awl
                document.forms['PreferencesForm'].elements['Oversub'][1].checked = true;
            else document.forms['PreferencesForm'].elements['Oversub'][0].checked = true;
        } else if (harray.length > (Relatives.servant + 2)) {	//26=Sc
            if ((harray[Relatives.servant + 2] >= 0 && harray[Relatives.servant + 2]) <= Mazhab.numschools)
                document.forms['PreferencesForm'].elements['SchoolSelector'].value = harray[Relatives.servant + 2];
            else document.forms['PreferencesForm'].elements['SchoolSelector'].value = Mazhab.Hanafi;
        } else if (harray.length > (Relatives.servant + 1)) {	//25=Rv
            if (harray[Relatives.servant + 1] === 2)	//Spouse too
                document.forms['PreferencesForm'].elements['Undersub'][1].checked = true;
            else if (harray[Relatives.servant + 1] === 0)	//No rudd
                document.forms['PreferencesForm'].elements['Undersub'][2].checked = true;
            else document.forms['PreferencesForm'].elements['Undersub'][0].checked = true;
        }
        Common.debug("Form populated from array");
    }

    /**
     *    Update the enablement of the forward and backward test buttons
     */
    static updateTestButtons() {
        if (this.testCase <= 0)
            document.forms['MeicFrm'].elements['PreviousTestButton'].disabled = true;
        else if (this.testCase >= TestCases.lastCase())
            document.forms['MeicFrm'].elements['NextTestButton'].disabled = true;
        else {
            document.forms['MeicFrm'].elements['PreviousTestButton'].disabled = false;
            document.forms['MeicFrm'].elements['NextTestButton'].disabled = false;
        }
        if (Environment.isModern || Environment.isIE) {
            if (this.testCase >= 0 && this.testCase <= TestCases.lastCase())
                this.outputValue('caseNum', displays["_Case_"] + " " + (this.testCase + 1) + " " + displays["_of_"] + " " + (TestCases.lastCase() + 1));
            else this.outputValue('caseNum', "");
        } else {
            if (this.testCase >= 0 && this.testCase <= TestCases.lastCase())
                document.forms['MeicFrm'].elements['caseNum'].value = displays["_Case_"] + " " + (this.testCase + 1) + displays["_of_"] + " " + (TestCases.lastCase() + 1);
            else document.forms['MeicFrm'].elements['caseNum'].value = "";
        }
    }

    /**
     *    Populate the forms from a test case string
     *    @param    caseNumber int, the test case number
     */
    static populateFromCase(caseNumber) {
        let harray = TestCases.getTestCase(caseNumber);
        this.populateForm(harray);
        Common.debug("Form populated from case #" + caseNumber + ":" + UI.myCase.getHeirList());
        this.updateTestButtons();
    }

    /**
     *    Create an array of randomly generated numbers of heirs in each category
     */
    static generateRandomHeirs() {
        let harray = new Array(Relatives.servant + 4);	//24:Relatives.servant, 25:rv replacing Relatives.treasury, 26:sc, 27:aw.
        harray[0] = 0; //Unnecessary for a random heirs test to specify a Relatives.bequest
        for (let i = Relatives.son; i <= Relatives.servant; i++) {
            if (i === Relatives.father || i === Relatives.husband || i === Relatives.mother || i === Relatives.gfather || i === Relatives.gmotherF || i === Relatives.gmotherM)
                harray[i] = Math.round(Math.random()); //0-1
            else if (i === Relatives.wife)
                harray[i] = Math.floor(Math.random() * 5); //0-4
            else harray[i] = Math.floor(Math.random() * 8); //0-7
        }
        if ((harray[Relatives.husband] > 0) && (harray[Relatives.wife] > 0)) {
            if (harray[Relatives.wife] > harray[Relatives.husband]) harray[Relatives.husband] = 0;
            else harray[Relatives.wife] = 0;
        }
        harray[Relatives.servant + 1] = Math.floor(Math.random() * 3); //rv(25)=0-2;
        harray[Relatives.servant + 2] = Math.floor(Math.random() * 10); //sc(26)=0-9;
        harray[Relatives.servant + 3] = Math.round(Math.random()); //aw(27)=0-1;
        return harray;
    }

    /**
     *    Populate the main form with randomly generated numbers of heirs in each category
     *    @see    #this.generateRandomHeirs()
     */
    static populateRandomly() {
        let harray = this.generateRandomHeirs();
        this.populateForm(harray);
    }

    /**
     *    Populate the forms from a test case string then this.calculate the UI.myCase.shares and display the,
     *    @param    caseNumber int, the test case number
     *    @see    #this.populateFromCase(int)
     *    @see    #this.calculate()
     */
    static calculateTestCase(caseNumber) {
        Common.debug("===>this.calculateTestCase(" + caseNumber + ")");
        this.clearForm();
        UI.myCase = new CaseStatus();
        this.populateFromCase(caseNumber);
        this.calculate();
        this.testCase = caseNumber;
        this.updateTestButtons();
    }

    /**
     *    Calculate the previous case to the Fraction.one just calculated
     *    @see    #this.calculateTestCase(int)
     */
    static previousTest() {
        if (this.testCase > 0) this.calculateTestCase(this.testCase - 1);
    }

    /**
     *    Calculate the next case to the Fraction.one just calculated
     *    @see    #this.calculateTestCase(int)
     */
    static nextTest() {
        if (this.testCase < TestCases.lastCase()) this.calculateTestCase(this.testCase + 1);
    }

    /**
     *    Load a randomly selected test case and run it
     *    @see    #this.populateRandomly()
     */
    static randomTest() {
        this.clearForm();
        UI.myCase = new CaseStatus();
        this.populateRandomly();
        Common.debug("===>Random test: " + UI.myCase.getHeirList());
        this.calculate();
    }

    /**
     *    Load a selected test case suite and run it
     *    @param    tc int, the test case to load
     *    @see    #this.runTests(int,int,boolean)
     *    @see    #this.calculateTestCase(int)
     */
    static showTestCase(tc) {
        Common.debug("Test case #" + tc);
        if (tc === -2) this.runTests(0, 2);					//Abu-Bakr
        else if (tc === -3) this.runTests(3, 4);				//Omaria
        else if (tc === -4) this.calculateTestCase(5);		//Minbaria
        else if (tc === -5) this.calculateTestCase(6);		//Shuraih
        else if (tc === -6) this.calculateTestCase(7);		//Mazhab.Shafii
        else if (tc === -7) this.calculateTestCase(8);		//Kharqaa
        else if (tc === -8) this.runTests(9, 11);			//Malikia
        else if (tc === -9) this.calculateTestCase(12);		//Pseudo-Malikia
        else if (tc === -10) this.runTests(13, 20);			//Akdaria
        else if (tc === -11) this.calculateTestCase(21);	//Mushtarika
        else if (tc === -30) this.runTests(22, 47);			//Sons and daughters
        else if (tc === -31) this.runTests(48, 95);			//Daughters and no sons
        else if (tc === -32) this.runTests(96, 108);			//Daughters and siblings
        else if (tc === -33) this.runTests(109, 150);		//Parents
        else if (tc === -35) this.runTests(151, 160);		//Spouses
        else if (tc === -34) this.runTests(161, 180);		//Grandfather and siblings
        else if (tc === -101) this.runTests(182, 186);		//Sole heir
        else if (tc === -100) this.runTests(187, 188)		//No heirs
        else if (tc === -98) {
            this.calculateTestCase(TestCases.getRandomTestCase());
        } else if (tc === -99) {
            this.randomTest();
        } else if (tc === -1000) {
            this.runAllTests();
        } else if (tc >= 0 && (tc - 0) <= TestCases.lastCase()) {
            this.calculateTestCase(tc);
        }
        this.setStatus(displays["_testok_"]);
    }

}

export default UI;