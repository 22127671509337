//Load this file after json2.js and before lookup.js
/**
 *	All literals are entered here in order to facilitate localization
 *	Entries are put in three associative arrays (JSON objects): rules, explanations and displays
 *	Each entry is in the format "key":"value"
 *	Translate only this file and the HTML files. Javascript files other than this do not need translation
 *	Translate "value" but not "key"
 *	@see	#rules
 *	@see	#explanations
 *	@see	#displays
 *	
 *	@since	September 2020
*/
/**
 *	Rules of inheritance. 
 *	Listed as one long JSON object simulating an associative array keyed by an Id,
 *	e.g., "_Daughter_":"text explaining the rules of inheritance for daughters".
 */
export const rules = {
"_Son_":"<em>Sons</em> are the most eligible heirs and are classified as agnates ('Asaba'). They agnate with daughters by a ratio of 2:1. If no daughters, they get the remainder after the named heirs get their named shares",

"_Daughter_":"<em>Daughters</em> are named heirs, i.e., they are named in the Quran as heirs who must inherit. If the other heirs include sons, they inherit half what the sons inherit. That's called a 1:2 agnation ('Ta'sib'). Otherwise, if there is only one daughter, she inherits half the the estate. If there are more, they share equally in two thirds of the estate (Sabeq[1])",

"_Grandson_":"<em>Sons of sons</em> only become eligible heirs in the absense of direct sons. Like sons, they are classified as agnates. If no daughters have survived, sons of sons agnate with daughters of sons by a ratio of 2:1. If no daughters of sons have survived, they get the remainder after the named heirs get their named shares",

"_Granddaughter_":"<em>Daughters of sons</em>. If a son of the decedant survived, daughters of sons do not inherit. If sons of sons did not survive but one daughter did, the daughter gets her named share of one half and the daughters of sons share one sixth, thus together they complete a two thirds as if they together were two daughters. This situation is called 'female agnation'. But if a son of son survived, he agnates by a ratio of 2:1 with the daughters of sons in what remains after the daughter of the decedent gets her named share of one half. If two or more daughters of the decedent survived, the daughters of sons do not inherit (because a two thirds is already given to the direct daughters) unless there are sons of sons, or sons of sons of sons etc, in which case they agnate by a ratio of 1:2 with the male descendants in the remainder (Sabeq[1])",

"_Husband_":"The <em>Husband</em> is named in the Quran as an heir who must inherit. His share is either half the estate if the decedent, his late wife, had no children. Otherwise, his share is a quarter of the estate (Sabeq[1])",

"_Wife_":"The <em>wife</em> is named in the Quran as an heir who must inherit. Her share is either a quarter of the estate if the decedent, her late husband, had no children. Otherwise, her share is one eigth of the estate. If there are more than one wife who have not been finally divorced, up to four, they share equally in the one quarter or one eighth share. If there are more than four who have not been finally divorced, only the first four are legal and therefore considered.<br/>If a surviving wife has been divorced by the decedent but was still in her grace period ('Idda'), she inherits. In the Hanbali juristic school, a wife divorced before consummation and who moved out still inherits if the decedent didn't marry another women after her. Egyptian law also lets a finally divorced wife, i.e., divorced for the third time, if she was still in her grace period and did not consent to the divorce, it lets her inherit (Sabeq[1])",

"_Father_":"The <em>father</em> is a named heir, i.e., he is named in the Quran as an heir who must inherit. If the other heirs include sons, or sons of sons, etc., the father gets a sixth of the estate. If the heirs include  daughters, or daughters of sons, etc., and no male descendants, the father gets a sixth plus what remains after the other named heirs get their named shares. If the heirs do not include children, he agnates in what remains after the other named heirs get their named shares (Sabeq[1])",

"_Mother_":"The <em>mother</em> is named in the Quran as an heir who must inherit. She gets a sixth of the estate if the other heirs include children or grandchildren or two siblings (three in the opinion of Ibn Hazm). If none of these heirs survived, she gets a third of the estate. She takes a third of the remainder if the other heirs include a spouse and the father. This last situation is commonly known as the Gharraia (Omaria) case (Sabeq[1]). Ibn Hazm opined that she gets a third of the estate even in that situation",

"_Grandfather_":"<em>The father of father</em> is classified as a male agnate. The father of mother is classified as a relative to mother. The father of father does not inherit if the father survived. If the father did not survive, the father of father inherits the father's share - with a few exceptions: a. The mother of father does inherit with the father of father, but she doesn't with the father. b. In the case of two parents and a spouse (the case known as Omaria), the mother gets a third of the remainder. If, on the other hand, the case included the father of father, instead of the father, then the mother inherits a third of the entire estate. Ibn Abbas (RA) and Ibn Hazm disagreed. Both opined that the mother gets a third of the remainder in both situations. c. Siblings do not inherit with the father, but they do with the father of father. Abu-Haneefa disagreed. He opined that siblings do not inherit with the father of father either. Egyptian law lets full siblings inherit with the father of father by agnation and the father of father is guaranteed a minimum of one sixth of the estate, subject to a possible redivision if the estate is oversubscribed (Sabeq[1])",

"_Grandmother_":"<em>Grandmothers</em> share a sixth of the estate if they are at the same generation. If one of them is a more junior generation, the younger generation gets the full sixth and the older generation is deprived. If the other heirs include the mother, the grandmothers are deprived. If the other heirs include the father (or father of father), his mother is deprived (Sabeq[1])",

"_FullBrother_":"<em>Full brothers</em> agnate in the remainder of the estate if any. If there are full sisters, then each brother shares with each sister by a ratio of 2:1",

"_FullSister_":"<em>Full sisters</em>. If one, she gets a half if there are no male agnates. If more than one, they share two thirds. If there are male agnates, the full sisters agnate with them by a ratio of 1:2. If there are no male agnates, but female descendants, the full sisters inherit the remainder after the named shares of the female descendants. They do not inherit with male descendants or the father. Abu-Haneefa added the father of father (Sabeq[1])",

"_HalfBrother_":"<em>Paternal half-brothers</em> agnate with paternal half-sisters in the remainder of the estate if any and if there are no full siblings",

"_HalfSister_":"<em>Paternal half-sisters</em> do not inherit with a father, son, father of father, son of son or a full brother. If a daughter or daughter of son survived as well as one full sister, the full sister together with the female descendant are regarded as a full brother and consequently an agnate in the remainder and the paternal half-sisters do not inherit. It also follows that this virtual agnate (juristically called an agnation with another 'Asaba ma`al Ghair') makes the full sisters more eligible for inheritance than paternal half-siblings. If the case involves two full sisters and a paternal half-brother, the paternal half-sisters agnate by a ratio of 1:2 in the remainder with the paternal half-brothers. If none of the above situations were the case, then one paternal half-sister gets a half, and two or more share two thirds. If the situation only involved one full sister, the paternal half-sister gets one sixth thus completing two thirds as if the two of them were full sisters. If the situation involved only paternal half-brothers then the paternal half-sisters agnate by a ratio of 1:2 with them. If the situation only involved daughters or daughters of sons, the paternal half-sisters get the remainder after the female descendants get their named shares (Sabq[1])",

"_SiblingM_":"<em>Maternal half-siblings from mother</em>: Juristic schools interpreted the mention of siblings in verse 4:12 to mean siblings from the mother's side only and therefore ruled that they inherit. They inherit only though if the other heirs do not include male descendents, e.g., sons, or male ascendants, e.g., father. The share of one maternal half-sibling is a sixth of the estate. If there are two or more, they share equally in a third (Sabeq[1])",

"_RelativeM_":"<em>Other relatives</em>",

"_Agnates_":"Agnates are male relatives of the decedent on the father's side. Of them, only sons and the father always inherit. The father and the father of father may combine a named share as well as a share in the remainder, hereby called agnation. Other agnates, such as nephews, uncles, and cousins (in that kinship order) may inherit the remainder when the estate is not fully subscribed. Only the nearest category of kinship inherits. The Jaafari juristic school does not allow agnation and instead gives the remainder, if any, to the Islamic treasury",

"_Kalala_":"A 'Kalala' situation is when surviving heirs do not include male ascendants or male descendants. Verses 4:12 and 4:176 mention two cases of it. Verse 4:12 mention it in the context of a surviving spouse and verse 4:176 mentions it in the context of no surviving spouse. The 'No prefrence' option of a juristic school preference uses both contexts. Other juristic schools only use the no-spouse context and have interpreted verse 4:12 to be speaking of maternal half-siblings"
};

/**
 *	Explanations that can be referred to by their Id.
 *	Listed as one long JSON object simulating an associative array keyed by an Id,
 *	e.g., "_GFSibsZ_":"Explanation of how the shares of siblings and grandfather are figured, according to Zaid (RA) and followed by Malik, Shafii and Ibn Hanbal".
 */
export const explanations = {
"_GFSibs_":"In the juristic opinion attributed to Ali (RA), and followed by the Egyptian law, as well as Shii juristic schools, the cases which include the father of father and siblings are decided as follows. First, the named heirs get their named shares, then the father of father is given the better of three portions: a third of the remainder, a male agnate's share or one sixth of the estate. The rest of the heirs agnate in the remainder. If the siblings are only female, the father of father gets the better of two portions: a third of the remainder or a male agnate share. The sisters agnate in the remainder (Ibrahim[4])",
"_GFSibsZ_":"In the juristic opinion attributed to Zaid (RA), and followed by the Maliki, Shafii and Hanbali juristic schools, the cases which include the father of father and siblings are decided as follows. First, the named heirs get their named shares, then the father of father is given the better of three portions: a third of the remainder, a male agnate's share or one sixth of the estate. The rest of the heirs agnate in the remainder. Agnation takes into account siblings from father but does not give them their shares. Instead, their shares are given to the full siblings (Ibrahim [4])"
};

/**
 *	Display literals  that can be referred to by their Id.
 *	Listed as one long JSON object simulating an associative array keyed by an Id,
 *	e.g., "_gfather_":"Father^ of father".
 */
export const displays = {
"_mustJS_":"<h1 align='center' style='text-align:center'>This page requires JavaScript 1.1</h1><p>This page requires a browser which supports JavaScript 1.1 or higher.</p><p>Your browser either does not support JavaScript v1.1, or it has JavaScript disabled. If you want to correctly view this page, please upgrade your browser or enable JavaScript support.</p>",
"_noIE_":"Incompatible browser. Please use a more modern browser, such as Firefox",
"_attrlang_":"lang='en'",
"_attrdir_":"dir='ltr'",
"_bodyclass_":"",
"_bodypopup_":"class='arabic popup'",
"_autotranslate_":"",
"_RTLarrows_":"",
"_improvetranslation_":"",
"_autotranslatedetails_":"",
"_IRTH_":"IRTH",
"_IrthVersion_":"v2(Beta)",
"_title47_":"A determinate share. (Quran 4:7)",
"_alt47_":"A determinate share. (Quran 4:7)",
"_title411_":"Ordained by God. (Quran 4:11)",
"_alt411_":"Ordained by God. (Quran 4:11)",
"_IslamicInheritance_":"Islamic Inheritance",
"_Home_":"Home",
"_Questions_":"Questions",
"_Help_":"Help",
"_wizTitle_":"A wizard interface to the IRTH program",
"_Wizard_":"Wizard",
"_classicanchor_":"Classic",
"_titleclassic_":"The original interface to the IRTH program",
"_Download_":"Download",
"_volunteeranchor_":"Participate",
"_v1stmt_":"document.writeln('<span id='oldlink' title=''>|<a href='../../v1/'>v1.0</a></span>');",
"_Number_":"Number",
"_Heirs_":"Heirs",
"_Share_":"Share",
"_Junior_":"Junior",
"_wizStep1_":"1. Designate the heirs",
"_wizStep2_":"2. Set the preferences",
"_wizStep3_":"3. Estate distribution",

/* Versions */
"_ClassicOption_":"<option value='./'>Classic</option>",
"_WizardOption_":"<option value='#.html'>Wizard</option>",
"_Version1Option_":"<option value='../../v1/'>Version 1</option>",

/* Help pages */
"_MenuOption_":"<option value='irth.html'>Pages</option>",
"_UsageOption_":"<option value='irthhelp.html'>Help</option>",
"_QuestionsOption_":"<option value='questions.html'>Questions</option>",
"_DownloadOption_":"<option value='local.html'>Download</option>",
"_ParticipateOption_":"<option value='volunteer.html'>Participate</option>",
"_HomePageOption_":"<option value='http://www.muslim.estate'>Home</option>",

/* Languages */
"_English_":"<option value='en'>English</option>",
"_Arabic_":"<option value='ar'>عربي</option>",
"_Indonesian_":"<option value='id'>Indonesia</option>",
"_Urdu_":"<option value='ur'>اردو</option>",
"_Uighur_":"<option value='ug'>ئۇيغۇر</option>",
"_Pashto_":"<option value='ps'>پښتو</option>",
"_Farsi_":"<option value='fa'>فارسی</option>",
"_Kurdish_":"<option value='ku'>کوردی</option>",
"_Dhivehi_":"<option value='dv' disabled>ދިވެހި</option>",
"_Afrikaans_":"<option value='af'>Afrikaans</option>",
"_Somali_":"<option value='so'>Af-Soomaali</option>",
"_Azeri_":"<option value='az'>Azərbaycan</option>",
"_Bosnian_":"<option value='bo'>босански</option>",
"_German_":"<option value='de'>Deutsch</option>",
"_Spanish_":"<option value='es'>Español</option>",
"_French_":"<option value='fr'>Français</option>",
"_Hausa_":"<option value='ha'>Hausa</option>",
"_Javanese_":"<option value='jv'>Jạwi</option>",
"_Kazakh_":"<option value='kk'>Қазақ</option>",	  
"_Swahili_":"<option value='sw'>Kiswahili</option>",
"_Malay_":"<option value='ms'>Melayu</option>",
"_Dutch_":"<option value='nl'>Nederlands</option>",
"_Portuguese_":"<option value='pt'>Português</option>",
"_Albanian_":"<option value='sq'>Shqip</option>", 
"_Turkish_":"<option value='tr'>Türk</option>",
"_Turkmen_":"<option value='tk'>Türkmen</option>",
"_Chechen_":"<option value='ce'>Содержани</option>",
"_Chinese_":"<option value='zh'>中文</option>",
"_Kyrgyz_":"<option value='ky'>Кыргызча</option>",
"_Russian_":"<option value='ru'>Pусский</option>",
"_Tajik_":"<option value='tg'>тоҷикӣ</option>",
"_Uzbek_":"<option value='uz'>ўзбекча</option>",
"_Bengali_":"<option value='bn'>বাংলা বর্ণমালা</option>",
"_Hindi_":"<option value='hi'>हिंदी</option>",
"_Kashmiri_":"<option value='ks'>कॉशुर</option>",
"_Thai_":"<option value='th'>ภาษาไทย</option>",
"_Rohingya_":"<option value='rh' disabled/>رُاَينڠَ</option>",
"_Amharic_":"<option value='am'>አማርኛ</option>",
"_OtherLanguage_":"<option value='ot'>Other</option>",

/* Float fix */
"_floatfix_":"left",

/* Heir categories and their conjugations */
/* Suffixes:- none: indefinite nominative, D: definite, O: accusative (object)), G: genetive, 2: dual */
"_Relation_":"Relation",
"_Bequest1-3_":"Bequest (rational fraction<=1/3)",

"_Son_":"Son",
"_SonD_":"The son",
"_SonO_":"son",
"_SonDO_":"the son",
"_SonG_":"son",
"_SonDG_":"the son",
"_Sons2_":"Two sons",
"_Sons2D_":"The two sons",
"_Sons2O_":"two sons",
"_Sons2DO_":"the two sons",
"_Sons2G_":"two sons",
"_Sons2DG_":"the two sons",
"_Sons_":"Sons",
"_SonsD_":"The sons",
"_SonsO_":"sons",
"_SonsDO_":"the sons",
"_SonsG_":"sons",
"_SonsDG_":"the sons",

"_Daughter_":"Daughter",
"_DaughterD_":"The daughter",
"_DaughterO_":"daughter",
"_DaughterDO_":"the daughter",
"_DaughterG_":"daughter",
"_DaughterDG_":"the daughter",
"_Daughters2_":"Two daughters",
"_Daughters2D_":"The two daughters",
"_Daughters2O_":"two daughters",
"_Daughters2DO_":"the two daughters",
"_Daughters2G_":"two daughters",
"_Daughters2DG_":"the two daughters",
"_Daughters_":"Daughters",
"_DaughtersD_":"The daughters",
"_DaughtersO_":"daughters",
"_DaughtersDO_":"the daughters",
"_DaughtersG_":"daughters",
"_DaughtersDG_":"the daughters",

"_Father_":"Father",
"_FatherD_":"The father",
"_FatherO_":"father",
"_FatherDO_":"the father",
"_FatherG_":"father",
"_FatherDG_":"the father",

"_Mother_":"Mother",
"_MotherD_":"The mother",
"_MotherO_":"mother",
"_MotherDO_":"the mother",
"_MotherG_":"mother",
"_MotherDG_":"the mother",

"_Husband_":"Husband",
"_HusbandD_":"The husband",
"_HusbandO_":"husband",
"_HusbandDO_":"the husband",
"_HusbandG_":"husband",
"_HusbandDG_":"the husband",

"_Wife_":"Wife",
"_WifeD_":"The wife",
"_WifeO_":"wife",
"_WifeDO_":"the wife",
"_WifeG_":"wife",
"_WifeDG_":"the wife",
"_Wives_":"Wives",

"_Brother_":"Full brother",
"_BrotherD_":"The full brother",
"_BrotherO_":"full brother",
"_BrotherDO_":"the full brother",
"_BrotherG_":"full brother",
"_BrotherDG_":"the full brother",
"_Brothers2_":"Two brothers",
"_Brothers2D_":"The two brothers",
"_Brothers2O_":"two brothers",
"_Brothers2DO_":"the two brothers",
"_Brothers2G_":"two brothers",
"_Brothers2DG_":"the two brothers",
"_Brothers_":"Full brothers",
"_BrothersD_":"The full brothers",
"_BrothersO_":"full brothers",
"_BrothersDO_":"the full brothers",
"_BrothersG_":"full brothers",
"_BrothersDG_":"the full brothers",

"_Sister_":"Full sister",
"_SisterD_":"The full sister",
"_SisterO_":"full sister",
"_SisterDO_":"the full sister",
"_SisterG_":"full sister",
"_SisterDG_":"the full sister",
"_Sisters2_":"Two sisters",
"_Sisters2D_":"The two sisters",
"_Sisters2O_":"two sisters",
"_Sisters2DO_":"the two sisters",
"_Sisters2G_":"two sisters",
"_Sisters2DG_":"the two sisters",
"_Sisters_":"Full sisters",
"_SistersD_":"The full sisters",
"_SistersO_":"full sisters",
"_SistersDO_":"the full sisters",
"_SistersG_":"full sisters",
"_SistersDG_":"the full sisters",

"_BrotherF_":"Paternal half-brother",
"_BrothersF_":"Paternal half-brothers",
"_Brotherf_":"Paternal half-brother",
"_BrotherfD_":"The paternal half-brother",
"_BrotherfO_":"paternal half-brother",
"_BrotherfDO_":"the paternal half-brother",
"_BrotherfG_":"paternal half-brother",
"_BrotherfDG_":"the paternal half-brother",
"_Brothersf2_":"Two paternal half-brothers",
"_Brothersf2D_":"The two paternal half-brothers",
"_Brothersf2O_":"two paternal half-brothers",
"_Brothersf2DO_":"the two paternal half-brothers",
"_Brothersf2G_":"two paternal half-brothers",
"_Brothersf2DG_":"the two paternal half-brothers",
"_Brothersf_":"Paternal half-brothers",
"_BrothersfD_":"The paternal half-brothers",
"_BrothersfO_":"paternal half-brothers",
"_BrothersfDO_":"the paternal half-brothers",
"_BrothersfG_":"paternal half-brothers",
"_BrothersfDG_":"the paternal half-brothers",

"_SisterF_":"Paternal half-sister", 
"_SistersF_":"Paternal half-sisters", 
"_Sisterf_":"Paternal half-sister",
"_SisterfD_":"The paternal half-sister",
"_SisterfO_":"paternal half-sister",
"_SisterfDO_":"the paternal half-sister",
"_SisterfG_":"paternal half-sister",
"_SisterfDG_":"the paternal half-sister",
"_Sistersf2_":"Two paternal half-sisters",
"_Sistersf2D_":"The two paternal half-sisters",
"_Sistersf2O_":"two paternal half-sisters",
"_Sistersf2DO_":"the two paternal half-sisters",
"_Sistersf2G_":"two paternal half-sisters",
"_Sistersf2DG_":"the two paternal half-sisters",
"_Sistersf_":"Paternal half-sisters",
"_SistersfD_":"The paternal half-sisters",
"_SistersfO_":"paternal half-sisters",
"_SistersfDO_":"the paternal half-sisters",
"_SistersfG_":"paternal half-sisters",
"_SistersfDG_":"the paternal half-sisters",

"_SiblingM_":"Maternal half-sibling",
"_SiblingsM_":"Maternal half-siblings",
"_Siblingm_":"Maternal half-sibling",
"_SiblingmD_":"The maternal half-sibling",
"_SiblingmO_":"maternal half-sibling",
"_SiblingmDO_":"the maternal half-sibling",
"_SiblingmG_":"maternal half-sibling",
"_SiblingmDG_":"the maternal half-sibling",
"_Siblingsm2_":"Two maternal half-siblings",
"_Siblingsm2D_":"The two maternal half-siblings",
"_Siblingsm2O_":"two maternal half-siblings",
"_Siblingsm2DO_":"the two maternal half-siblings",
"_Siblingsm2G_":"two maternal half-siblings",
"_Siblingsm2DG_":"the two maternal half-siblings",
"_Siblingsm_":"Maternal half-siblings",
"_SiblingsmD_":"The maternal half-siblings",
"_SiblingsmO_":"maternal half-siblings",
"_SiblingsmDO_":"the maternal half-siblings",
"_SiblingsmG_":"maternal half-siblings",
"_SiblingsmDG_":"the maternal half-siblings",

"_Gson_":"Son of son",
"_Gsons_":"Sons of sons",
"_Grandson_":"Son of son",
"_GrandsonD_":"The son of son",
"_GrandsonO_":"son of son",
"_GrandsonDO_":"the son of son",
"_GrandsonG_":"son of son",
"_GrandsonDG_":"the son of son",
"_Grandsons2_":"Two sons of sons",
"_Grandsons2D_":"The two sons of sons",
"_Grandsons2O_":"two sons of sons",
"_Grandsons2DO_":"the two sons of sons",
"_Grandsons2G_":"two sons of sons",
"_Grandsons2DG_":"the two sons of sons",
"_Grandsons_":"Sons of sons",
"_GrandsonsD_":"The sons of sons",
"_GrandsonsO_":"sons of sons",
"_GrandsonsDO_":"the sons of sons",
"_GrandsonsG_":"sons of sons",
"_GrandsonsDG_":"the sons of sons",

"_Gdaughter_":"Daughter of son",
"_Gdaughters_":"Daughters of sons",
"_Granddaughter_":"Daughter of son",
"_GranddaughterD_":"The daughter of son",
"_GranddaughterO_":"daughter of son",
"_GranddaughterDO_":"the daughter of son",
"_GranddaughterG_":"daughter of son",
"_GranddaughterDG_":"the daughter of son",
"_Granddaughters2_":"Two daughters of sons",
"_Granddaughters2D_":"The two daughters of sons",
"_Granddaughters2O_":"two daughters of sons",
"_Granddaughters2DO_":"the two daughters of sons",
"_Granddaughters2G_":"two daughters of sons",
"_Granddaughters2DG_":"the two daughters of sons",
"_Granddaughters_":"Daughters of sons",
"_GranddaughtersD_":"The daughters of sons",
"_GranddaughtersO_":"daughters of sons",
"_GranddaughtersDO_":"the daughters of sons",
"_GranddaughtersG_":"daughters of sons",
"_GranddaughtersDG_":"the daughters of sons",

"_Gfather_":"Father^ of father",
"_Grandfather_":"Father of father",
"_GrandfatherD_":"The father of father",
"_GrandfatherO_":"father of father",
"_GrandfatherDO_":"the father of father",
"_GrandfatherG_":"father of father",
"_GrandfatherDG_":"the father of father",

"_GmotherF_":"Mother^ of father",
"_Grandmotherf_":"Mother of father",
"_GrandmotherfD_":"The mother of father",
"_GrandmotherfO_":"mother of father",
"_GrandmotherfDO_":"the mother of father",
"_GrandmotherfG_":"mother of father",
"_GrandmotherfDG_":"the mother of father",

"_GmotherM_":"Mother^ of mother",
"_Grandmotherm_":"Mother of mother",
"_GrandmothermD_":"The mother of mother",
"_GrandmothermO_":"mother of mother",
"_GrandmothermDO_":"the mother of mother",
"_GrandmothermG_":"mother of mother",
"_GrandmothermDG_":"the mother of mother",

"_Grandmother_":"Grandmother",
"_Grandmothers_":"Grandmothers",

"_Uncle_":"Full brother of the father",
"_UncleD_":"The full brother of the father",
"_UncleO_":"full brother of the father",
"_UncleDO_":"the full brother of the father",
"_UncleG_":"full brother of the father",
"_UncleDG_":"the full brother of the father",
"_Uncles2_":"Two full brothers of the father",
"_Uncles2D_":"The two full brothers of the father",
"_Uncles2O_":"two full brothers of the father",
"_Uncles2DO_":"the two full brothers of the father",
"_Uncles2G_":"two full brothers of the father",
"_Uncles2DG_":"the two full brothers of the father",
"_Uncles_":"Full brothers of the father",
"_UnclesD_":"The full brothers of the father",
"_UnclesO_":"full brothers of the father",
"_UnclesDO_":"the full brothers of the father",
"_UnclesG_":"full brothers of the father",
"_UnclesDG_":"the full brothers of the father",

"_UncleF_":"Paternal half-brother of father",
"_UnclesF_":"Paternal half-brothers of father",
"_Unclef_":"Paternal half-brother of the father",
"_UnclefD_":"The paternal half-brother of the father",
"_UnclefO_":"paternal half-brother of the father",
"_UnclefDO_":"the paternal half-brother of the father",
"_UnclefG_":"paternal half-brother of the father",
"_UnclefDG_":"the paternal half-brother of the father",
"_Unclesf2_":"Two paternal half-brothers of the father",
"_Unclesf2D_":"The two paternal half-brothers of the father",
"_Unclesf2O_":"two paternal half-brothers of the father",
"_Unclesf2DO_":"the two paternal half-brothers of the father",
"_Unclesf2G_":"two paternal half-brothers of the father",
"_Unclesf2DG_":"the two paternal half-brothers of the father",
"_Unclesf_":"Paternal hlf-brothers of the father",
"_UnclesfD_":"The paternal half-brothers of the father",
"_UnclesfO_":"paternal half-brothers of the father",
"_UnclesfDO_":"the paternal half-brothers of the father",
"_UnclesfG_":"paternal half-brothers of the father",
"_UnclesfDG_":"the paternal half-brothers of the father",

"_RelativeM_":"Other relative",
"_RelativesM_":"Other relatives",
"_Relativem_":"Other relative",
"_RelativemD_":"the other relative",
"_RelativemO_":"other relative",
"_RelativemDO_":"The other relative",
"_RelativemG_":"other relative",
"_RelativemDG_":"the other relative",
"_Relativesm2_":"Two relatives to the mother",
"_Relativesm2D_":"The two relatives to the mother",
"_Relativesm2O_":"two relatives to the mother",
"_Relativesm2DO_":"The two relatives to the mother",
"_Relativesm2G_":"two relatives to the mother",
"_Relativesm2DG_":"the two relatives to the mother",
"_Relativesm_":"Other relatives",
"_RelativemsD_":"the other relatives",
"_RelativemsO_":"other relatives",
"_RelativemsDO_":"the other relatives",
"_RelativemsG_":"other relatives",
"_RelativemsDG_":"the other relatives",

"_Nephew_":"Son of full brother",
"_NephewD_":"The son of a full brother",
"_NephewO_":"son of a full brother",
"_NephewDO_":"the son of a full brother",
"_NephewG_":"son of a full brother",
"_NephewDG_":"the son of a full brother",
"_Nephews2_":"Two sons of full brothers of the father",
"_Nephews2D_":"The two sons of full brothers of the father",
"_Nephews2O_":"two sons of full brothers of the father",
"_Nephews2DO_":"the two sons of full brothers of the father",
"_Nephews2G_":"two sons of full brothers of the father",
"_Nephews2DG_":"the two sons of full brothers of the father",
"_Nephews_":"Sons of full brothers",
"_NephewsD_":"The sons of full brothers",
"_NephewsO_":"sons of full brothers",
"_NephewsDO_":"the sons of full brothers",
"_NephewsG_":"Sons of full brothers",
"_NephewsDG_":"the sons of full brothers",

"_NephewF_":"Son of paternal half-brother",
"_NephewsF_":"Sons of paternal half-brothers",
"_Nephewf_":"Son of a paternal half-brother",
"_NephewfD_":"The son of a paternal half-brother",
"_NephewfO_":"son of a paternal half-brother",
"_NephewfDO_":"the son of a paternal half-brother",
"_NephewfG_":"Son of a paternal half-brother",
"_NephewfDG_":"the son of a paternal half-brother",
"_Nephewsf2_":"Two sons of paternal half-brothers",
"_Nephewsf2D_":"The two sons of paternal half-brothers",
"_Nephewsf2O_":"two sons of paternal half-brothers",
"_Nephewsf2DO_":"the two sons of paternal half-brothers",
"_Nephewsf2G_":"two sons of paternal half-brothers",
"_Nephewsf2DG_":"the two sons of paternal half-brothers",
"_Nephewsf_":"Sons of paternal half-brothers",
"_NephewsfD_":"The sons of paternal half-brothers",
"_NephewsfO_":"sons of paternal half-brothers",
"_NephewsfDO_":"the sons of paternal half-brothers",
"_NephewsfG_":"sons of paternal half-brothers",
"_NephewsfDG_":"the sons of paternal half-brothers",

"_Cousin_":"Son of full brother of father",
"_CousinD_":"The son of a full brother of the father",
"_CousinO_":"son of a full brother of the father",
"_CousinDO_":"the son of a full brother of the father",
"_CousinG_":"son of a full brother of the father",
"_CousinDG_":"the son of a full brother of the father",
"_Cousins2_":"Two sons of full brothers of the father",
"_Cousins2D_":"The two sons of full brothers of the father",
"_Cousins2O_":"two sons of full brothers of the father",
"_Cousins2DO_":"the two sons of full brothers of the father",
"_Cousins2G_":"two sons of full brothers of the father",
"_Cousins2DG_":"the two sons of a full brother of the father",
"_Cousins_":"Sons of full brothers of the father",
"_CousinsD_":"The sons of full brothers of the father",
"_CousinsO_":"sons of full brothers of the father",
"_CousinsDO_":"the sons of full brothers of the father",
"_CousinsG_":"sons of full brothers of the father",
"_CousinsDG_":"the sons of full brothers of the father",

"_CousinF_":"Son of paternal half-brother of father",
"_CousinsF_":"Sons of paternal half-brothers of father",
"_Cousinf_":"Son of a paternal half-brother of the father",
"_CousinfD_":"The son of a paternal half-brother of the father",
"_CousinfO_":"son of a paternal half-brother of the father",
"_CousinfDO_":"the son of a paternal half-brother of the father",
"_CousinfG_":"son of a paternal half-brother of the father",
"_CousinfDG_":"the son of a paternal half-brother of the father",
"_Cousinsf2_":"Two sons of paternal half-brothers of the father",
"_Cousinsf2D_":"The two sons of paternal half-brothers of the father",
"_Cousinsf2O_":"two sons of paternal half-brothers of the father",
"_Cousinsf2DO_":"the two sons of paternal half-brothers of the father",
"_Cousinsf2G_":"two sons of paternal half-brothers of the father",
"_Cousinsf2DG_":"the two sons of a paternal half-brother of the father",
"_Cousinsf_":"Sons of paternal half-brothers of the father",
"_CousinsfD_":"The sons of paternal half-brothers of the father",
"_CousinsfO_":"sons of paternal half-brothers of the father",
"_CousinsfDO_":"the sons of paternal half-brothers of the father",
"_CousinsfG_":"sons of paternal half-brothers of the father",
"_CousinsfDG_":"the sons of paternal half-brothers of the father",

"_Servant_":"Servant",
"_ServantD_":"The servant",
"_ServantO_":"servant",
"_ServantDO_":"the servant",
"_ServantG_":"servant",
"_ServantDG_":"the servant",
"_Servants2_":"Two Servants",
"_Servants2D_":"The two servants",
"_Servants2O_":"two servants",
"_Servants2DO_":"the two servants",
"_Servants2G_":"two servanst",
"_Servants2DG_":"the two servants",
"_Servants_":"Servants",
"_ServantsD_":"The servants",
"_ServantsO_":"servants",
"_ServantsDO_":"the servants",
"_ServantsG_":"servants",
"_ServantsDG_":"the servants",

"_Bequest_":"Bequest",
"_BequestD_":"The bequest",

"_Treasury_":"Islamic treasury",

/* Other conjugations */
/* Suffixes:- none: masculine, F: feminine, 2: dual */
"_each_":"each",
"_eachF_":"each",
"_each2_":"each",
"_each2F_":"each",
"_eachgets_":"each gets",
"_eachgetsF_":"each gets",
"_eachgets2_":"each gets",
"_eachgets2F_":"each gets",
"_gets_":"gets",
"_getsF_":"gets",
"_gets0_":"doesn't inherit",
"_gets0F_":"doesn't inherit",
"_get_":"get",
"_getF_":"get",
"_get0_":"don't inherit",
"_get0F_":"don't inherit",
"_get2_":"get",
"_get2F_":"get",
"_get02_":"don't inherit",
"_get02F_":"don't inherit",
"_noshare_":"0",
"_noshareF_":"0",
"_noshares2_":"0",
"_noshares2F_":"0",
"_noshares_":"0",
"_nosharesF_":"0",
"_has_":"has",
"_hasF_":"has",
"_have_":"have",
"_haveF_":"have",
"_have2_":"have",
"_have2F_":"have",
"_fracNONE_":"NONE",
"_fracTOSHARE_":"TOSHARE",
"_fracWHOLE_":"WHOLE",

    "_ttss_": "Sons of daughters are considered uterine relatives and should be entered under 'Other relatives'",
    "_ttds_": "Daughters of daughters are considered uterine relatives and should be entered under 'Other relatives'",
    "_ttgf_": "Father of mother is considered a uterine relative and should be entered under 'Other relatives'",
    "_ttun_": "Full brothers of mother, full sisters of mother, and full sisters of father are considered uterine relatives and should be entered under 'Other relatives'",
    "_ttunf_": "Maternal half-brothers of father or of mother, maternal half-sisters of father or of mother, and half-sisters of father are considered uterine relatives and should be entered under 'Other relatives'",
    "_ttnw_": "Sons and daughters of full sisters, as well as daughters of full brothers, are considered uterine relatives and should be entered under 'Other relatives'",
    "_ttnwf_": "Sons and daughters of half-sisters, as well as daughters of half-brothers, are considered uterine relatives and should be entered under 'Other relatives'",
    "_ttcz_": "Sons and daughters of full brothers of mother, sons and daughters of full sisters of mother, daughters of full brothers of father or of mother, and daughters of full sisters of father or of mother are considered uterine relatives and should be entered under 'Other relatives'",
    "_ttczf_": "Sons and daughters of maternal half-brothers, daughters of maternal half-sisters, daughters of half-brothers of father or of mother, and daughters of half-sisters of father or of mother are considered uterine relatives and should be entered under 'Other relatives'",
    "_ttsrv_": "Males",

/* Buttons */
"_Calculate_":"Calculate",
"_Clear_":"Clear",
"_Details_":"Details",
"_>>_":">>",
"_<<_":"<<",
"_BackToTop_":"Back to Input Page",
"_ResetPrefs_":"Reset Preferences",
"_Add_":"Add",
"_Remove_":"Remove",
"_Reset_":"Reset",
"_wizbtnDetails_":"Details",

/* Preferences */
"_Preferences_":"Preferences",
"_optAllowed_":"Allowed",
"_optNotAllowed_":"Not Allowed",
"_titlebq_":"A bequest, expressed as a rational fraction of the estate, not to exceed 1/3",
"_titlerudd_":"Whether to allow distribution of the remainder of an undersubscribed estate to the named heirs. If not allowed, the remainder goes to the Islamic treasury",
"_titleawl_":"Whether to allow redivision of an oversubscribed estate by the ratios of their proportions to each other. If not allowed, the excess is deducted from the share of the farthest female heir",

/* Jusristic schools */
"_School_":"Juristic school",
"_wizSchool_":"Juristic school",
"_titleSchool_":"Mazhab",
"_explSchool_":"Juristic schools may have different opinions about how to divide up an estate. Select the juristic school you follow or prefer to follow. If you do not follow any particular juristic school, you may select 'No preference.' The default is the juristic school of Abu-Haneefa.",
"_Hanafi_":"Hanafi",
"_Maliki_":"Maliki",
"_Shafii_":"Shafii",
"_Hanbali_":"Hanbali",
"_Zahiri_":"Zahiri",
"_Egypt_":"Egyptian Law",
"_Jaafari_":"Jaafari",
"_Ibadhi_":"Ibadhi",
"_Zaidi_":"Zaidi",
"_none_":"None",
"_None_":"No preference",

/* Oversubscription */
"_Oversub_":"Oversubscription preference",
"_explOversub_":"When the sum of distributed shares is <em>more</em> than the estate, how do you prefer to reset the shares?",
"_explOversub1_":"Redivide all shares by their ratios to each other (Consensus)",
"_explOversub0_":"Reduce the share of the farthest female heir (Zahiri/Jaafari)",
"_awlrule_":"Redivision Rule",

/* Undersubscription */
"_Undersub_":"Undersubscription preference",
"_explUndersub_":"When the sum of distributed shares is <em>less</em> than the estate, how do you prefer to distribute the remainder?",
"_explUndersub1_":"Divide it between the named heirs by the ratios to each other of their named shares (Consensus)",
"_explUndersub2_":"Include spouses in the dividing (Uthman)",
"_explUndersub0_":"Give it to the Islamic treasury (Maliki)",
"_ruddrule_":"Reversion Rule",
"_optExtSpouses_":"Extended to Spouses",

/* Test cases */
"_TestCases_":"Test Cases",
"_Case_":"Case",
"_of_":"of",
"_AbuBakr_":"Abu-Bakr",
"_Omaria_":"Gharraia (Omar)",
"_Minbaria_":"Minbaria (Ali)",
"_Shuraih_":"Judge Shuraih",
"_Shafiia_":"Shafiia",
"_Kharqaa_":"Kharqaa",
"_Malikia_":"Malikia",
"_PseudoMalikia_":"Pseudo-Malikia",
"_Akdaria_":"Akdaria",
"_Mushtarika_":"Mushtarika",
"_Sons-Daughters_":"Sons and daughters",
"_Daughters-nosons_":"Daughters and no sons",
"_Daughters-Siblings_":"Daughters and siblings",
"_Parents_":"Parents",
"_Grandfather-Siblings_":"Grandfather and siblings",
"_Spouses_":"Spouses",
"_SoleHeir_":"Sole heir",
"_NoHeirs_":"No heirs",
"_RandomCase_":"Random Case",
"_RandomHeirs_":"Random Heirs",
"_AllTests_":"All Test Cases",

/* Messages */
"_BUG_":"BUG",
"_calcerror_":"Error during share calculation",
"_calcnotok_":"Calculation incomplete",
"_calcok_":"Calculation done",
"_daughtersfail_":"Daughter(s) did not inherit",
"_fatherfail_":"The father did not inherit",
"_husbandfail_":"Husband did not inherit",
"_inputinvalid_":"Error occurred during input validation. Please correct and re-try",
"_motherfail_":"The mother did not inherit",
"_rangefail_":"Calculation of heir range failed",
"_redivfail_":"Estate not fully subscribed after redivision",
"_reportit_":"Please report it to the authors",
"_sonsfail_":"Son(s) did not inherit",
"_spouse2_":"Incorrect input of both husband and wife",
"_startover_":"Please start over",
"_sumfail_":"After agnation and redivision, the final sum of shares is short",
"_wifefail_":"Wife did not inherit",

/* Status */
"_bequest_":"Bequest",
"_fullrev_":"Full Reversion",
"_norev_":"No reversion",
"_rediv_":"No redivision",
"_rev_":"Reversion",
"_thanks_":"Thank you for visiting Muslim.Estate",
"_translating_":"Translating to",

/* Footer */
"_benefits_":"May God accept this work and turn it into a 'knowledge that benefits.'",
"_altCopyright_":"Creative Commons license CC-BY-NC-ND",
"_titleCopyright_":"Creative Commons license CC-BY-NC-ND",
"_Copyright_":"&copy;&nbsp;2024+&nbsp;<a href='mailto:info@muslim.estate?subject=About your IRTH program'>IslamicSoftware.org</a>&nbsp;CC-BY-NC-ND license.<br/>Logo design by Shaddim; original CC license symbols by Creative Commons [<a href='https://creativecommons.org/licenses/by/4.0'>CC BY 4.0</a>], <a href='https://commons.wikimedia.org/wiki/File:Creative_commons_license_spectrum.svg'>via Wikimedia Commons</a>",
"_Copyrightlogofloat_":"right",
"_Copyrightfloat_":"left",

/* Details */
"_Results_":"MEIC - Result Details",

"_comma_":",",
"_semicolon_":";",

"_is_":"is",

"_START_":"<h2>Muslim Inheritance Calculator - Result Explanation</h2>",


"_correct2_":"Value corrected to 1/3",

"_deprived_":"is deprived",
"_depriveds_":"are deprived",

"_iskalala_":"A 'Kalala' situation: No descendants nor ascendants alive",

/* sumUp */
"_addsup_":"Checking if shares add up...",
"_calcremain_":"Calculating the remainder...",
"_doesaddup_":"Estate is fully subscribed",
"_finalsum_":"<h3>Summary</h3>",
"_Finalsum_":"<h3>Result Summary</h3>",
"_notaddupag_":"After agnation, shares do not yet add up",
"_notfullrudd_":"Not fully subscribed after 'rudd' (reversion)",
"_notfullagrudd_":"Not fully subscribed after agnation and 'rudd' (reversion)",
"_remainnow_":"Remainder",
"_sumnow_":"Sum",
"_sumtreasury_":"Summing up to check that treasury got the remainder...",

"_theEnd_":"<h6>END Calculations</h6>",

"_Noheirs_":"No heirs",

    /* calculateHeirsSharesOfNamedHeirs */
    "_calcnamed_":"Calculating shares for named heirs...",
    "_calcnamed1_":"Calculating share for",
    "_daughters_":"No surviving sons. One daughter receives half. Two or more receive two-thirds.",
    "_daughters12_":"Daughters share with sons at a ratio of 1:2.",
    "_fasaba_":"In 'female agnation': No male descendants but one daughter, sister, or paternal half-sister from father, so daughters of sons get one-sixth to complete two-thirds.",
    "_fasabasis_":"In 'female agnation': Full sisters will share by agnation with female descendants.",
    "_fasabasisF_":"Paternal half-sisters do not inherit in a 'female agnation' situation.",
    "_father6_":"Both parents survived. Father also gets one-sixth.",
    "_fincalcnamed1_":"Finished calculating share for",
    "_fincalcnamed_":"Finished calculating shares for named heirs.",
    "_gdaughters_":"No male descendants. One daughter of a son gets half. Two or more get two-thirds.",
    "_gdaughters0_":"Daughters of sons do not inherit with sons.",
    "_gdaughters0a_":"Daughters of sons do not inherit with more than one daughter and no sons of sons (cannot agnate).",
    "_gdaughters0b_":"None of the rules apply. Daughters of sons get no share.",
    "_gdaughters12gs_":"Daughters of sons replace daughters who did not survive.",
    "_gmother0_":"Grandmothers do not inherit with the mother.",
    "_gmother36Z_":"Grandmothers replace the mother who did not survive. They get one-third or one-sixth accordingly (Zahiri).",
    "_gmother6_":"Mother of father and mother of mother are of the same generation, they share one-sixth.",
    "_gmotherF0My_":"Mother of father does not inherit because mother of mother is a younger generation than her.",
    "_gmotherF6M0_":"No mother of mother; mother of father gets one-sixth (Abu-Bakr's verdict).",
    "_gmotherF6y_":"Mother of father gets one-sixth because she is of a younger generation than mother of mother.",
    "_gmotherJ_":"In the Jaafari juristic school, all grandmothers get one-sixth unless the mother survived, in which case grandmothers do not inherit.",
    "_gmotherM0Fy_":"Mother of mother does not inherit because mother of father is a younger generation than her (Hanafi and Hanbali juristic schools).",
    "_gmotherM6y_":"Mother of mother gets one-sixth because she is of a younger generation than mother of father.",
    "_gmotherM6F0_":"No mother of father, mother of mother gets one-sixth (Abu-Bakr's verdict).",
    "_gsons1_":"Sons of sons replace sons who did not survive.",
    "_gsons21d_":"Sons of sons share with daughters at a ratio of 2:1.",
    "_higherTierJ_":"In the Jaafari juristic school, heirs in a lower tier do not inherit if an heir in a higher tier survived.",
    "_husband4_":"Decedent left children or grandchildren; husband gets one-quarter.",
    "_husband2_":"Decedent left no children or grandchildren; husband gets half.",
    "_kalala1_":"In a 'Kalala' situation with a surviving spouse, one sibling gets one-sixth. More share one-third equally (4:12).",
    "_kalalaJgfOK_":"In the Jaafari juristic school, the presence of a grandfather is allowed in determining a 'Kalala' situation (Ibrahim[3]).",
    "_mother3_":"No descendants, father, or siblings. Mother gets one-third.",
    "_mother3c_":"Mother gets one-third of the remainder, and father gets two-thirds of it (Omar's verdict and consensus).",
    "_mother3C_":"Mother gets one-sixth with two or more siblings (consensus).",
    "_mother3z_":"Mother gets one-third and father agnates (Zahiri).",
    "_mother3Z_":"Mother gets one-third with less than three siblings (Zahiri).",
    "_mother6_":"Decedent left descendants; mother gets one-sixth.",
    "_mrdeprives_":"Because the mother survived.",
    "_mushtarika_":"Mushtarika case: Siblings from mother share with full brothers (Maliki/Shafii).",
    "_noasabaJ_":"The Jaafari juristic school does not allow 'Asaba'.",
    "_nochptJ_":"In the Jaafari juristic school, grandchildren do not inherit with their parents.",
    "_nofasaba_":"Not a 'female agnation' situation. More than one daughter, so two-thirds are already allocated. Therefore, nothing is allocated to the daughters of sons.",
    "_numsib_":"Number of siblings.",
    "_siblings3_":"Decedent left a spouse, a father, a mother, and siblings. The siblings share one-third.",
    "_sibling6_":"Decedent left a spouse, a father, a mother, and a sibling. The sibling gets one-sixth.",
    "_siblings6_":"Siblings get the one-sixth that was reduced from the share of the mother.",
    "_siblingsM0MSB1_":"Siblings from mother do not inherit with father of father (Maliki/Shafii/Hanbali).",
    "_siblingsM0MSB2_":"Siblings from mother do not inherit if daughters (or daughters of sons) have survived (Hanafi/Maliki/Shafii/Hanbali).",
    "_siblingM6_":"One sibling from mother gets one-sixth.",
    "_siblingsM3_":"More than one sibling from mother, they share in one-third.",
    "_sisters0_":"Sisters do not inherit with the father.",
    "_sisters0HZI_":"Sisters do not inherit with the father of father (Hanafi/Zahiri/Ibadhi).",
    "_sisters12_":"Full sisters share with full brothers at a ratio of 1:2.",
    "_sistersMSB_":"Sisters agnate with father of father (Maliki/Shafii/Hanbali/Egyptian law).",
    "_sisters2_":"One full sister and no full brothers, she gets half.",
    "_sisters23_":"More than one full sister and no full brothers, they share two-thirds.",
    "_sistersF0_":"Paternal half-sisters do not inherit with the father.",
    "_sistersF0m_":"Paternal half-sisters do not inherit with full brothers or male descendants.",
    "_sistersF0nofasaba1_":"Paternal half-sisters do not inherit with more than one full sister and no paternal half-brothers (cannot agnate).",
    "_sistersF0nofasaba2_":"Paternal half-sisters do not inherit with one full sister and one or more female descendants.",
    "_sistersF12_":"Paternal half-sisters share with paternal half-brothers at a ratio of 1:2.",
    "_sistersF2_":"One paternal half-sister and no paternal half-brothers. She gets half.",
    "_sistersF23_":"More than one paternal half-sister and no paternal half-brothers. They share two-thirds.",
    "_sistersFHZI_":"Sisters from father do not inherit with the father of father (Hanafi/Zahiri/Ibadhi).",
    "_sistersFMSB_":"Paternal half-sisters agnate with father of father (Maliki/Shafii/Hanbali).",
    "_sistersFfasaba_":"One full sister and no paternal half-brothers and no female descendants, so paternal half-sisters get one-sixth to complete two-thirds.",
    "_sistersFshare_":"Half-sister(s) from father will share by agnation.",
    "_sumsofar_":"Summarizing share distribution so far...",
    "_wife4_":"Decedent left no children or grandchildren; wife gets one-quarter.",
    "_wife8_":"Decedent left children or grandchildren; wife gets one-eighth.",

    /* calculateHeirsSharesOfAgnatedHeirs */
    "_akdaria_": "Akdaria issue: Setting the share of father of father to one-sixth before redistribution",
    "_agnates0_": "Do not inherit with closer male relatives",
    "_agnates0a_": "Do not inherit with sisters in a 'female agnation' situation",
    "_becausefull_": " because estate is now fully distributed after applying all rules",
    "_brothers0_": "Full brothers do not inherit with male descendants or with father",
    "_brothers0HZ_": "Full brothers do not inherit with father of father (Hanafi/Zahiri)",
    "_brothers_": "Full brothers may share by agnation",
    "_brothersF_": "Paternal half-brothers may share by agnation",
    "_brothersF0_": "Paternal half-brothers do not inherit with male descendants or with father",
    "_brothersF0a_": "Paternal half-brothers do not inherit with full sisters in a 'female agnation' situation",
    "_calcagnate1_": "Setting agnate share for",
    "_father_": "Decedent left no descendants; father inherits by agnation",
    "_father6a_": "Decedent left female descendants; father gets one-sixth. He may receive more by agnation",
    "_fatherdeprives_": "because the father survived",
    "_frdeprivesgmf_": "because the father survived",
    "_fincalcagnate_": "Finished establishing agnate heirs' shares",
    "_fincalcagnate1_": "Finished establishing agnate shares for",
    "_gfather_": "Decedent left no descendants; father of father inherits by agnation",
    "_gfather0_": "Father of father does not inherit with father",
    "_gfather6_": "Decedent left male descendants; father of father gets one-sixth",
    "_gfather6a_": "Decedent left female descendants; father of father gets one-sixth. He may receive more by agnation",
    "_gfdeprives_": "because father of father deprives (Hanafi)",
    "_gsondeprives_": "because a son of son survived",
    "_gsons0_": "Sons of sons do not inherit with sons",
    "_gsons_": "Sons of sons share by full agnation",
    "_invalidheir_": " not a valid heir index",
    "_noagnationJ_": "Agnation ('Asaba') is not allowed in the Jaafari juristic school",
    "_relativesM0MSZ_": "Other relatives do not inherit (Maliki/Shafii/Zahiri)",
    "_sondeprives_": "because a son survived",
    "_sons_": "Sons share by full agnation",

    "_xsons_": "Share of sons cannot be zero",
    "_xdaughters_": "Share of daughters cannot be zero",
    "_xfather_": "Share of father cannot be zero",
    "_xmother_": "Share of mother cannot be zero",
    "_xhusband_": "Share of husband cannot be zero",
    "_xwife_": "Share of wife cannot be zero",

    /* Awl (oversubscription) */
    "_daughters-ZJ_": "Redistribution of an oversubscribed estate is not allowed. Share of daughters reduced due to oversubscription (Zahiri/Jaafari)",
    "_excess_": "Estate is oversubscribed by",
    "_father3awl_": "Father's share is restored to one third before redistribution",
    "_fathersum_": "Summing up now that father's share has been reset",
    "_gdaughters-ZJ_": "Redistribution of an oversubscribed estate is not allowed. Share of daughters of sons reduced due to oversubscription (Zahiri/Jaafari)",
    "_gfather6awl_": "Father of father's share is restored to one sixth before redistribution (Egyptian law/Ali's opinion)",
    "_gfather3awl_": "Father of father's share is restored to one third before redistribution (Consensus/Zaid's opinion)",
    "_gfathersum_": "Summing up now that father of father's share has been reset",
    "_isoversub_": "Oversubscription has occurred",
    "_noawl_": "Redistribution of an oversubscribed estate is not allowed. This is the default for Zahiri/Jaafari juristic schools. Ibn Hazm followed Ibn Abbas (RA) who did not approve of redistribution in the case of oversubscription of an estate. Instead, he favored reducing the share of sisters or daughters. The Jaafari juristic school agrees",
    "_redivok_": "Shares add up after redistribution",
    "_sisters-ZJ_": "Redistribution of an oversubscribed estate is not allowed. Share of full sisters reduced due to oversubscription (Zahiri/Jaafari)",
    "_sistersF-ZJ_": "Redistribution of an oversubscribed estate is not allowed. Share of sisters from father reduced due to oversubscription (Zahiri/Jaafari)",
    "_sumoverunder_": "Summing up to determine oversubscription or undersubscription...",
    "_wrongrediv_": "redivide() called when sum is",

    /* agnate */
    "_addsupfathers_": "Shares add up after handling special cases of father/grandfather",
    "_agnateshare_": "Agnate share",
    "_agnshare_": "Agnate share of",
    "_agplusnamed_": "The father, or father of father, get their agnate share as well as their named share",
    "_applyag_": "<b>*** Applying agnation rules ***</b>",
    "_brothersF23agZ_": "In the grandfather-siblings cases, paternal half-brothers get their agnate share when the remainder does not cover the named share of sisters (Maliki/Shafii/Hanbali)",
    "_brothersFZ_": "In the grandfather-siblings cases, when there are no full siblings, paternal half-brothers get the remainder and paternal half-sisters get nothing (Maliki/Shafii/Hanbali)",
    "_fatherag_": "Special case of father and no male descendants. He inherits his named share and by agnation",
    "_finagn_": "Finished applying agnation rules",
    "_finaldeprive_": "Heirs set to receive share get nothing if nothing is left. Estate is now fully subscribed after applying all rules",
    "_getrem_": "also get the remainder being the closest male relatives",
    "_getsrem_": "also get the remainder being the closest male relative",
    "_gfatherag_": "Special case of father of father and no male descendants. He inherits his named share and by agnation",
    "_gfatheragA_": "In the grandfather-siblings cases, the father of father gets a regular agnate's share of remainder (Ali/Hanafi/Egyptian law)",
    "_gfather3ag6Z_": "In the grandfather-siblings cases, the father of father gets the greater of a third of the remainder, a brother's agnate share, or a sixth of the estate (Maliki/Shafii/Hanbali)",
    "_gfatheragAsis_": "In the grandfather-siblings cases, when there are no brothers, the father of father gets the better of an equal agnate share or a third of remainder (Ali/Egyptian law)",
    "_gfatheragSibH_": "In the grandfather-siblings cases, the father of father gets the remainder and the sisters get no share (Hanafi)",
    "_gfather3Z_": "In the grandfather-siblings cases, when there are no brothers, father of father gets the greater of a third of remainder or a brother's agnate share (Maliki/Shafii/Hanbali)",
    "_lastdeprive_": " because agnation and/or oversubscription have already been applied",
    "_noaddupag_": "Distribution still incomplete after applying agnation rules",
    "_nofatherfdec_": "Case is not one with a father, female descendants, and no male descendants",
    "_notundersub_": "Estate is not undersubscribed",
    "_regagshare_": " (the agnate share)",
    "_sistersagZ_": "In the grandfather-siblings cases, full sisters get their agnate share plus the agnate share of paternal half-sisters, if any (Maliki/Shafii/Hanbali)",
    "_sistersZ_": "In the grandfather-siblings cases, when there are no brothers, full sisters get their agnate share plus the agnate share of paternal half-sisters, if any (Maliki/Shafii/Hanbali)",
    "_sistersFZ_": "In the grandfather-siblings cases, when there are no brothers or full sisters, paternal sisters get their agnate share which is the remainder (Maliki/Shafii/Hanbali)",
    "_sisters23agZ_": "In the grandfather-siblings cases, sisters get their named share, half or two-thirds, when the remainder can cover it. Paternal half-sisters in this case get nothing. Paternal half-brothers get the remainder, which may be nothing since the father of father will take the bigger of a third or an agnate share (Maliki/Shafii/Hanbali)",
    "_sisters23ag2Z_": "In the grandfather-siblings cases, when there are no brothers, full sisters get the lesser of their named share, half or two-thirds, or an agnate share. Paternal half-sisters get the remainder, if any (Maliki/Shafii/Hanbali)",
    "_sumagn_": "Sum of agnation weights =",
    "_sumaggfather_": "Summing up in case the share of father of father has exceeded an agnate's share",
    "_sumcalcag1_": "Summing up after agnation calculation is completed for",
    "_sumcalcfathers_": "Summing up before handling special cases of father/grandfather...",
    "_sumfatherfdec_": "Summing up before handling possible special cases remaining, e.g., father and female descendants...",
    "_sumfathers_": "Summing up in case share of father or father of father has changed",
    "_sumfasaba_": "Summing up before checking for 'female agnation'...",
    "_sumsisagZ_": "Summing up in case the share of sisters has decreased from their named share",
    "_wrongag_": "Agnation rules do not apply unless the estate is undersubscribed",

    /* Rudd (undersubscription) */
    "_doesnotrudd_": "does not participate in 'rudd' (reversion)",
    "_donotrudd_": "do not participate in 'rudd' (reversion)",
    "_noagrudd_": "No agnates, dividing remainder among named heirs by ratios of their named shares",
    "_norudd_": "Reversion ('Rudd') is not allowed",
    "_noruddMZ_": "Following Maliki/Zahiri juristic school which does not allow 'rudd' (reversion)",
    "_rev2_": "Reversion ('rudd') to spouses is allowed",
    "_doesrudd_": "participates in 'rudd' (reversion)",
    "_rudd2C_": "Following majority opinion which does not allow reversion to spouses",
    "_ruddextra_": "Reversion applied - Added share from the remainder",
    "_dorudd_": "participate in 'rudd' (reversion)",
    "_usernorudd_": "User prefers to not allow 'rudd' (reversion)",
    "_userrudd_": "User prefers to allow 'rudd' (reversion)",
    "_userrudd2_": "User prefers to allow 'rudd' (reversion) also to spouses",

/* Results */
"_allowed_":"allowed",
"_asfollows_":"After payment of expenses, debts, and obligations, the remainder of the estate is to be divided as follows",
"_asfollowsnobq_":"After payment of expenses, debts, bequests, and obligations, the remainder of the estate is to be divided as follows",
"_Calculating_":"<h6>Calculating</h6>",
"_Collecting_":"<h6>Collecting Data</h6>",
"_Correcting_":"<h6>Validating Data</h6>",
"_Displaying_":"<h6>Displaying Data</h6>",
"_DISTRIBUTION_":"ESTATE DISTRIBUTION",
"_END_":"<h6>END</h6>",
"_Initializing_":"<h6>Initializing</h6>",
"_NONE_":"NONE",
"_notallowed_":"not allowed",
"_Reversion_":"Reversion",
"_Reversion-s_":"Reversion to spouses",
"_Redivision_":"Redivision",
"_tabALL_":"ALL",
"_tabAw_":"Aw",
"_tabBf_":"Bf",
"_tabBq_":"Bq",
"_tabBr_":"Br",
"_tabCase_":"Case",
"_tabCf_":"Cf",
"_tabCz_":"Cz",
"_tabDr_":"Dr",
"_tabDs_":"Ds",
"_tabFr_":"Fr",
"_tabFull_":"Full",
"_tabGF_":"GF",
"_tabGf_":"Gf",
"_tabGm_":"Gm",
"_tabHeirs_":"Heirs",
"_tabHb_":"Hb",
"_tabMr_":"Mr",
"_tabNf_":"Nf",
"_tabNo_":"No",
"_tabNu_":"Nu",
"_tabOr_":"Or",
"_tabRv_":"Rv",
"_tabSc_":"Sc",
"_tabSf_":"Sf",
"_tabShares_":"Shares",
"_tabSm_":"Sm",
"_tabSn_":"Sn",
"_tabSr_":"Sr",
"_tabSs_":"Ss",
"_tabSv_":"Sv",
"_tabTr_":"Tr",
"_tabUf_":"Uf",
"_tabUn_":"Un",
"_tabWf_":"Wf",
"_tabYes_":"Yes",
"_THEHEIRS_":"THE HEIRS",
"_tabLegend_":"Legend:- Sn:Sons, Dr:Daughters, Fr:Father, Mr:Mother, Hb:Husband, Wf:Wife, Br:Full brother, Sr:Full sister, Sm:Maternal half-siblings, Ss:Sons of sons, Ds:Daughters of sons, GF:Father of father, Gf:Mother of father, Un:Full brothers of father, Uf:Paternal half-brothers of father, Bf:Paternal half-brothers, Sf:Paternal half-sisters, Or:Other relatives, Nu:Sons of full brothers, Nf:Sons of paternal half-brothers, Gm:Mother of mother, Cz:Sons of full brothers of father, Cf:Sons of paternal half-brothers of father, Sv:Servants, Tr:Islamic treasury, Sc:Jusristic school, Rv:Reversion, Aw:Redivision, Bq:Bequest",

/* Test cases */
"_ALL_":"ALL",
"_Full_":"Full",
"_No_":"No",
"_testcase_":"Test case #",
"_testok_":"Test case calculation done",
"_Yes_":"Yes",

/* Special cases */
"_specialcase_":"Special case, Id: ",
"_spacialfailed_":"Special case processing failed",

    "_bequest1_": "Bequest is granted first",
    "_reducedbq_": "Share reduced due to bequest",
    "_treasury1_": "No heirs. Treasury receives the estate minus bequests, if any",
    "_treasury2_": "Sole heir and reversion to spouses is not allowed. Treasury receives the remainder",
    "_treasury3_": "Sole heir and reversion is not allowed. Treasury receives the remainder",
    "_treasury4_": "Estate is not fully distributed after applying all the rules. Remaining estate is given to the Islamic Treasury"
};
