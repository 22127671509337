import Preferences from "../Preferences";

class TestCases {

    //Test cases for calculating inheritance shares of eligible heirs according to Islamic law.
//Following cases implement the cases tabulated by ISNA[2] and Ibrahim[3,4].
//First 21 cases are famous jurisprudence cases mentioned in Sabeq[1] and many other books.

    /**
     *	An array of all the test cases, each is an array of 25 to 27 integers
     *	The integers are counts of heirs in the order followed throught the program, i.e.,
     *	bequest, son, daughter, father, mother, husband, wife, brother, sister, ...etc.
     *	The 26th index is the reversion rule. Default is 1, for allowRudd
     *	The 27th index is for the juristic school. Default is 1, for Hanafi
@version 2.0
     *	@since	October 2020
     */
    static testCases = [];

    static firstCase() {
        return 22;
    }
    
    static init() {

//Helper ruler aids in entering heir counts in the correct order
//May leave Rv (allow reversion) out. Default is 1 (Allow but not to spouses)
//May leave Sc (juristic school) out. Default is 1 (Hanafi)
//May leave Aw (allow oversubscription redivision) out. Default is 1 (Allowed)
//Helper ruler:BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
//Abu-Bakr
    this.testCases[0] = [0,2,3,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[1] = [0,2,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[2] = [0,2,3,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0];
//Omar (Gharraiya)
    this.testCases[3] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[4] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
//Ali (Minbariya)
    this.testCases[5] = [0,0,2,1,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
//Judge Shuraih
    this.testCases[6] = [0,0,0,0,1,1,0,0,2,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
//Shafiia
    this.testCases[7] = [0,0,2,0,1,0,1,12,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
//Kharqaa
    this.testCases[8] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
//Malikiya
    this.testCases[9] = [0,0,0,0,1,1,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,2];
    this.testCases[10] = [0,0,0,0,1,1,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,2];
    this.testCases[11] = [0,0,0,0,1,1,0,0,0,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,2];
//Pseudo-Malikiya
    this.testCases[12] = [0,0,0,0,1,1,0,2,0,2,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,2];
//Akdariya
    this.testCases[13] = [0,0,0,0,1,1,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];
    this.testCases[14] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];
    this.testCases[15] = [0,0,0,0,0,1,0,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];
    this.testCases[16] = [0,0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];
    this.testCases[17] = [0,0,0,0,1,0,1,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];
    this.testCases[18] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];
    this.testCases[19] = [0,0,0,0,0,0,1,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];
    this.testCases[20] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];
//Mushtarika (Himmariya)
    this.testCases[21] = [0,0,0,0,1,1,0,2,0,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,3];

   

    let t = this.firstCase();
//Cases of sons and daughters
//Helper ruler:  BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,2,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//22
    this.testCases[t++] = [0,2,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,1,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//30
    this.testCases[t++] = [0,2,3,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,1,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,1,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,1,1,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,1,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0];//40
    this.testCases[t++] = [0,2,3,1,0,1,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,0,1,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,2,3,0,0,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0];//47

//Cases of daughters and no sons
//Helper ruler:  BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//48
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//60
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,1,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];//70
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,1,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,1,0,0,0,0,0,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0];//80
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,0,0,0,0,0,0,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,0,0,0,0,0,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,3,4,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,3,4,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,3,4,1,0,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,3,4,1,1,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,1,1,0,0,0,0,3,4,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,3,4,1,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,3,4,1,0,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,3,4,1,1,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,4,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0];//95

//Cases of daughters and siblings
//Helper ruler:BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//96
    this.testCases[t++] = [0,0,2,0,0,0,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,0,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,0,0,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,1,0,0,0,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,1,3,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,4,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,4,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0];//108

//Cases of parents and no offspring
//Helper ruler:  BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//109
    this.testCases[t++] = [0,0,0,1,0,0,0,2,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,1,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,1,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,1,1,1,0,2,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,1,1,0,1,2,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//120
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//130
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvSc
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//140
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,3,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,1,0,0,3,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0];//150

//Cases of spouses
//Helper ruler:  BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//151
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,0,0,1,2,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,0,1,0,2,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,0,0,3,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,0,0,3,0,0,0,0,0,0,0,0,0,0];//160

//Cases of grandfather and siblings (Ali & Zaid)
//Helper ruler:  BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,0,3,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//161
    this.testCases[t++] = [0,0,0,0,0,0,0,3,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];
    this.testCases[t++] = [0,0,0,0,0,0,0,3,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,1];
    this.testCases[t++] = [0,0,0,0,0,0,0,3,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,6];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//170
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,1];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,6];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,1];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,6];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,0];
    this.testCases[t++] = [0,0,0,0,0,0,0,2,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,2,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];
    this.testCases[t++] = [0,0,0,0,0,0,0,2,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,1];//180

//Cases of a sole heir
//Helper ruler:    BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] =   [0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//181
    this.testCases[t++] =   [0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++] =   [0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2];
    this.testCases[t++] =   [0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2];
    this.testCases[t++] =   [0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    this.testCases[t++]=['1/3',0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//186

//Cases of no heirs
//Helper ruler:    BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++]  =  [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//187
    this.testCases[t++]=['1/3',0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];//188

//Cases of grandfathers and siblings (Zaid+Ali) (Ibrahim[4])
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.1a
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//190
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.1bc
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.2a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.2b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.2c
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.2de
    this.testCases[t++] = [0,0,0,0,0,0,0,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//200
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.3a
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.3b
    this.testCases[t++] = [0,0,0,0,0,0,0,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.3c
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z1.3d
    this.testCases[t++] = [0,0,0,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z1.4a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//210
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z1.4bc
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z1.5a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z1.5b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z1.5c
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z1.5de
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,5];//220
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z1.6a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,3];//Z1.6b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,3];//Z1.6c
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,3];//Z1.6d
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z1.7abcd
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//230
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z1.8a
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z1.8bc
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z1.8d
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z1.9a
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z1.10a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//240
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z1.10b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z1.10c
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z1.11a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z1.11b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z1.11cd
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];//250
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z1.11e
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z1.11fg
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z1.12a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z1.12b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z1.13ab
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//260
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z1.14ab
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z1.15
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.1a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.1a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.1bc
    this.testCases[t++] = [0,0,1,0,0,0,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//270
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.1bc1
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.1de
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.1de1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//280
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2b
    this.testCases[t++] = [0,0,1,0,0,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2b1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2c
    this.testCases[t++] = [0,0,1,0,0,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2c1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2de
    this.testCases[t++] = [0,0,1,0,0,0,0,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//290
    this.testCases[t++] = [0,0,0,0,0,0,0,0,4,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2de1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,4,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2f
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2f1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2gh
    this.testCases[t++] = [0,0,2,0,0,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.2gh1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//300
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3b
    this.testCases[t++] = [0,0,1,0,0,0,0,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,2,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,2,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3c
    this.testCases[t++] = [0,0,1,0,0,0,0,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//310
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3c1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3d
    this.testCases[t++] = [0,0,1,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,2,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3d1
    this.testCases[t++] = [0,0,0,0,0,0,0,2,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3e
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z2.3e1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//320
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.4a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.4a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z2.4bc
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z2.4bc1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.4de
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//330
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.4de1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.5a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.5a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z2.5b
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z2.5b1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];//340
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z2.5c
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z2.5c1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z2.5de
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z2.5de1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,4,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.5f
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//350
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.5f1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z2.5gh
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z2.5gh1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.6a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.6a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//360
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,3];//Z2.6b
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,2,0,0,0,0,0,0,0,1,3];//Z2.6b1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,3];//Z2.6c
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,3,0,0,0,0,0,0,0,1,3];//Z2.6c1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,3];//Z2.6d
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,5];//370
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,0,0,0,0,1,3];//Z2.6d1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.6e
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.6e1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.7ab
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.7ab1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//380
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.7c
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.7c1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.8a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.8a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z2.8b
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];//390
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z2.8b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.8c
    this.testCases[t++] = [0,0,1,0,0,0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.8c1
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.8d
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.8d1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//400
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.9a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.9a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.9b
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.9b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.10a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//410
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.10a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z2.10b
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z2.10b1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.10c
    this.testCases[t++] = [0,0,1,0,0,0,0,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.10c1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//420
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.10d
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.10d1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z2.11b
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];//430
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z2.11b1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z2.11cd
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z2.11cd1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11e
    this.testCases[t++] = [0,0,1,0,0,0,0,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11e1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//440
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,0,3,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11f
    this.testCases[t++] = [0,0,1,0,0,0,0,0,3,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11f1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,4,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11g
    this.testCases[t++] = [0,0,1,0,0,0,0,0,4,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,4,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11g1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,4,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11h
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//450
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.11h1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.12abc
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.12abc1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.12d
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.12d1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//460
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.13a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.13a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.13b
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z2.13b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.14ab
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//470
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.14ab1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.14c
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z2.14c1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.15a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.15a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//480
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.15b
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z2.15b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.1a
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.1bc
    this.testCases[t++] = [0,0,0,0,0,1,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.2a
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//490
    this.testCases[t++] = [0,0,0,0,0,1,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.2b
    this.testCases[t++] = [0,0,0,0,0,1,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.2c
    this.testCases[t++] = [0,0,0,0,0,1,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.2de
    this.testCases[t++] = [0,0,0,0,0,1,0,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.3a6a
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.3b6b
    this.testCases[t++] = [0,0,0,0,0,1,0,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//500
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,1,0,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.3c6c
    this.testCases[t++] = [0,0,0,0,0,1,0,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z3.3d6d
    this.testCases[t++] = [0,0,0,0,0,1,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z3.4a
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z3.4bc
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z3.5a
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//510
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z3.5b
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z3.5c
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z3.5de
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z3.6a
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,3];//Z3.6b
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,5];//520
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,3];//Z3.6c
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,3];//Z3.6d
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z3.7ab
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z3.8a
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z3.8bc
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];//530
    this.testCases[t++] = [0,0,0,0,0,1,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z3.8d
    this.testCases[t++] = [0,0,0,0,0,1,0,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z3.9
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z3.10a
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z3.10b
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z3.10c
    this.testCases[t++] = [0,0,0,0,0,1,0,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//540
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z3.11a
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z3.11b
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z3.11cd
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z3.11e
    this.testCases[t++] = [0,0,0,0,0,1,0,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,3,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z3.11fg
    this.testCases[t++] = [0,0,0,0,0,1,0,0,3,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//550
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z3.12abc
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z3.13
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z3.14ab
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z3.15
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.1a
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//560
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,1,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.1bc
    this.testCases[t++] = [0,0,0,0,0,0,1,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.2a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.2b
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.2c
    this.testCases[t++] = [0,0,0,0,0,0,1,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.2de
    this.testCases[t++] = [0,0,0,0,0,0,1,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//570
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.3a6a
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.3b6b
    this.testCases[t++] = [0,0,0,0,0,0,1,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.3c6c
    this.testCases[t++] = [0,0,0,0,0,0,1,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z4.3d6d
    this.testCases[t++] = [0,0,0,0,0,0,1,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z4.4a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//580
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z4.4bc
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z4.5a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z4.5b
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z4.5c
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z4.5de
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,5];//590
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z4.6a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,3];//Z4.6b
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,3];//Z4.6c
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,3];//Z4.6d
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z4.7abc
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//600
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z4.8a
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z4.8bcd
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z4.9
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z4.10a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z4.10b
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];//610
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z4.10c
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z4.11a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z4.11b
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z4.11cd
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z4.11e
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//620
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z4.11f
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z4.12abc
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z4.13ab
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z4.14ab
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z4.15
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//630
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.1a
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.1bc
    this.testCases[t++] = [0,0,0,0,1,0,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.2a
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.2b
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.2c
    this.testCases[t++] = [0,0,0,0,1,0,0,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//640
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,0,0,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.2de
    this.testCases[t++] = [0,0,0,0,1,0,0,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.3a
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.3b
    this.testCases[t++] = [0,0,0,0,1,0,0,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.3c
    this.testCases[t++] = [0,0,0,0,1,0,0,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z5.3
    this.testCases[t++] = [0,0,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//650
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z5.4a
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z5.4bc
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z5.5a
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z5.5b
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z5.5c
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];//660
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z5.5de
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z5.6a
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,3];//Z5.6b
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,3];//Z5.6c
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,3];//Z5.6c
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,5];//670
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z5.7abcd
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z5.8a
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z5.8bcd
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z5.9
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z5.10a
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//680
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z5.10b
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z5.10c
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z5.11a
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z5.11b
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z5.11cd
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];//690
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z5.11e
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z5.11f
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z5.12ab
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z5.12c
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z5.13ab
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//700
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z5.14ab
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z5.15
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.1a
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.1bc
    this.testCases[t++] = [0,0,0,0,0,0,0,2,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.2a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//710
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.2b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.2c5c
    this.testCases[t++] = [0,0,0,0,0,0,0,0,3,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,4,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.2de
    this.testCases[t++] = [0,0,0,0,0,0,0,0,4,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.3a
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.3b
    this.testCases[t++] = [0,0,0,0,0,0,0,1,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//720
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.3c
    this.testCases[t++] = [0,0,0,0,0,0,0,1,3,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z6.3d
    this.testCases[t++] = [0,0,0,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z6.4a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z6.4bc
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z6.5a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//730
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z6.5b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z6.5c
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z6.5de
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,4,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z6.6a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,2,0,0,0,0,0,0,0,1,3];//Z6.6b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,2,0,0,0,0,0,0,0,1,5];//740
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,3,0,0,0,0,0,0,0,1,3];//Z6.6c
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,1,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,0,0,0,0,1,3];//Z6.6d
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,2,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z6.7abcd
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z6.8a
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z6.8bcd
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];//750
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z6.9
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z6.10a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z6.10b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z6.10c
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z6.11a
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//760
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z6.11b
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z6.11cd
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,4,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z6.11e
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z6.11f
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z6.12ab
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//770
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z6.12c
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z6.13ab
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z6.14ab
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z6.15
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z7.1a
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//780
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,1,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z7.1b
    this.testCases[t++] = [0,0,0,0,1,1,0,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z7.2a (Akdaria)
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z7.2b
    this.testCases[t++] = [0,0,0,0,1,1,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z7.3
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z7.4a
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//790
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z7.4b
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z7.5a (Akdaria)
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z7.5b
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z7.6
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z7.7
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//800
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z7.8
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z7.9
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z7.10
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z7.11ab
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z7.12
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//810
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z7.13
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z7.14
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z7.15
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z8.1ab
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z8.2a
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//820
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,1,0,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z8.2bc
    this.testCases[t++] = [0,0,0,0,0,1,0,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z8.3
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z8.4ab
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z8.5a
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z8.5bc
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];//830
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z8.6
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z8.7
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z8.8
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z8.9
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z8.10
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//840
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z8.11ab
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z8.12
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z8.13
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z8.14
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z8.15
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//850
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.1a
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.1ab
    this.testCases[t++] = [0,0,0,0,1,0,1,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.2a
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.2b
    this.testCases[t++] = [0,0,0,0,1,0,1,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.2c
    this.testCases[t++] = [0,0,0,0,1,0,1,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//860
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,0,1,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.2de
    this.testCases[t++] = [0,0,0,0,1,0,1,0,4,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.3a
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.3b
    this.testCases[t++] = [0,0,0,0,1,0,1,1,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.3c
    this.testCases[t++] = [0,0,0,0,1,0,1,1,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z9.3d
    this.testCases[t++] = [0,0,0,0,1,0,1,2,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//870
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z9.4a
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z9.4ab
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z9.5a
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z9.5b
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z9.5c
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];//880
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z9.5de
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,0,4,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z9.6a
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,3];//Z9.6b
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,1,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,3];//Z9.6c
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,1,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,3];//Z9.6d
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,1,5];//890
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z9.7ab
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,2,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z9.7c
    this.testCases[t++] = [0,0,0,0,1,0,1,2,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z9.8a
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z9.8a
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z9.8a
    this.testCases[t++] = [0,0,0,0,1,0,1,2,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//900
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,0,1,2,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z9.9
    this.testCases[t++] = [0,0,0,0,1,0,1,2,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z9.10a
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z9.10bd
    this.testCases[t++] = [0,0,0,0,1,0,1,0,2,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z9.10c
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z9.11a
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//910
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z9.11b
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z9.11cd
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z9.11e
    this.testCases[t++] = [0,0,0,0,1,0,1,0,2,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,3,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z9.11f
    this.testCases[t++] = [0,0,0,0,1,0,1,0,3,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z9.12ab
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//920
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z9.13
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z9.14
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z9.15
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.1a
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,2,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.1bc
    this.testCases[t++] = [0,0,0,0,0,0,1,2,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//930
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.2a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.2b
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,3,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.2c
    this.testCases[t++] = [0,0,0,0,0,0,1,0,3,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,4,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.2de
    this.testCases[t++] = [0,0,0,0,0,0,1,0,4,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.3a
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//940
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,1,1,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.3b
    this.testCases[t++] = [0,0,0,0,0,0,1,1,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,3,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.3c
    this.testCases[t++] = [0,0,0,0,0,0,1,1,3,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,2,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.3d
    this.testCases[t++] = [0,0,0,0,0,0,1,2,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z10.4a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z10.4bc
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,2,0,0,0,0,0,0,0,0,1,5];//950
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z10.5a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z10.5b
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z10.5c
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,0,4,0,0,0,0,0,0,0,1,3];//Z10.5de
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,0,4,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z10.6a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//960
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,1,2,0,0,0,0,0,0,0,1,3];//Z10.6b
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,1,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,1,3,0,0,0,0,0,0,0,1,3];//Z10.6c
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,0,1,1,0,0,1,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,2,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z10.6d
    this.testCases[t++] = [0,0,0,0,0,0,1,2,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z10.7abc
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z10.8a
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//970
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z10.8bc
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,2,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z10.8d
    this.testCases[t++] = [0,0,0,0,0,0,1,2,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z10.9
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z10.10a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z10.10c
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,2,0,0,0,0,0,0,0,0,1,5];//980
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z10.10bd
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z10.11a
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z10.11b
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z10.11cd
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z10.11e
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//990
    this.testCases[t++] = [0,0,0,0,0,0,1,0,3,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z10.11e
    this.testCases[t++] = [0,0,0,0,0,0,1,0,3,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z10.12ab
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z10.13
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z10.14ab
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z10.15
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1000
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.1a4a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.1a4a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.1b4b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.1b4b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.2a5a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1010
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.2a5a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.2a5a
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.2a5a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.3a6a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.3a6a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1020
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.3b6b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z11.3b6b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.7a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.7a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.7b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1030
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.7b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.8a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.8a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.8b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.8b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1040
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.9a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.9a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.9b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.9b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.10a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1050
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.10a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.10b
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.10b1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.11a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.11a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1060
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.11b
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.11b1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.12a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.12a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.12b
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1070
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.12b1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.13a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.13a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.13b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z11.13b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1080
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.14a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.14a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.14b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z11.14b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.15a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1090
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.15a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.15b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z11.15b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.1a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.1a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1100
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,1,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.1b
    this.testCases[t++] = [0,0,1,0,0,0,1,2,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.1b1
    this.testCases[t++] = [0,0,0,0,0,0,1,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.1c
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.1c1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.2a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1110
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.2a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.2b
    this.testCases[t++] = [0,0,1,0,0,0,1,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.2b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,2,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.2c
    this.testCases[t++] = [0,0,1,0,0,0,1,0,3,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,3,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.2c1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,3,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1120
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.2d
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.2d1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.3a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.3a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.3b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1130
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z12.3b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.4a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.4a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z12.4b
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,0,1,3];//Z12.4b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,0,1,5];//1140
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.4c
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.4c1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.5a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.5a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z12.5b
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];//1150
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z12.5b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z12.5c
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,3,0,0,0,0,0,0,0,1,3];//Z12.5c1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,3,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.5d
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.5d1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1160
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.6a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.6a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.6b
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.6b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.7a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1170
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.7a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.7b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.7b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.8a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.8a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1180
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.8b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.8b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.9a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.9a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.9b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1190
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.9b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.10a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.10a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.10b
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.10b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1200
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.11a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.11a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z12.10b
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z12.10b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.11c
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1210
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.11c1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.12a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.12a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.12b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.12b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1220
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.13a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.13a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.13b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z12.13b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.14a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1230
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.14a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.14b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z12.14b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.15a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.15a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1240
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.15b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z12.15b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.1ab
    this.testCases[t++] = [0,0,1,0,1,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.1ab1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.1c
    this.testCases[t++] = [0,0,2,0,1,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1250
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.1c1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.2a
    this.testCases[t++] = [0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.2a1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.2bc
    this.testCases[t++] = [0,0,1,0,1,0,0,0,2,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.2bc1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,2,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1260
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.2d
    this.testCases[t++] = [0,0,2,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.2d1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.3a
    this.testCases[t++] = [0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.3a1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.3b
    this.testCases[t++] = [0,0,2,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1270
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z13.3b1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.4ab
    this.testCases[t++] = [0,0,1,0,1,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.4ab1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.4c
    this.testCases[t++] = [0,0,2,0,1,0,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.4c1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1280
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,1,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.5a
    this.testCases[t++] = [0,0,1,0,1,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.5a1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z13.5bc
    this.testCases[t++] = [0,0,1,0,1,0,0,0,0,0,0,0,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z13.5bc1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.5d
    this.testCases[t++] = [0,0,2,0,1,0,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1290
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.5d1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.6a
    this.testCases[t++] = [0,0,1,0,1,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.6a1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.6b
    this.testCases[t++] = [0,0,2,0,1,0,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.6b1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1300
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,1,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.7a
    this.testCases[t++] = [0,0,1,0,1,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.7a1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.7b
    this.testCases[t++] = [0,0,2,0,1,0,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.7b1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.8a
    this.testCases[t++] = [0,0,1,0,1,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1310
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.8a1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.8b
    this.testCases[t++] = [0,0,2,0,1,0,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.8b1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.9a
    this.testCases[t++] = [0,0,1,0,1,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.9a1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1320
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,1,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.9b
    this.testCases[t++] = [0,0,2,0,1,0,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.9b1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.10a
    this.testCases[t++] = [0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.10a1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.10b
    this.testCases[t++] = [0,0,2,0,1,0,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1330
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.10b1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.11ab
    this.testCases[t++] = [0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.11ab1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.11c
    this.testCases[t++] = [0,0,2,0,1,0,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.11c1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1340
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.12a
    this.testCases[t++] = [0,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.12a1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.12b
    this.testCases[t++] = [0,0,2,0,1,0,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.12b1
    this.testCases[t++] = [0,0,0,0,1,0,0,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.13a
    this.testCases[t++] = [0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1350
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.13a1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.13b
    this.testCases[t++] = [0,0,2,0,1,0,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z13.13b1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.14a
    this.testCases[t++] = [0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.14a1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1360
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,1,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.14b
    this.testCases[t++] = [0,0,2,0,1,0,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z13.14b1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.15a
    this.testCases[t++] = [0,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.15a1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.15b
    this.testCases[t++] = [0,0,2,0,1,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1370
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z13.15b1
    this.testCases[t++] = [0,0,0,0,1,0,0,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.1ab
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.1ab1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.1c
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.1c1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//1380
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.2a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.2a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.2bc
    this.testCases[t++] = [0,0,1,0,0,0,0,0,2,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.2bc1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,2,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.2d
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//1390
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.2d1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.3a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.3a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.3b
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z14.3b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//1400
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.4ab
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.4ab1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.4c
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.4c1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.5a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1410
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.5a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z14.5bc
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,0,2,0,0,0,0,0,0,0,1,3];//Z14.5bc1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,0,2,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.5d
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.5d1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1420
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.6a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.6a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.6b
    this.testCases[t++] = [0,0,2,0,0,0,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.6b1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.7a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//1430
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.7a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.8a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.8a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.8b
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.8b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1440
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.9a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.9a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.9b
    this.testCases[t++] = [0,0,2,0,0,0,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.9b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.10a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//1450
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.10a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.10b
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.10b1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.11ab
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.11ab1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1460
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.11c
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.11c1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.12a
    this.testCases[t++] = [0,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.12a1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.12b
    this.testCases[t++] = [0,0,2,0,0,0,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1470
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.12b1
    this.testCases[t++] = [0,0,0,0,0,0,0,0,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.13a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.13a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.13b
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z14.13b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//1480
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.14a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.14a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.14b
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z14.14b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.15a
    this.testCases[t++] = [0,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1490
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.15a1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.15b
    this.testCases[t++] = [0,0,2,0,0,0,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z14.15b1
    this.testCases[t++] = [0,0,0,0,0,0,0,1,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.1a
    this.testCases[t++] = [0,0,1,0,1,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.1a1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1500
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,1,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.1b
    this.testCases[t++] = [0,0,2,0,1,1,0,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.1b1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.2a
    this.testCases[t++] = [0,0,1,0,1,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.2a1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.2b
    this.testCases[t++] = [0,0,2,0,1,1,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1510
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.2b1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.3a
    this.testCases[t++] = [0,0,1,0,1,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.3a1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.3b
    this.testCases[t++] = [0,0,2,0,1,1,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z15.3b1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1520
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,1,1,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.4a
    this.testCases[t++] = [0,0,1,0,1,1,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.4a1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.4b
    this.testCases[t++] = [0,0,2,0,1,1,0,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.4b1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.5a
    this.testCases[t++] = [0,0,1,0,1,1,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1530
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.5a1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.5b
    this.testCases[t++] = [0,0,2,0,1,1,0,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.5b1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.6a
    this.testCases[t++] = [0,0,1,0,1,1,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.6a1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1540
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,1,1,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.3b6b
    this.testCases[t++] = [0,0,2,0,1,1,0,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.3b6b1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.7a
    this.testCases[t++] = [0,0,1,0,1,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.7a1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.7b
    this.testCases[t++] = [0,0,2,0,1,1,0,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1550
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.7b1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.8a
    this.testCases[t++] = [0,0,1,0,1,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.8a1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.8b
    this.testCases[t++] = [0,0,2,0,1,1,0,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.8b1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1560
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,1,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.9a
    this.testCases[t++] = [0,0,1,0,1,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.9a1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.9b
    this.testCases[t++] = [0,0,2,0,1,1,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.9b1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.10a
    this.testCases[t++] = [0,0,1,0,1,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1570
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.10a1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.10b
    this.testCases[t++] = [0,0,2,0,1,1,0,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.10b1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.11a
    this.testCases[t++] = [0,0,1,0,1,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.11a1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1580
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,1,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.11b
    this.testCases[t++] = [0,0,2,0,1,1,0,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.11b1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.12a
    this.testCases[t++] = [0,0,1,0,1,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.12a1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.12b
    this.testCases[t++] = [0,0,2,0,1,1,0,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1590
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.12b1
    this.testCases[t++] = [0,0,0,0,1,1,0,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.13a
    this.testCases[t++] = [0,0,1,0,1,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.13a1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.13b
    this.testCases[t++] = [0,0,2,0,1,1,0,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.13b1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1600
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,1,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.14a
    this.testCases[t++] = [0,0,1,0,1,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.14a1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.14b
    this.testCases[t++] = [0,0,2,0,1,1,0,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z15.14b1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.15a
    this.testCases[t++] = [0,0,1,0,1,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1610
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.15a1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.15b
    this.testCases[t++] = [0,0,2,0,1,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z15.15b1
    this.testCases[t++] = [0,0,0,0,1,1,0,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.1a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.1a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//1620
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.1b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.1b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.2a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.2a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.2b
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//1630
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.2b1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.3a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.3a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.3b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.3b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//1640
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.4a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.4a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.4b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z16.4b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.5a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1650
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.5a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.5b
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.5b1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.6a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.6a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1660
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.6b
    this.testCases[t++] = [0,0,2,0,0,1,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.6b1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.7a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.7a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.7b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//1670
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.7b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.8a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.8a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.8b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.8b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1680
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.9a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.9a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.9b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.9b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.10a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//1690
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.10a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.10b
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.10b1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.11a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.11a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1700
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.11b
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.11b1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.12a
    this.testCases[t++] = [0,0,1,0,0,1,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.12a1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.12b
    this.testCases[t++] = [0,0,2,0,0,1,0,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1710
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.12b1
    this.testCases[t++] = [0,0,0,0,0,1,0,0,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.13a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z16.13a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.16b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z15.16b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//1720
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.14a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.14a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.14b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z16.14b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.15a
    this.testCases[t++] = [0,0,1,0,0,1,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1730
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.15a1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.15b
    this.testCases[t++] = [0,0,2,0,0,1,0,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z16.15b1
    this.testCases[t++] = [0,0,0,0,0,1,0,1,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.1a
    this.testCases[t++] = [0,0,1,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.1a1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1740
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.1b
    this.testCases[t++] = [0,0,2,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.1b1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.2a
    this.testCases[t++] = [0,0,1,0,1,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.2a1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.2b
    this.testCases[t++] = [0,0,2,0,1,0,1,0,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1750
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.2b1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.3a
    this.testCases[t++] = [0,0,1,0,1,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.3a1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.3b
    this.testCases[t++] = [0,0,2,0,1,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z17.3b1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,1,5];//1760
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,1,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.4a
    this.testCases[t++] = [0,0,1,0,1,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.4a1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.4b
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.4b1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.5a
    this.testCases[t++] = [0,0,1,0,1,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1770
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.5a1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.5b
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.5b1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.6a
    this.testCases[t++] = [0,0,1,0,1,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.6a1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1780
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.6b
    this.testCases[t++] = [0,0,2,0,1,0,1,0,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.6b1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.7a
    this.testCases[t++] = [0,0,1,0,1,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.7a1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.7b
    this.testCases[t++] = [0,0,2,0,1,0,1,1,0,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1790
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.7b1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.8a
    this.testCases[t++] = [0,0,1,0,1,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.8a1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.8b
    this.testCases[t++] = [0,0,2,0,1,0,1,1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.8b1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1800
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,1,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.9a
    this.testCases[t++] = [0,0,1,0,1,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.9a1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.9b
    this.testCases[t++] = [0,0,2,0,1,0,1,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.9b1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,0,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.10a
    this.testCases[t++] = [0,0,1,0,1,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1810
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.10a1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.10b
    this.testCases[t++] = [0,0,2,0,1,0,1,0,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.10b1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.11a
    this.testCases[t++] = [0,0,1,0,1,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.11a1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];//1820
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,1,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.11b
    this.testCases[t++] = [0,0,2,0,1,0,1,0,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.11b1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.12a
    this.testCases[t++] = [0,0,1,0,1,0,1,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.12a1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.12b
    this.testCases[t++] = [0,0,2,0,1,0,1,0,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1830
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.12b1
    this.testCases[t++] = [0,0,0,0,1,0,1,0,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.13a
    this.testCases[t++] = [0,0,1,0,1,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.13a1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.13b
    this.testCases[t++] = [0,0,2,0,1,0,1,1,1,0,0,0,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z17.13b1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,2,1,0,0,0,1,0,0,0,0,0,0,0,0,1,5];//1840
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,1,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.14a
    this.testCases[t++] = [0,0,1,0,1,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.14a1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.14b
    this.testCases[t++] = [0,0,2,0,1,0,1,1,1,0,0,0,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z17.14b1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,2,1,0,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,1,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.15a
    this.testCases[t++] = [0,0,1,0,1,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];//1850
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.15a1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,1,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.15b
    this.testCases[t++] = [0,0,2,0,1,0,1,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z17.15b1
    this.testCases[t++] = [0,0,0,0,1,0,1,1,1,0,0,2,1,0,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.1a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.1a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//1860
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.1b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.1b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.2a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.2a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.2b
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//1870
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.2b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.3a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.3a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.3b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,3];//Z18.3b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,1,0,0,0,0,0,0,0,0,0,0,0,1,5];//1880
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.4a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.4a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.4b
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.4b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.5a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1890
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.5a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.5b
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.5b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.6a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.6a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1900
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.6b
    this.testCases[t++] = [0,0,2,0,0,0,1,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.6b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.7a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.7a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.7b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//1910
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.7b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.8a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.8a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.8b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.8b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1920
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.9a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.9a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.9b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.9b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,0,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.10a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//1930
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.10a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.10b
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.10b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.11a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.11a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];//1940
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.11b
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.11b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.12a
    this.testCases[t++] = [0,0,1,0,0,0,1,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.12a1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.12b
    this.testCases[t++] = [0,0,2,0,0,0,1,0,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1950
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.12b1
    this.testCases[t++] = [0,0,0,0,0,0,1,0,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.13a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.13a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.13b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,3];//Z18.13b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,1,0,0,1,0,0,0,0,0,0,0,0,1,5];//1960
//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvScAw
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.14a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.14a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.14b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,3];//Z18.14b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,1,0,0,0,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.15a
    this.testCases[t++] = [0,0,1,0,0,0,1,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1970
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.15a1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,1,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.15b
    this.testCases[t++] = [0,0,2,0,0,0,1,1,1,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,3];//Z18.15b1
    this.testCases[t++] = [0,0,0,0,0,0,1,1,1,0,0,2,1,1,0,0,1,1,0,0,0,0,0,0,0,1,5];//1976
        
        
        }
        

    /**
     *	Gets the last test case
     *	@return	int: number of the last test case
     */
    static lastCase() {
        //return t-1;
        return 188;
    }

    /**
     *	Gets a specific test case
     *	@return	Array of int heir counts of specified test case
     */
    static getTestCase(caseNumber) {
        if (this.testCases === []) this.init();
        return this.testCases[caseNumber];
    }

    /**
     *	Gets a random test case
     *	@return	int: number of a random test case
     */
    static getRandomTestCase() {
        let lc = TestCases.lastCase();
        // noinspection UnnecessaryLocalVariableJS
        let tc = Math.floor(Math.random() * lc);
        return tc;
    }

    /**
     *	Utility to generate test cases to append from the lookup cases
     */
    static appendSpecialTestCases() {
        if (this.testCases === []) this.init();

        let s = "";
        for (let i=0; i<Preferences.lookupPatterns.length; i++) {
            let entry = Preferences.lookupPatterns[i];
            let hl = (entry.hl).split(",");
            let hc = (entry.hc).split(",");
            let id = entry.id;
            for (let j=0; j<hl.length; j++) {
                let val = hl[j];	//string, e.g. "brother"
                // eslint-disable-next-line no-eval
                hl[j] = eval(val);	//int, e.g., 7 for brother
                console.log("hl[" + j + "] = " + hl[j] + "\n");
            }
            for (let k=0; k<hc.length; k++) {
                let val = hc[k].toString();	//string, e.g., "2" or ">1"
                let num = 0;
                if (val.indexOf(">")!==-1) {
                    num = val.substring(1)-0+1;	//int, e.g. >2 becomes 3
                }
                else num = val-0;
                hc[k] = num;
                console.log("hc[" + k + "] = " + hc[k] + "\n");
            }
            let kace3 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,3];	//3 is Shafii school (Zaid)
            let kace5 = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,5];	//5 is Egyptian law (Ali)
            for (let h=0; h<hl.length && h<hc.length; h++) {
                kace3[hl[h]] = hc[h]*1;
                kace5[hl[h]] = hc[h]*1;
                console.log("kace[" + hl[h] + "] = " + hc[h] + "\n");
            }
            if (i>0) s+= "\n";
            s += "this.testCases[t++] = [" + kace3.toString() + "];//" + id;
            let k3 = TestCases.lastCase() + i*2 + 1;
            if (k3%10===0) s+= "//" + k3;
            s += "\nthis.testCases[t++] = [" + kace5.toString() + "];";
            let k5 = k3 + 1;
            if (k5%10===0) s+= "//" + k5;
            if (k3%20===0 || k5%20===0)
                s+= "\n//Helper ruler:. BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvSc";

        }
        console.log(s);
    }

    static reorderCaseHeirs(c) {
        if (this.testCases === []) this.init();

//Helper ruler:BqSnDrSsDsHbWfFrMrGFGfGmBrSrBfSfSmNuNfUnUfCzCfSvRmRvSc
//              0 1 2 3 4 5 6 7 8 9101112131415161718192021222324
//Helper ruler:BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvSc
//              0 1 2 7 8 5 6121316 3 4 9101920141524171811212223
        let cace = TestCases.getTestCase(c);
        let rt = [];
        rt.push(cace[0]);
        rt.push(cace[1]);
        rt.push(cace[2]);
        rt.push(cace[7]);
        rt.push(cace[8]);
        rt.push(cace[5]);
        rt.push(cace[6]);
        rt.push(cace[12]);
        rt.push(cace[13]);
        rt.push(cace[16]);
        rt.push(cace[3]);
        rt.push(cace[4]);
        rt.push(cace[9]);
        rt.push(cace[10]);
        rt.push(cace[19]);
        rt.push(cace[20]);
        rt.push(cace[14]);
        rt.push(cace[15]);
        rt.push(cace[24]);
        rt.push(cace[18]);
        rt.push(cace[11]);
        rt.push(cace[21]);
        rt.push(cace[22]);
        rt.push(cace[23]);
        if (cace.length>24) rt.push(cace[24]);
        if (cace.length>25) rt.push(cace[25]);
        let out = "[";
        for (let i=0; i<rt.length; i++) {
            if (i>0) out += ",";
            out += rt[i];
        }
        out += "];\n";
        return out;
    }

    static reorderCasesHeirs() {
        if (this.testCases === []) this.init();

        let out = "//Helper ruler:BqSnDrFrMrHbWfBrSrSmSsDsGFGfUnUfBfSfRmNuNfGmCzCfSvRvSc\n";
        for (let i=0; i<TestCases.firstCase(); i++)
            out += "this.testCases[" + i + "] = " + TestCases.reorderCaseHeirs(i);
        for (let j=TestCases.firstCase(); j<=TestCases.lastCase(); j++)
            out += "this.testCases[t++] = " + TestCases.reorderCaseHeirs(j);
        return out;
    }


}

export default TestCases;