import React from 'react';
import styles from "./Intro.module.scss";
import NumInput from "../NumInput/NumInput";
import Mazhab from "../../../lib/inheritance/Calculator/Define/Mazhab";
import Preferences from "../../../lib/inheritance/Calculator/Preferences";
import {IconButton} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const Intro = props => {

    return (
        <div className={styles.Intro}>
            <section>
                <h4>Welcome to</h4>
                <h2>Islamic Inheritance Calculator</h2>
                <p>
                    Islamic inheritance is distributed in the following order:
                </p>
                <ol>
                    <li>
                        <strong>Funeral and burial expenses</strong>
                    </li>
                    <li>
                        <strong>Debts</strong>
                    </li>
                    <li>
                        <strong>Wasiyah</strong>: Discretion portion not to exceed 1/3
                    </li>
                    <li>
                        <strong>Faraid</strong>: Residuary, mandatory shares
                    </li>
                </ol>
                <p>
                    This free tool helps you calculate who the Islamic heirs are and what fractions they are entitled to
                    inherit. You can also enter dollar values for each of the items above to see how those fractions
                    would apply to a decedent’s estate.
                </p>
            </section>
            <section style={{
                backgroundColor: 'offwhite'
            }}>
                <h3>Options</h3>
                <h4>Inheritance Total Value</h4>
                <p>You may enter inheritance total value in dollar here, this value will be distributed to heirs:</p>
                <section>

                    <div className={styles.IrthTotalValue}>
                        <NumInput id={"irth-total-value"}/>
                        <strong>$</strong>
                    </div>

                </section>
                <h4>Juristic School</h4>
                <p>You can select Islamic Mazhab and other options here:</p>
                <table onClick={props.handleOptionsClickOpen} style={{cursor: 'pointer'}}>
                    <tbody>
                    <tr>
                        <td style={{width: '70px'}}>
                            <IconButton aria-label="settings" onClick={props.handleOptionsClickOpen}>
                                <AccountBalanceIcon/>
                            </IconButton>
                        </td>
                        <td style={{textAlign: 'start'}}>
                            <h3>{Mazhab.schoolNames[Preferences.school]}</h3>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </section>
        </div>
    );

};

export default Intro;
