import React from 'react';
import OptionInput from "../OptionInput/OptionInput";

const Spouse = () => {

    return (
        <tbody>
        <tr>
            <th>Heirs</th>
            <th>Number</th>
            <th>Share</th>
            <th>Total ($)</th>
        </tr>
        <tr>
            <td>Husband</td>
            <td>
                <OptionInput name="num5" id="num5" type="checkbox" tabIndex="5"
                             onClick={(e) => {
                                 if (e.target.checked === true) e.target.form.elements['num6'].checked = false;
                             }}/>
            </td>
            <td id="get5">&nbsp;</td>
            <td id="get5usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Wife</td>
            <td>
                <OptionInput name="num6" id="num6" type="checkbox" tabIndex="6"
                             onClick={(e) => {
                                 if (e.target.checked === true) e.target.form.elements['num5'].checked = false;
                             }}/>
            </td>
            <td id="get6">&nbsp;</td>
            <td id="get6usd">&nbsp;</td>
        </tr>
        </tbody>
    );

};

export default Spouse;
