import React from 'react';
import SwipeableViews from "react-swipeable-views-react-18-fix";

import Offsprings from "./Offseprings";
import Parents from "./Parents";
import Spouse from "./Spouse";
import Siblings from "./Siblings";
import Descendants from "./Descendants";
import Others from "./Others";
import {useTheme} from "@mui/material";

import styles from "./FormContent.module.scss";
import Intro from "./Intro";
import Result from "./Result";

const FormContent = props => {

    const theme = useTheme();



    return (
        <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={props.activeStep}
            enableMouseEvents
            onChangeIndex={props.onChangeIndex}
        >
            <div id="Intro" className={styles.InputTable}>
                <Intro
                    handleOptionsClickOpen={props.handleOptionsClickOpen} />
            </div>
            <table id="Heirs1" className={styles.InputTable}>
                <Offsprings/>
            </table>
            <table id="Heirs1" className={styles.InputTable}>
                <Parents/>
            </table>
            <table id="Heirs1" className={styles.InputTable}>
                <Spouse/>
            </table>
            <table id="Heirs1" className={styles.InputTable}>
                <Siblings/>
            </table>
            <table id="Heirs1" className={styles.InputTable}>
                <Descendants/>
            </table>
            <table id="Heirs1" className={styles.InputTable}>
                <Others/>
            </table>
            <div>
                <Result/>
            </div>
        </SwipeableViews>
    );

};

export default FormContent;
