class Common {

    static debugText = "";

    /**
     * Whether an element is in a given array
     * @param {Array} arr The array to search
     * @param {*} elem The element to find
     * @return {boolean} Returns true if the element is found, otherwise false
     */
    static inArray(arr, elem) {
        let found = false;
        if (typeof Array.indexOf === 'undefined') {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === elem) {
                    found = true;
                    break;
                }
            }
        } else {
            found = arr.indexOf(elem) !== -1;
        }
        return found;
    }

    /**
     * Logs a debugging text to the console
     * @param {string} message Debugging string to log
     */
    static debug(message) {
        console.log("[debug] " + message + "\n");
    }

    /**
     * Appends a string of detail to the global variable debugText
     * @param {string} detail Detail string to log
     * @see debugText
     */
    static detail(detail) {
        this.debugText += detail + "<br/>";
        console.log(detail + "\n");
    }

    /**
     * Parse the URL query string
     * @return {object} A key-value object corresponding to key=value query string syntax
     */
    static getArgs() {
        let args = {};
        let queryString = window.location.search ? window.location.search.substring(1) : null;
        if (queryString) {
            let pieces = queryString.split("&");
            for (let i = 0; i < pieces.length; i++) {
                let pos = pieces[i].indexOf('=');
                let arg, value;
                if (pos < 0) {
                    arg = i + "";
                    value = pieces[i];
                } else {
                    arg = pieces[i].substring(0, pos);
                    value = pieces[i].substring(pos + 1);
                }
                if (!typeof (Error)) { // JavaScript version earlier than 1.5
                    args[arg] = unescape(value);
                } else {
                    args[arg] = decodeURIComponent(value);
                }
            }
        }
        return args;
    }

    /**
     * Get a document element
     * @param {string} elementId The ID of the element to retrieve
     * @return {Element|null} The HTML element or null if not found
     */
    static getElement(elementId) {
        if (document.getElementById) {
            return document.getElementById(elementId);
        } else if (document.all) {
            return document.all(elementId);
        } else {
            return null;
        }
    }

    /**
     * Get a document element from window.opener
     * @param {string} elementId The ID of the element to retrieve from the parent window
     * @return {Element|null} The HTML element or null if not found
     */
    static getElementFromParent(elementId) {
        if (window.opener === null) {
            return null;
        }
        if (!elementId) {
            return null;
        }
        let el;
        if (document.getElementById) {
            el = window.opener.document.getElementById(elementId);
        } else if (document.all) {
            el = window.opener.document.all(elementId);
        } else {
            el = null;
        }
        return el;
    }

    /**
     * Output a nice representation of an object
     * @param {object} obj The object to represent as a string
     * @param {string} newline The newline character to use (default: "<br/>")
     * @return {string} A formatted string representation of the object
     */
    static prettyObject(obj, newline) {
        let str = "";
        for (let key in obj) {
            str += key + ": " + obj[key] + (newline ? newline : "<br/>");
        }
        return str;
    }

    /**
     * Whether a variable is an array
     * @param {*} variable The variable to check
     * @return {boolean} Returns true if the variable is an array, otherwise false
     */
    static isArray(variable) {
        return (variable instanceof Array) || (variable.constructor.toString().indexOf("Array") > -1);
    }

    /**
     * Asserts a condition
     * @param {boolean} condition The condition to be asserted
     * @param {string} description A string describing the condition to be asserted
     * @return {string|null} Null if condition is asserted, or a string describing the failure if it fails
     */
    static notasserted(condition, description) {
        if (false === condition) {
            return description;
        } else {
            return null;
        }
    }

    /**
     * Asserts a condition
     * @param {boolean} condition The assertion
     * @param {string} message An assertion failure message
     * @return {boolean} The assertion value
     */
    static ensure(condition, message) {
        if (this.notasserted(condition, message)) {
            console.error(message);
        }
        return condition;
    }

    /**
     * Map of Indian numerals to their Arabic counterparts
     */
    static indianToArabicNumerals = {
        "٠": 0, "١": 1, "٢": 2, "٣": 3, "٤": 4, "٥": 5, "٦": 6, "٧": 7, "٨": 8, "٩": 9
    };

    /**
     * Array of Indian numerals
     */
    static indianNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

    /**
     * Whether a character is an Indian numeral
     * @param {string|number} character The character or integer to check
     * @return {boolean} Returns true if the input is an Indian numeral, otherwise false
     */
    static isIndianNumeral(character) {
        return /٠|١|٢|٣|٤|٥|٦|٧|٨|٩/.test(character) === true;
    }

    /**
     * Converts an Indian numeral to an Arabic numeral
     * @param {string|number} indianNumeral The Indian numeral to convert
     * @return {number|null} Returns the Arabic numeral, or null if not a valid Indian numeral
     */
    static arabicNumeral(indianNumeral) {
        if (this.isIndianNumeral(indianNumeral)) {
            return this.indianToArabicNumerals[indianNumeral];
        } else {
            return null;
        }
    }

    /**
     * Whether a string contains only Indian numerals
     * @param {string} indianNumberString The string to check
     * @return {boolean} Returns true if the string consists solely of Indian numerals, otherwise false
     */
    static isIndianNumber(indianNumberString) {
        return /^[\u0660-\u0669]+$/.test(indianNumberString);
    }

    /**
     *	Convert a string of Indian numbers to an integer, expressed normally as a sequence of Arabic numerals
     *	The string must contain only Indian numerals, no minuses, no thousands separator, etc.
     *	Non-numeric characters in the input string will be skipped
     *	@return	int: The Arabic number
     */
    static  arabicNumber(indianNumber) {
        if (this.isIndianNumber(indianNumber)) {
            let ret = "";
            for (let i=0; i<indianNumber.length; i++)
                ret += this.arabicNumeral(indianNumber[i]);
            return ret-0;
        }
        else return 0;
    }


    //Cookies:
    /** Original JavaScript code by Chirp Internet: www.chirp.com.au
     *	Please acknowledge use of this code by including this header.
     *	@param	name string, the cookie name
     *	@return	cookie value or null if it's not found
     *	@author	Chirp Internet: www.chirp.com.au
     */
    static  auGetCookie(name) {
        let re = new RegExp(name + "=([^;]+)");
        let value = re.exec(document.cookie);
        return (value != null) ? decodeURI(value[1]) : null;
    }


    //End code courtesy of Chirp Internet

    // Cookie-related methods

    /**
     * Get the value of a cookie
     * @param {string} cookieName The name of the cookie to retrieve
     * @return {string} The value of the cookie, or an empty string if not found
     */
    static getCookieValue(cookieName) {
        let nameEq = cookieName + "=";
        let cookieValue;
        if (!typeof (Error)) { // JavaScript version earlier than 1.5
            cookieValue = unescape(document.cookie);
        } else {
            cookieValue = decodeURIComponent(document.cookie);
        }
        let cookieParts = cookieValue.split(';');
        for (let i = 0; i < cookieParts.length; i++) {
            let part = cookieParts[i];
            while (part.charAt(0) === ' ') {
                part = part.substring(1);
            }
            if (part.indexOf(nameEq) === 0) {
                return part.substring(nameEq.length, part.length);
            }
        }
        return "";
    }

    /**
     * Checks if a cookie is set
     * @param {string} cookieName The name of the cookie to check
     * @return {boolean} Returns true if the cookie is set, otherwise false
     */
    static isCookieSet(cookieName) {
        let value = this.getCookieValue(cookieName);
        return (value !== "");
    }

    /**
     * Sets a cookie with optional expiration
     * @param {string} cookieName The name of the cookie to set
     * @param {string} cookieValue The value of the cookie
     * @param {number} [expirationSeconds] The expiration time in seconds (optional)
     */
    static setCookie(cookieName, cookieValue, expirationSeconds) {
        let cookieString = cookieName + "=" + cookieValue + ";path=/";
        if (expirationSeconds) {
            let expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + (expirationSeconds * 1000));
            let expires = "expires=" + expirationDate.toUTCString();
            cookieString += ";" + expires;
        }
        document.cookie = cookieString;
    }
}

export default Common;