import Relatives from "./Relatives";

class Heirs {

    /**
     *  Heir categories of named heirs
     *  Include husband but not father since he also is an agnate
     */

    static namedHeirs = [Relatives.daughter, Relatives.gdaughter, Relatives.mother, Relatives.gmotherF, Relatives.gmotherM, Relatives.husband, Relatives.wife, Relatives.sister, Relatives.sisterF, Relatives.siblingM];

    /**
     *  Heir categories of agnate heirs
     */

    static agnateHeirs = [Relatives.son, Relatives.gson, Relatives.father, Relatives.gfather, Relatives.brother, Relatives.brotherF, Relatives.relativeM, Relatives.nephew, Relatives.nephewF, Relatives.uncle, Relatives.uncleF, Relatives.cousin, Relatives.cousinF, Relatives.servant];

    /**
     *  Tiers of heirs, as defined by the Jaafari juristic school
     */

    static tiersJ = [
        [Relatives.son, Relatives.daughter, Relatives.father, Relatives.mother, Relatives.gson, Relatives.gdaughter],
        [Relatives.brother, Relatives.sister, Relatives.gfather, Relatives.gmotherF, Relatives.gmotherM, Relatives.brotherF, Relatives.sisterF, Relatives.siblingM, Relatives.nephew, Relatives.nephewF],
        [Relatives.uncle, Relatives.uncleF, Relatives.relativeM, Relatives.cousin, Relatives.cousinF],
        [Relatives.servant]
    ];


    static baseKeysSingular = ['Bequest','Son','Daughter','Father','Mother','Husband','Wife','Brother','Sister','Siblingm','Grandson','Granddaughter','Grandfather','Grandmotherf','Uncle','Unclef','Brotherf','Sisterf','Relativem','Nephew','Nephewf','Grandmotherm','Cousin','Cousinf','Servant'];

    static baseKeysPlural = ['Bequest','Sons','Daughters','Father','Mother','Husband','Wife','Brothers','Sisters','Siblingsm','Grandson','Granddaughter','Grandfather','Grandmotherf','Uncles','Unclesf','Brothersf','Sistersf','Relativesm','Nephews','Nephewsf','Grandmotherm','Cousins','Cousinsf','Servants'];

    //Dual adds a '2' after the plural


}

export default Heirs;
