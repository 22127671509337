import React, { useState } from 'react';
import styles from './NumInput.module.scss';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const NumInput = props => {
    const [value, setValue] = useState(0);

    const incrementValue = () => {
        setValue(prevValue => prevValue + 1);
    };

    const decrementValue = () => {
        setValue(prevValue => (prevValue > 0 ? prevValue - 1 : 0));
    };

    const handleKeyUp = (e) => {
        if (e.key === 'ArrowUp') {
            incrementValue();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            decrementValue();
        }
    };

    const handleChange = (e) => {
        const newValue = e.target.value ? parseInt(e.target.value) : 0;
        setValue(newValue);
    };

    return (
        <div className={styles.NumInput}>
            <IconButton onClick={decrementValue}><RemoveIcon fontSize="small" /></IconButton>
            <input
                {...props}
                value={value}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
            />
            <IconButton onClick={incrementValue}><AddIcon fontSize="small" /></IconButton>
        </div>
    );
};

export default NumInput;
