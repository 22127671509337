import React from 'react';
import NumInput from "../NumInput/NumInput";
import OptionInput from "../OptionInput/OptionInput";

const Parents = () => {

    return (
        <tbody>
        <tr>
            <th>Heirs</th>
            <th>Number</th>
            <th>Share</th>
            <th>Total ($)</th>
        </tr>
        <tr>
            <td>Father</td>
            <td>
                <OptionInput name="num3" id="num3" tabIndex="3"/>
            </td>
            <td id="get3">&nbsp;</td>
            <td id="get3usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Mother</td>
            <td>
                <label>
                    <OptionInput name="num4" tabIndex="4"/>
                </label>
            </td>
            <td id="get4">&nbsp;</td>
            <td id="get4usd">&nbsp;</td>
        </tr>

        <tr>
            <td>Father of father</td>
            <td>
                <OptionInput name="num12" id="num12" tabIndex="12"/>
            </td>
            <td id="get12">&nbsp;</td>
            <td id="get12usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Mother of father
                <span title={"Most juristic schools distribute shares to grandmothers based on generational level; for example, if the heirs include a mother of mother and a mother of father of father, all schools distribute a share to the mother of mother only, while if the heirs include a mother of mother of mother and a mother of father, Malik and Shafii distribute shares to both, but Abu-Haneefa and Ibn-Hanbal distribute a share to the mother of father only."}>
                    <input name="gmfy" id="gmfy" type="checkbox" value="1"
                           onClick={(e) => {
                               e.target.form.elements['gmmy'].checked = false;
                               return true;
                           }}
                    />

                        <em>
                            Junior
                        </em>
                </span>
            </td>
            <td>
                <OptionInput name="num13" type="checkbox" id="num13" tabIndex="13"/>
            </td>
            <td id="get13">&nbsp;</td>
            <td id="get13usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Full brothers of the father</td>
            <td>
                <NumInput name="num14" size="2" tabIndex="14" id="num14"/>
            </td>
            <td id="get14">&nbsp;</td>
            <td id="get14usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Mother of mother
                <span  title={"Most juristic schools distribute shares to grandmothers based on generational level; for example, if the heirs include a mother of mother and a mother of father of father, all schools distribute a share to the mother of mother only, while if the heirs include a mother of mother of mother and a mother of father, Malik and Shafii distribute shares to both, but Abu-Haneefa and Ibn-Hanbal distribute a share to the mother of father only."}>
                <input name="gmmy" id="gmmy" type="checkbox" value="2" onClick={(e) => {
                    e.target.form.elements['gmfy'].checked = false;
                    return true;
                }}
                />

                        <em>Junior</em>
            </span>
            </td>
            <td>
                <OptionInput name="num21" type="checkbox" id="num21" tabIndex="21"/>
            </td>
            <td id="get21">&nbsp;</td>
            <td id="get21usd">&nbsp;</td>
        </tr>
        </tbody>
    );

};

export default Parents;
