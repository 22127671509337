import React from 'react';
import styles from "./Alert.module.scss";

const Alert = props => {

    return (
        <div className={styles.Alert + ( props.open ? ' ' + styles.Open : '')}
             onClick={() => {
                 props.handleOpen(false);
             }}
        >
            Calculation process is complete!
        </div>
    );

};

export default Alert;
