import React from 'react';
import IrthCalcLayout from "../Layout/IrthCalcLayout";
import {
    Avatar,
    Box,
    Button, Card, CardContent, CardHeader,
    Container, IconButton,
    Step,
    StepButton,
    Stepper
} from "@mui/material";
import CalculatorSubmit from "./RelativesForm/CalculatorSubmit";

import styles from "./MeiCalcForm.module.scss";
import FormContent from "./RelativesForm/FormContent";

import {red} from "@mui/material/colors";

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Preferences from "../../lib/inheritance/Calculator/Preferences";
import OptionsDialog from "../Settings/Options/OptionsDialog";
import Mazhab from "../../lib/inheritance/Calculator/Define/Mazhab";


const steps = [
    'Intro',
    'Children',
    'Parents',
    'Spouse',
    'Siblings',
    'Descendants',
    'Others',
    'Result'
];

const MeiCalcForm = () => {

    const [activeStep, setActiveStep] = React.useState(0);

    const totalSteps = () => {
        return steps.length;
    };


    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };


    const handleNext = () => {
        const newActiveStep = isLastStep() ? 0 : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {

    };


    // const handleReset = () => {
    //     setActiveStep(0);
    // };


    const [open, setOpen] = React.useState(false);

    const [selectedMazhab, setSelectedMazhab] = React.useState(Preferences.school);

    const handleOptionsClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedMazhab(value);
    };


    return (
        <IrthCalcLayout className={styles.InheritanceCalc}>


            <Container className={styles.CalcContainer}>

                <Card sx={{maxWidth: 1024, margin: 'auto'}}>
                    <CardHeader
                        avatar={
                            <Avatar sx={{bgcolor: red[500]}} aria-label="recipe">
                                {activeStep + 1}
                            </Avatar>
                        }
                        action={
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <h5>{Mazhab.schoolNames[Preferences.school]}</h5>
                                    </td>
                                    <td>
                                        <IconButton aria-label="settings" onClick={handleOptionsClickOpen}>
                                            <AccountBalanceIcon/>
                                        </IconButton>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        }
                        title={(<h1>{steps[activeStep]}</h1>)}
                        subheader=""
                    />

                    <CardContent>


                        <form id="MeicFrm" action="#">

                            <Box sx={{width: '100%'}}>
                                <Stepper nonLinear activeStep={activeStep}>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepButton color="inherit" onClick={handleStep(index)}>
                                                <span className={styles.StepperLabel}>{label}</span>
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            <div>
                                <React.Fragment>

                                    <section className={styles.ContentPages}>

                                        <FormContent
                                            activeStep={activeStep}
                                            handleOptionsClickOpen={handleOptionsClickOpen}
                                            onChangeIndex={(index) => {
                                                setActiveStep(index);
                                            }}
                                        />

                                    </section>


                                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{mr: 1}}
                                        >
                                            Back
                                        </Button>
                                        <Box sx={{flex: '1 1 auto'}}/>
                                        <Button onClick={handleNext} sx={{mr: 1}}>
                                            Next
                                        </Button>

                                        <Button onClick={handleComplete} style={{display: "none"}}>
                                            Calculate
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            </div>


                            <CalculatorSubmit setActiveStep={setActiveStep}/>


                        </form>

                    </CardContent>


                    <OptionsDialog
                        selectedValue={selectedMazhab}
                        open={open}
                        onClose={handleClose}
                    />

                </Card>

            </Container>

        </IrthCalcLayout>
    );

};

export default MeiCalcForm;
