import React from 'react';
import Preferences from "../../../lib/inheritance/Calculator/Preferences";

const Mazhab = props => {

    return (
        <table id="Prefs1">
            <tbody>
            <tr>
                <td>
                    <b title="Mazhab">Juristic school: &nbsp; </b>
                    <select
                        defaultValue={"1"}
                        name="SchoolSelector"
                        onChange={(event) => {
                            Preferences.school = parseInt(event.target.value);
                            props.handleMazhabSelected(Preferences.school);
                        }}>
                        <option value="1">Hanafi</option>
                        <option value="2">Maliki</option>
                        <option value="3">Shafii</option>
                        <option value="4">Hanbali</option>
                        <option value="6">Zahiri</option>
                        <option value="5">Egyptian Law</option>
                        <option value="7">Jaafari</option>
                        <option value="8">Ibadhi</option>
                        <option value="9" disabled>Zaidi</option>
                        <option value="0">No preference</option>
                    </select>
                    <p style={{fontSize: '12px'}}>
                        Juristic schools may have different opinions about how to divide up an estate. Select the
                        juristic school you follow or prefer to follow. If you do not follow any particular juristic
                        school, you may select 'No preference.' The default is the juristic school of Abu-Haneefa.
                    </p>
                </td>
            </tr>
            </tbody>
        </table>

    );

};

export default Mazhab;
