import React from 'react';
import {Dialog, DialogTitle} from "@mui/material";
import OptionsForm from "../OptionsForm";

const OptionsDialog = props => {

    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleMazhabSelected = (value) => {
        onClose(value);
    };


    return (
        <Dialog onClose={handleClose} open={true} style={{display: open ? '' : 'none'}}>
            <DialogTitle>Select Options</DialogTitle>

            <OptionsForm
                handleMazhabSelected={handleMazhabSelected}
            />

        </Dialog>
    );

};

export default OptionsDialog;
