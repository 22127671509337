import React from 'react';
import {Grid} from "@mui/material";
import Mazhab from "./Options/Mazhab";
import UndersubsriptionOpt from "./Options/UndersubsriptionOpt";
import OversubscriptionOpt from "./Options/OversubscriptionOpt";

const OptionsForm = props => {

    return (
        <form id="PreferencesForm" action="#" style={{
            padding: "2em",
            display: "block"
        }}>

            <Grid container spacing={5}>

                <Grid item xs={12} lg={12}>
                    <Mazhab handleMazhabSelected={props.handleMazhabSelected} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <UndersubsriptionOpt />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <OversubscriptionOpt />
                </Grid>

            </Grid>

        </form>
    );

};

export default OptionsForm;
