import React, {useState} from 'react';


const UndersubsriptionOpt = () => {

    const [selectedOption, setSelectedOption] = useState('1');

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
        <table id="Prefs2">
            <tbody>
            <tr>
                <td>
                    <b>Undersubscription preference:</b>
                    <p>
                        When the sum of distributed shares is <em>less</em> than the estate, how do you prefer to
                        distribute the remainder?
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="top">
                    <input type="radio"
                           name="Undersub"
                           value="1"
                           checked={selectedOption === '1'}
                           onChange={handleChange}
                    />
                    Divide it between the named heirs by the ratios to each other of their named shares (Consensus)
                </td>
            </tr>
            <tr>
                <td valign="top">
                    <input type="radio"
                           name="Undersub"
                           value="2"
                           checked={selectedOption === '2'}
                           onChange={handleChange}
                    />
                    Include spouses in the dividing (Uthman)
                </td>
            </tr>
            <tr>
                <td valign="top">
                    <input type="radio"
                           name="Undersub"
                           value="3"
                           checked={selectedOption === '3'}
                           onChange={handleChange}
                    />
                    Give it to the Islamic treasury (Maliki)
                </td>
            </tr>
            </tbody>
        </table>
    );

};

export default UndersubsriptionOpt;
