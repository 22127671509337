class Environment {

    // User's browser environment

    static _UserAgent = navigator.userAgent.toLowerCase();


    static isOpera = Environment._UserAgent.indexOf("opera") !== -1;

    static isIE = (navigator.appName.indexOf("Microsoft")) !== -1 && (Environment.isOpera===false);

    static isModern = !!document.getElementById;


    static _JavascriptVersion = 1.0;


}


if (typeof Array != "undefined") Environment._JavascriptVersion = 1.1;

if (typeof RegExp != "undefined") Environment._JavascriptVersion = 1.2;

if (typeof Infinity != "undefined") Environment._JavascriptVersion = 1.3;

if (typeof Error != "undefined") Environment._JavascriptVersion = 1.5;


export default Environment;