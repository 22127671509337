import React from 'react';
import NumInput from "../NumInput/NumInput";
import Fraction from "../../../lib/inheritance/Fraction";
import HeirsFractions from "../../../lib/inheritance/Calculator/Define/HeirsFractions";

const Others = props => {

    return (
        <tbody>
        <tr>
            <th>Heirs</th>
            <th>Number</th>
            <th>Share</th>
            <th>Total ($)</th>
        </tr>
        <tr>
            <td>Paternal half-brothers of father</td>
            <td>
                <NumInput name="num15" size="2" tabIndex="15" id="num15"/>
            </td>
            <td id="get15">&nbsp;</td>
            <td id="get15usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Sons of full brothers of the father</td>
            <td>
                <NumInput name="num22" size="2" tabIndex="22" id="num22"/>
            </td>
            <td id="get22">&nbsp;</td>
            <td id="get22usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Sons of paternal half-brothers of father</td>
            <td>
                <NumInput name="num23" size="2" tabIndex="23" id="num23"/>
            </td>
            <td id="get23">&nbsp;</td>
            <td id="get23usd">&nbsp;</td>
        </tr>
        <tr>
            <td><em>Other relatives</em></td>
            <td>
                <NumInput name="num18" size="2" tabIndex="18" id="num18"/>
            </td>
            <td id="get18">&nbsp;</td>
            <td id="get18usd">&nbsp;</td>
        </tr>

        <tr>
            <th colSpan={4}>Other Items</th>
        </tr>
        <tr style={{display: 'none'}}>
            <td>Servants</td>
            <td>
                <NumInput name="num24" size="2" tabIndex="24" id="num24"/>
            </td>
            <td id="get24">&nbsp;</td>
            <td id="get24usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Bequest (rational fraction &lt;= 1/3)</td>
            <td id="bequest1"></td>
            <td>
                <input type="text"
                       name="bequestField"
                       id="bequestField"
                       size="2"
                       minLength="3"
                       pattern="/\d+\/[2-9]\d*/"
                       onBlur={(e) => {
                           const bVal = Fraction.toRational(e.target.value);
                           if (bVal === null || Fraction.isGt(bVal, HeirsFractions.third)) e.target.value = '1/3';
                       }}
                />
            </td>
            <td id="bequest1usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Treasury</td>
            <td>&nbsp;</td>
            <td id="get25">&nbsp;</td>
            <td id="get25usd">&nbsp;</td>
        </tr>
        </tbody>
    );

};

export default Others;
