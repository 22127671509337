/* eslint-disable */
import Relatives from './Define/Relatives';
import Common from "./Common";
import Heirs from "./Define/Heirs";
import HeirsFractions from "./Define/HeirsFractions";
import {displays, explanations, rules} from "./Output/texts";
import Preferences from "./Preferences";
import Mazhab from "./Define/Mazhab";
import Fraction from "../Fraction";
import heirs from "./Define/Heirs";
import UI from "./Output/UI";

class CaseStatus {

    __lstHeirs__ = [];

    shares = [];	//IE doesn't understand new Array(Relatives.numcats)

    firstHeir = 0;

    lastHeir = 0;

    fasab = false;	//Flag if there's female this.agnates with other females

    bequestShare =  HeirsFractions.none;

    remain = HeirsFractions.whole;

    sum =  HeirsFractions.none;

    /**
     *	Initializes global variables
     */
    constructor() {

        this.__lstHeirs__ = [];

        this.shares = [];

        for (let i=0; i < Relatives.numcats; i++) {

            this.__lstHeirs__.push(0);

            this.shares.push( HeirsFractions.none);

        }

        this.fasab = false;

        this.remain = HeirsFractions.whole;

        this.sum =  HeirsFractions.none;

        this.bequestShare =  HeirsFractions.none;

        this.firstHeir = 0;

        this.lastHeir = 0;

    }

    isMaleDesc() {
        return (this.__lstHeirs__[Relatives.son]>0) || (this.__lstHeirs__[Relatives.gson]>0);
    }

    isFemaleDesc() {
        return (this.__lstHeirs__[Relatives.daughter]>0) || (this.__lstHeirs__[Relatives.gdaughter]>0);
    }

    isSpouse(h) {
        return (h === Relatives.husband) || (h === Relatives.wife);
    }

    /**
     *  Mazhab.Jaafari tier of an heir
     *  @param  h index of the heir
     *  @return int, index in #Heirs.tiersJ array of the tier that this.has heir h, or -1 if not found (e.g., spouses)
     */
    tierJof(h) {
        let t = -1;
        for (let n=0; n<4; n++)
            if (Common.inArray(Heirs.tiersJ[n], h)) {
                t = n;
                break;
            }
        return t;
    }

    /**
     *  Are there surviving heirs in a given tier?
     *  @param  n int, tier number, 0-3
     *  @return boolean: true if there are or if a spouse survived
     */
    isTierJ(n) {
        if (n<0 || n>3) return false;
        let ret = false;
        for (let h=0; h<Heirs.tiersJ[n].length; h++)
            if (this.__lstHeirs__[h]>0) {
                ret = true;
                break;
            }
        return ret || (this.__lstHeirs__[Relatives.husband]>0) || (this.__lstHeirs__[Relatives.wife]>0);
    }

    /**
     *  Are there survivors in a higher tier?
     *  @param  h int, index of the given heir
     *  @return boolean.
     */
     isHigherTierJ(h) {
        let t = this.tierJof(h);
        if (t===0) return false;
        else if (t<0) return true; //Spouses
        else return !!(this.isTierJ(t - 1) || this.isTierJ(t - 2) || this.isTierJ(t - 3));
    }

    /**
     *  Get the gender of an heir
     *  @param  h int, index of the heir in the heirs categories array
     *  @return "F" for female, or "" otherwise
     */
    getHeirGender(h) {
        h = parseInt(h);
        if (h===Relatives.daughter || h===Relatives.gdaughter || h===Relatives.mother || h===Relatives.wife || h===Relatives.gmotherF || h===Relatives.gmotherM || h===Relatives.sister || h===Relatives.sisterF) return "F";
        else return "";
    }



    /**
     * Retrieves the conjugated form of a word based on the number and gender of its related subject/object.
     *
     * @param word (string) The word to conjugate (e.g., "get", "each").
     * @param count (int) The number of the related subject/object (singular: 1, plural: any other value).
     * @param gender (string) The gender of the subject/object ("F" for feminine, empty string "" for others).
     * @return (string) The conjugated form of the word (e.g., "gets" for singular masculine, "get" for singular non-feminine),
     *                 or the original word if no matching key is found in the resource bundle.
     */
    reformatWord(word, count, gender) {
        let suffix = "";
        // Use a ternary operator for a more concise way to handle optional parameters with defaults
        const adjustedCount = count !== null ? count : 1;
        const adjustedGender = gender !== null ? gender : "";
        if (adjustedCount === 2) {
            suffix += "2";
        }
        // No else needed here, suffix will remain empty for singular or plural other than 2
        if (adjustedGender === "F") {
            suffix += "F";
        }
        console.log("reformatWord(" + word + "," + adjustedCount + "," + adjustedGender + ") = " + word + suffix + "\n");
        const conjugatedWord = word + suffix;
        // Check if the key exists using bracket notation (displays['_'+conjugatedWord+'_'])
        if (typeof displays["_" + conjugatedWord + "_"] === "undefined") {
            return word;
        }
        return conjugatedWord;
    }

    /**
     *	Lookup in resource bundle for a key generated by formatting a non-noun word in the local language
     *	@param	word string, the word to conjugate, e.g., "this.gets", or "each"
     *	@param	count int, the number of the word's related subject or object, e.g., 1, 2, 3...
     *	@param	gender string, either "F" or ""
     *	@return	string vaue associated with conjugated word key, e.g., value of "get2F", or "eachF", ...
     *					if key is not found in resource bundle, use the word param as is
     */
    formatWord(word, count, gender) {
        let conj = this.reformatWord(word, count, gender);
        let ret = displays["_"+conj+"_"];
        if (typeof ret === "undefined") ret = word;
        return ret;
    }

    /**
     *	reformatNoun a noun, e.g., " Relatives.brother"
     *	@param	noun string, the noun to conjugate
     *	@param	definitive boolean, true for definite, or false for indefinite
     *	@param	count int, number of the noun, e.g., 1,2,3...
     *	@param	declination string, "O" for objective, "G" for genitive, or "" for subjective
     *	@return	string the key of the conjugated noun, e.g., "Brothers2DO" for two brothers as definite objects
     *					if the key is not found in resource bundle, return the noun param
     */
    reformatNoun(noun, definitive, count, declination) {
        let suffix = "";
        let df = (null !== definitive) && definitive;
        let c = (null !== count)? count : 1;
        let dc = (null !== declination)? declination : "";
        console.log("Conjugating noun: " + noun + "(" + (df?'D':'')+c+(dc?declination:'') + ")...");
        if (2===c) suffix += "2";	//noun is either singular or plural
        if (df) suffix += "D";
        //Declination: 'O' object, 'G' genitive, '' subject
        if (dc) suffix += dc;
        let ret = noun + suffix;
        console.log("this.reformatNoun(" + noun + "," + df + "," + c + "," + dc + ") = " + noun+suffix + "\n");
        if (typeof displays["_"+ret+"_"] === "undefined") ret = noun;
        return ret;
    }

    /**
     *	reformatHeir an heir category, e.g.,  Relatives.brother
     *	@param	heir int, the index of the heir in the heirs categories array
     *	@param	definitive boolean
     *	@param	declination string, "O" for objective, "G" for genitive, or "" for subjective
     *	@return	string key for conjugated heir category, e.g., "Brother2DG"
     *	@see	#this.reformatNoun(string, boolean, int, string)
     */
    reformatHeir(heir, definitive, declination) {
        let ret;
        let df = (null !== definitive) && definitive;
        let dc = (null !== declination)? declination : "";
        console.log("Conjugating heir " + heir + "("+df+dc+")...");
        if (this.__lstHeirs__[heir]===1) ret = this.reformatNoun(Heirs.baseKeysSingular[heir], df, 1, dc);
        else ret = this.reformatNoun(Heirs.baseKeysPlural[heir], df, this.__lstHeirs__[heir], dc);
        return ret;
    }

    /**
     *	Format an heir category in the local language
     *	@param	h int, index of the heir in the heirs categories array
     *	@param	definitive boolean, whether to Fraction.add the definitive article
     *	@param	declination string, "O" for objective, "G" for genitive or "fr"  subjective
     *	@return	string value associated with the formatted heir category key
     */
    formatHeir(h, definitive, declination) {
        if (h===0) return displays["_Relatives.Relatives.bequest" + definitive? "D" : "_"];
        else if (h===Relatives.treasury) return displays["_Relatives.treasury_"];
        else return displays["_" + this.reformatHeir(h, definitive, declination) + "_"];
    }

    /**
     *	Localize display of a rational fraction
     *	@param	rational_fraction Rational, e.g. HeirsFractions.third or HeirsFractions.whole
     *	@return	string: printed local value of rational_fraction, e.g. "1/3" or "ALL"
     */
     toLangFraction(rational_fraction) {
        let frac = displays["_frac" + toString(rational_fraction) + "_"];
        if (typeof frac === "undefined")
            return Fraction.toString(rational_fraction);
        else return frac;
    }


    /**
     * Ensures the given condition is true. Throws an assertion error if it's not.
     *
     * @param condition (boolean) The condition to assert and potentially modify.
     * @param errmsg (string) The error message to throw if the condition needs modification.
     * @return (boolean) The modified or original value of the condition.
     */
    assertTrue(condition, errmsg) {
        if (condition===false) {
            alert(displays["_BUG_"] + ":\n" + errmsg + "\n" + displays["_reportit_"]);
            Common.debug("[ERROR] " + errmsg + ":\n" + this.caseToString());
        }
        return condition;
    }



    /**
     * Assigns a share to an heir.
     * @param heirIdx int, the index of the heir.
     * @param share Rational, the share to be assigned.
     * @param message string, optional information related to the share assignment.
     * @see Rational
     */
    gets(heirIdx, share, message) {
        if (this.__lstHeirs__[heirIdx] === 1 || heirIdx === Relatives.treasury || heirIdx === Relatives.bequest) {
            // Never use this.gets(Relatives.bequest). Use this.bequestShare instead.
            if (!Fraction.isZero(share)) {
                Common.detail(
                    this.formatHeir(heirIdx, true, '') + " " +
                    this.formatWord("gets", 1, this.getHeirGender(heirIdx)) + " " +
                    this.toLangFraction(share) +
                    (message ? message : "")
                );
            } else {
                Common.detail(
                    this.formatHeir(heirIdx, true, '') + " " +
                    this.formatWord("gets0", 1, this.getHeirGender(heirIdx)) + " " +
                    (message ? message : "")
                );
            }
            this.shares[heirIdx] = share;
        } else if (this.__lstHeirs__[heirIdx] > 1) {
            if (!Fraction.isZero(share)) {
                Common.detail(
                    this.formatHeir(heirIdx, true, '') + " " +
                    this.formatWord("get", this.__lstHeirs__[heirIdx], this.getHeirGender(heirIdx)) + " " +
                    this.toLangFraction(share) + " " +
                    (message ? message : "")
                );
            } else {
                Common.detail(
                    this.formatHeir(heirIdx, true, '') + " " +
                    this.formatWord("get0", this.__lstHeirs__[heirIdx], this.getHeirGender(heirIdx)) + " " +
                    (message ? message : "")
                );
            }
            this.shares[heirIdx] = share;
        } else {
            this.shares[heirIdx] = HeirsFractions.none;
        }
        Common.debug("Giving " + heirIdx + " (" + this.__lstHeirs__[heirIdx] + ") " + toString(share));
    }

    /**
     * Retrieves an heir's share.
     * @param heirIdx int, the index of the heir.
     * @return Rational, the heir's share.
     * @see Rational
     */
    has(heirIdx) {
        if (heirIdx === Relatives.bequest) {
            // Never use this.has(Relatives.bequest). Use this.bequestShare instead.
            Common.debug(this.formatHeir(heirIdx, false, '') + " " + displays["_has_"] + " " + Fraction.toString(this.bequestShare));
            return this.bequestShare;
        } else if (heirIdx === Relatives.treasury) {
            Common.debug(this.formatHeir(heirIdx, false, '') + " " + this.formatWord("has", 1, '') + " " + Fraction.toString(this.shares[Relatives.treasury]));
            return this.shares[Relatives.treasury];
        } else if (this.__lstHeirs__[heirIdx] === 1) {
            Common.debug(this.formatHeir(heirIdx, false, '') + " " + this.formatWord("has", 1, '') + " " + Fraction.toString(this.shares[heirIdx]));
            return this.shares[heirIdx];
        } else if (this.__lstHeirs__[heirIdx] > 1) {
            Common.debug(this.formatHeir(heirIdx, false, '') + " " + this.formatWord("have", this.__lstHeirs__[heirIdx], this.getHeirGender(heirIdx)) + " " + this.toLangFraction(this.shares[heirIdx]));
            return this.shares[heirIdx];
        } else {
            return HeirsFractions.none;
        }
    }

    /**
     * Asserts invariants, such as sons always inheriting, etc.
     * @return boolean, true if the invariants hold, false otherwise.
     */
    sanityCheck() {
        let isSane = true;
        if (this.__lstHeirs__[Relatives.son] > 0) {
            isSane = isSane && Common.ensure(!Fraction.isZero(this.has(Relatives.son)), displays["_sonsfail_"]);
        }
        if (this.__lstHeirs__[Relatives.daughter] > 0) {
            isSane = isSane && Common.ensure(!Fraction.isZero(this.has(Relatives.daughter)), displays["_daughtersfail_"]);
        }
        if (this.__lstHeirs__[Relatives.mother] > 0) {
            isSane = isSane && Common.ensure(!Fraction.isZero(this.has(Relatives.mother)), displays["_motherfail_"]);
        }
        if (this.__lstHeirs__[Relatives.father] > 0) {
            isSane = isSane && Common.ensure(!Fraction.isZero(this.has(Relatives.father)), displays["_fatherfail_"]);
        }
        if (this.__lstHeirs__[Relatives.husband] > 0) {
            isSane = isSane && Common.ensure(!Fraction.isZero(this.has(Relatives.husband)), displays["_husbandfail_"]);
        }
        if (this.__lstHeirs__[Relatives.wife] > 0) {
            isSane = isSane && Common.ensure(!Fraction.isZero(this.has(Relatives.wife)), displays["_wifefail_"]);
        }
        return isSane;
    }

    /**
     * Determines if an heir is set to share by agnation.
     * @param heirIdx int, the index of the heir.
     * @return boolean, true if the heir is set to share by agnation, false otherwise.
     */
    isToShare(heirIdx) {
        if (this.__lstHeirs__[heirIdx] > 0) {
            let share = this.has(heirIdx);
            let isSharing = (share.num < 0) && (share.denom >= 1);
            if (this.__lstHeirs__[heirIdx] === 1) {
                Common.debug(this.formatHeir(heirIdx, true, '') + (isSharing ? " is set to share" : " is not set to share"));
            } else {
                Common.debug(this.formatHeir(heirIdx, true, '') + (isSharing ? " are set to share" : " are not set to share"));
            }
            return isSharing;
        } else {
            return false;
        }
    }

    /**
     * Checks if an heir is listed but has no share.
     * @param heirIdx int, the index of the heir.
     * @return boolean, true if the heir is listed but has no share, false otherwise.
     */
    isVoid(heirIdx) {
        return (this.__lstHeirs__[heirIdx] === 0 || Fraction.isZero(this.has(heirIdx)));
    }

    /**
     * Deprives an heir of a share.
     * @param heirIdx int, the index of the heir.
     * @param reason string, the reason for depriving the heir.
     */
    deprive(heirIdx, reason) {
        if (this.__lstHeirs__[heirIdx] === 0) return;
        if (this.__lstHeirs__[heirIdx] === 1) {
            Common.detail(this.formatHeir(heirIdx, true, '') + " " + displays["_deprived_"] + " " + (reason ? reason : ""));
        } else {
            Common.detail(this.formatHeir(heirIdx, true, '') + " " + displays["_depriveds_"] + " " + (reason ? reason : ""));
        }
        this.gets(heirIdx, HeirsFractions.none);
    }

    /**
     * Deprives a range of heirs of their shares.
     * @param startIdx int, the starting index of the range.
     * @param endIdx int, the ending index of the range.
     * @param reason string, the reason for depriving the heirs.
     */
    depriveRange(startIdx, endIdx, reason) {
        if (reason) {
            for (let i = startIdx; i <= endIdx; i++) {
                this.deprive(i, reason);
            }
        } else {
            for (let i = startIdx; i <= endIdx; i++) {
                this.deprive(i);
            }
        }
    }
    /**
     * Deprives all heirs in lower tiers according to Mazhab.Jaafari juristic school.
     */
    depriveJ() {
        for (let i = Relatives.brother; i <= Relatives.servant; i++) { // Tiers 1-3
            if (this.isHigherTierJ(i)) {
                this.deprive(i, " (" + displays["_Jaafari_"] + ")");
            }
        }
    }

    /**
     * Determines if the case is a "kalala" situation, i.e., no male descendants nor male ascendants.
     * The Mazhab.Jaafari school does not count grandfathers in the kalala determination.
     * @return boolean, true if the case is a "kalala", false otherwise.
     */
    isKalala() {
        let total = this.__lstHeirs__[Relatives.son] + this.__lstHeirs__[Relatives.father] + this.__lstHeirs__[Relatives.gson];
        if (Preferences.school !== Mazhab.Jaafari) {
            total += this.__lstHeirs__[Relatives.gfather];
        }
        if (total === 0 && Preferences.school === Mazhab.Jaafari) {
            Common.detail(displays["_kalalaJgfOK_"]);
        } else if (total === 0) {
            Common.detail(displays["_iskalala_"]);
        }
        return (total === 0);
    }

    /**
     * Checks if the juristic school is Mazhab.Maliki, Mazhab.Shafii, or Mazhab.Hanbali.
     * @return boolean, true if the juristic school is Mazhab.Maliki, Mazhab.Shafii, or Mazhab.Hanbali, false otherwise.
     */
    isMSB() {
        return (Preferences.school === Mazhab.Maliki) || (Preferences.school === Mazhab.Shafii) || (Preferences.school === Mazhab.Hanbali);
    }

    /**
     * Checks if the case involves a grandfather and siblings.
     * @return boolean, true if the case involves a grandfather and siblings, false otherwise.
     */
    isGFB() {
        let numBrother = this.__lstHeirs__[Relatives.brother] - 0 + this.__lstHeirs__[Relatives.brotherF] - 0;
        Common.debug("this.isGFB: numBrother = " + numBrother);
        return (this.__lstHeirs__[Relatives.gfather] > 0) && (numBrother > 0);
    }

    /**
     * Checks if the case involves a grandfather and sisters.
     * @return boolean, true if the case involves a grandfather and sisters, false otherwise.
     */
    isGFS() {
        let numBrother = this.__lstHeirs__[Relatives.brother] - 0 + this.__lstHeirs__[Relatives.brotherF] - 0;
        let numSister = this.__lstHeirs__[Relatives.sister] - 0 + this.__lstHeirs__[Relatives.sisterF] - 0;
        Common.debug("this.isGFS: numBrother = " + numBrother + ", numSister = " + numSister);
        return (this.__lstHeirs__[Relatives.gfather] > 0) && (numBrother === 0) && (numSister > 0);
    }

    /**
     * Checks if the case matches the "Mushtarika" situation.
     * @return boolean, true if the case matches the "Mushtarika" situation, false otherwise.
     */
    isMushtarika() {
        return !this.isMaleDesc() && !this.isFemaleDesc() &&
            (this.__lstHeirs__[Relatives.siblingM] > 0) &&
            (Preferences.school === Mazhab.Maliki || Preferences.school === Mazhab.Shafii || Preferences.school === Mazhab.Zaidi) &&
            (this.__lstHeirs__[Relatives.husband] > 0) && (this.__lstHeirs__[Relatives.mother] > 0) &&
            this.isVoid(Relatives.gfather) &&
            !this.isVoid(Relatives.brother) && this.isVoid(Relatives.sister);
    }

    /**
     * Checks if there is "female agnation", i.e., a daughter, daughters of sons, and sisters (full or from father).
     * @return boolean, true if there is "female agnation", false otherwise.
     */
    isFemaleAgnation() {
        let fa = (this.__lstHeirs__[Relatives.daughter] === 1) && (this.__lstHeirs__[Relatives.gdaughter] > 0) && (this.__lstHeirs__[Relatives.sister] > 0);
        let faF = (this.__lstHeirs__[Relatives.daughter] === 1) && (this.__lstHeirs__[Relatives.gdaughter] > 0) && (this.__lstHeirs__[Relatives.sisterF] > 0);
        return fa || faF;
    }

    /**
     * Checks if a bequest has been specified.
     * @return boolean, true if a bequest has been specified, false otherwise.
     */
    isBequest() {
        return Fraction.isGt(this.bequestShare, HeirsFractions.none);
    }

    /**
     * Sums up all given shares (excluding shares that are set to be distributed) and sets the global variables this.sum and this.remain.
     * Calculation is based on the estate, which is Fraction.one less any bequest.
     * @see #this.sum
     * @see #this.remain
     */
    sumUp() {
        let sum = HeirsFractions.none;
        for (let i = this.firstHeir; i <= this.lastHeir; i++) {
            if ((this.__lstHeirs__[i] > 0) && !this.isToShare(i)) { // Add only unmarked shares
                sum = Fraction.add(sum, this.has(i));
            }
        }
        sum = Fraction.add(sum, this.shares[Relatives.treasury]);
        this.sum = sum;
        this.remain = Fraction.subtract(HeirsFractions.whole, this.sum);
    }

    /**
     * Logs the current sum of shares and the remainder on the Common.details document.
     * @param stage string, optional. Stage of the calculation so far.
     */
    logSumAndRemain(stage) {
        Common.detail((stage ? "[" + stage + "]" : "") + displays["_sumnow_"] + ": " + this.toLangFraction(this.sum) + displays["_comma_"] + " " + displays["_remainnow_"] + ": " + this.toLangFraction(this.remain));
    }

    /**
     * Checks if the sum of shares equals the entire estate.
     * @return boolean, true if the sum of share distributions equals the entire estate, false otherwise.
     * @see #sumUp()
     */
    addsUp() {
        this.sumUp();
        return Fraction.isZero(this.remain);
    }

    /**
     * Calculates the remainder of the estate not yet distributed.
     * @return Rational, the remainder.
     * @see #sumUp()
     */
    calculateRemainder() {
        Common.detail(displays["_calcremain_"]);
        this.sumUp();
        return this.remain;
    }

    /**
     * Checks if the share distribution exceeds the estate.
     * @return boolean, true if the share distribution exceeds the estate, false otherwise.
     * @see #sumUp()
     */
    isOversubscribed() {
        this.sumUp();
        return Fraction.isGt(this.sum, HeirsFractions.whole);
    }

    /**
     * Checks if the share distribution is less than the entire estate.
     * @return boolean, true if the share distribution is less than the entire estate, false otherwise.
     * @see #sumUp()
     */
    isUndersubscribed() {
        this.sumUp();
        return Fraction.isLt(this.sum, HeirsFractions.whole);
    }
    /**
     * Determines if an heir is eligible for reversion (Rudd).
     * @param {number} h - Heir index
     * @returns {boolean} True if h is eligible for reversion and reversion is allowed
     */
    isRevertTo(h) {
        if (Preferences.allowRudd === false) {
            return false;
        }

        let b = (h === Relatives.daughter) || (h === Relatives.gdaughter) || (h === Relatives.mother) || (h === Relatives.gmotherF) || (h === Relatives.gmotherM) ||
            (h === Relatives.sister) || (h === Relatives.sisterF) || (h === Relatives.siblingM);

        if (Preferences.allowRuddToSpouses === true) {
            Common.detail(displays["_rev2_"]);
            b = b || (h === Relatives.husband) || (h === Relatives.wife);
        }

        if (!this.isVoid(h) && (this.__lstHeirs__[h] === 1)) {
            Common.detail(
                this.formatHeir(h, true, '') +
                " " +
                (b ? this.formatWord("doesrudd", 1, this.getHeirGender(h)) : this.formatWord("doesnotrudd", 1, this.getHeirGender(h)))
            );
        } else if (!this.isVoid(h) && (this.__lstHeirs__[h] > 1)) {
            Common.detail(
                this.formatHeir(h, true, '') +
                " " +
                (b ? this.formatWord("dorudd", this.__lstHeirs__[h], this.getHeirGender(h)) : this.formatWord("donotrudd", this.__lstHeirs__[h], this.getHeirGender(h)))
            );
        }

        return b;
    }

    /**
     * Retrieves the list of heir categories entered by the user.
     * @returns {string} List of heir indexes entered by the user
     */
    getHeirList() {
        let heirList = "";
        for (let j = Relatives.son; j < Relatives.treasury; j++) {
            if (this.__lstHeirs__[j] > 0) {
                if (this.__lstHeirs__[j] <= 2) {
                    heirList += this.formatHeir(j, false, '') + displays["_semicolon_"] + " ";
                } else {
                    heirList += this.__lstHeirs__[j] + " " + this.formatHeir(j, false, '') + displays["_semicolon_"] + " ";
                }
            }
        }

        Common.debug("===> this.getHeirList: " + heirList);

        if (heirList.length < 2) {
            return displays["_Noheirs_"];
        } else {
            return heirList.slice(0, -2);
        }
    }

    /**
     * Determines the first and last heirs entered by the user. Sets the global variables this.firstHeir and this.lastHeir accordingly.
     */
    getHeirRange() {
        this.firstHeir = 0;
        for (let i = Relatives.son; i < Relatives.treasury; i++) {
            if (this.__lstHeirs__[i] > 0) {
                this.firstHeir = i;
                break;
            }
        }

        this.lastHeir = 0;
        for (let j = Relatives.son; j < Relatives.treasury; j++) {
            if (this.__lstHeirs__[j] > 0) {
                this.lastHeir = j;
            }
        }

        if ((this.firstHeir > 0) && (this.lastHeir < Relatives.treasury)) {
            Common.debug("First heir is " + this.formatHeir(this.firstHeir) + " and last heir is " + this.formatHeir(this.lastHeir));
        }
    }

    /**
     * Cites a specific rule in the Common.details window.
     * @param {string} ruleKey - Key to the rule in the resource bundle
     */
    citeRule(ruleKey) {
        Common.detail(rules[ruleKey]);
    }

    /**
     * Adds a specific explanation to the Common.details window.
     * @param {string} explanationKey - Key to the explanation in the resource bundle
     */
    explain(explanationKey) {
        Common.detail(explanations[explanationKey]);
    }

    /**
     * Calculates inheritance share for a named heir based on specified rules.
     * @param {number} heirIdx - Index of the heir for whom inheritance is calculated.
     *                     Must be within the range [1, Relatives.treasury].
     * @see this.shares[] - Global array storing calculated inheritance shares.
     * @version 2.0
     * @since September 2020
     */
    calculateHeirsSharesOfNamedHeirs(heirIdx) {
         Common.ensure(heirIdx>0 && heirIdx<=Relatives.treasury, heirIdx + displays["_invalidheir_"]);
        let n = this.__lstHeirs__[heirIdx];
        if (n===0) {
            this.shares[heirIdx] =  HeirsFractions.none;
            return;
        }
        if (n <= 2)
            Common.detail("<br/>" + displays["_calcnamed1_"] + " " + this.formatHeir(heirIdx, true, 'G'));
        else
            Common.detail("<br/>" + displays["_calcnamed1_"] + " " + this.formatHeir(heirIdx, true, 'G') + " (" + n + ")");
        let sh =  HeirsFractions.none;
        switch(heirIdx) {
            case  Relatives.daughter:
                this.citeRule("_Daughter_");
                if (this.__lstHeirs__[ Relatives.son]>0) {
                    Common.detail(displays["_daughters12_"]);
                    sh = Fraction.multiply(HeirsFractions.toshare(), HeirsFractions.half);
                     this.gets( Relatives.son,HeirsFractions.toshare());
                }
                else if (Preferences.school===0 && this.__lstHeirs__[ Relatives.gson]>0) {
                    Common.detail(displays["_gsons1_"]);
                    Common.detail(displays["_gsons21d_"]);
                    sh = Fraction.multiply(HeirsFractions.toshare(), HeirsFractions.half);
                     this.gets( Relatives.gson,HeirsFractions.toshare());
                }
                else {
                    Common.detail(displays["_daughters_"]);
                    if (n===1) sh = HeirsFractions.half;
                    else sh = HeirsFractions.twothirds;
                }
                 Common.ensure(sh && !Fraction.isZero(sh), displays["_xdaughters_"]);
                break;
            case  Relatives.gdaughter:
                this.citeRule("_Granddaughter_");
                if (this.__lstHeirs__[ Relatives.daughter]>0 && Preferences.school===Mazhab.Jaafari) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_nochptJ_"]);
                }
                else if (this.__lstHeirs__[ Relatives.son]>0 && this.__lstHeirs__[ Relatives.gson]===0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_gdaughters0_"])
                }
                else if ((this.__lstHeirs__[ Relatives.daughter]>1) && (this.__lstHeirs__[ Relatives.gson]===0)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_gdaughters0a_"]);
                }
                //else if (this.__lstHeirs__[ Relatives.gson]>0 && this.__lstHeirs__[ Relatives.daughter]===0) {
                else if (this.__lstHeirs__[ Relatives.gson]>0 && this.__lstHeirs__[ Relatives.daughter]<2 && this.__lstHeirs__[ Relatives.son]===0) {
                    Common.detail(displays["_gdaughters1d_"]);
                    Common.detail(displays["_gdaughters12gs_"]);
                    sh = Fraction.multiply(HeirsFractions.toshare(), HeirsFractions.half);
                     this.gets( Relatives.gson,HeirsFractions.toshare());
                }
                else if (this.__lstHeirs__[ Relatives.daughter]===1 && !this.isMaleDesc()) {
                    this.fasab = true;
                    Common.detail(displays["_fasaba_"]);
                    sh = HeirsFractions.sixth;
                }
                else if (this.__lstHeirs__[ Relatives.daughter]>1 && !this.isMaleDesc()) {
                    Common.detail(displays["_nofasaba_"]);
                    sh =  HeirsFractions.none;
                }
                else if (!this.isMaleDesc()) {
                    Common.detail(displays["_gdaughters_"]);
                    if (n===1) sh = HeirsFractions.half;
                    else sh = HeirsFractions.twothirds;
                }
                else {
                    Common.detail(displays["_gdaughters0b_"])
                    sh =  HeirsFractions.none;
                }
                break;
            case  Relatives.husband:
                this.citeRule("_Husband_");
                if (this.isMaleDesc()||this.isFemaleDesc()) {
                    sh = HeirsFractions.quarter;
                    Common.detail(displays["_husband4_"]);
                }
                else {
                    sh = HeirsFractions.half;
                    Common.detail(displays["_husband2_"]);
                }
                 Common.ensure(sh && !Fraction.isZero(sh), displays["_xhusband_"]);
                break;
            case  Relatives.wife:
                this.citeRule("_Wife_");
                if (this.isMaleDesc()||this.isFemaleDesc()) {
                    sh = HeirsFractions.eighth;
                    Common.detail(displays["_wife8_"]);
                }
                else {
                    sh = HeirsFractions.quarter;
                    Common.detail(displays["_wife4_"]);
                }
                 Common.ensure(sh && !Fraction.isZero(sh), displays["_xwife_"]);
                break;
            case  Relatives.mother:
                this.citeRule("_Mother_");
                 this.deprive(Relatives.gmotherF, displays["_mrdeprives_"]);
                 this.deprive(Relatives.gmotherM, displays["_mrdeprives_"]);
                let numsib = this.__lstHeirs__[ Relatives.brother]*1 + this.__lstHeirs__[ Relatives.sister]*1 + this.__lstHeirs__[Relatives.siblingM]*1 + this.__lstHeirs__[Relatives.brotherF]*1 + this.__lstHeirs__[Relatives.sisterF]*1;
                Common.detail(displays["_numsib_"] + " = " + numsib);
                if (this.isMaleDesc()||this.isFemaleDesc()) {	//Descendants
                    Common.detail(displays["_mother6_"]);
                    sh = HeirsFractions.sixth;
                    if (this.__lstHeirs__[ Relatives.father]>0) {
                         this.gets( Relatives.father,HeirsFractions.sixth);
                        Common.detail(displays["_father6_"]);
                    }
                }
                else if ((numsib)>1) {	//No descendants
                    if (Preferences.school===Mazhab.Zahiri && (numsib)<3) {
                        sh = HeirsFractions.third;
                        Common.detail(displays["_mother3Z_"]);
                    }
                    else {
                        sh = HeirsFractions.sixth;
                        Common.detail(displays["_mother3C_"]);
                    }
                    if (Preferences.school===0) {
                        if ((numsib)===1) {	//Whoever it is this.gets the reduced HeirsFractions.sixth
                            Common.detail(displays["_sibling6_"]);
                            if (this.__lstHeirs__[ Relatives.brother]===1)  this.gets( Relatives.brother,HeirsFractions.sixth);
                            else if (this.__lstHeirs__[Relatives.brotherF]===1)  this.gets(Relatives.brotherF,HeirsFractions.sixth);
                            else if (this.__lstHeirs__[ Relatives.sister]===1)  this.gets( Relatives.sister,HeirsFractions.sixth);
                            else if (this.__lstHeirs__[Relatives.sisterF]===1)  this.gets(Relatives.sisterF,HeirsFractions.sixth);
                            else if (this.__lstHeirs__[Relatives.siblingM]===1)  this.gets(Relatives.siblingM,HeirsFractions.sixth);
                        }
                        else {	//They share in the reduced HeirsFractions.sixth
                            Common.detail(displays["_siblings3_"]);
                            if (this.__lstHeirs__[ Relatives.brother]>0)
                                 this.gets( Relatives.brother, Fraction.multiply(HeirsFractions.sixth, new Fraction(this.__lstHeirs__[ Relatives.brother], numsib)));
                            if (this.__lstHeirs__[Relatives.brotherF]>0)
                                 this.gets(Relatives.brotherF, Fraction.multiply(HeirsFractions.sixth, new Fraction(this.__lstHeirs__[Relatives.brotherF], numsib)));
                            if (this.__lstHeirs__[ Relatives.sister]>0)
                                 this.gets( Relatives.sister, Fraction.multiply(HeirsFractions.sixth, new Fraction(this.__lstHeirs__[ Relatives.sister], numsib)));
                            if (this.__lstHeirs__[Relatives.sisterF]>0)
                                 this.gets(Relatives.sisterF, Fraction.multiply(HeirsFractions.sixth, new Fraction(this.__lstHeirs__[Relatives.sisterF], numsib)));
                            if (this.__lstHeirs__[Relatives.siblingM]>0)
                                 this.gets(Relatives.siblingM, Fraction.multiply(HeirsFractions.sixth, new Fraction(this.__lstHeirs__[Relatives.siblingM], numsib)));
                        }
                        Common.detail(displays["_siblings6_"]);
                    }
                }
                else if (this.__lstHeirs__[ Relatives.father]>0) {	//No siblings
                    if (Preferences.school===Mazhab.Zahiri) {
                        sh = HeirsFractions.third;
                         this.gets( Relatives.father, HeirsFractions.toshare());
                        Common.detail(displays["_mother3z_"]);
                    }
                    else if (this.__lstHeirs__[ Relatives.husband]+this.__lstHeirs__[ Relatives.wife]>0) {
                        //Al-Gharraia, or the Two Omarias
                        sh = Fraction.multiply(this.remain, HeirsFractions.third);
                         this.gets( Relatives.father, Fraction.multiply(this.remain, HeirsFractions.twothirds));
                        Common.detail(displays["_mother3c_"]);
                    }
                    else {
                        sh = HeirsFractions.third;
                         this.gets( Relatives.father, HeirsFractions.toshare());
                    }
                }
                else {
                    sh = HeirsFractions.third;
                    Common.detail(displays["_mother3_"]);
                }
                 Common.ensure(sh && !Fraction.isZero(sh), displays["_xmother_"]);
                break;
            case Relatives.gmotherF:
                this.citeRule("_Grandmother_");
                if (this.__lstHeirs__[ Relatives.mother]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_gmother0_"]);
                }
                else if (Preferences.school === Mazhab.Jaafari) {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_gmotherJ_"]);
                }
                else if (this.__lstHeirs__[Relatives.gmotherM]>0) {
                    let g = UI.getGrandMaGeneration();
                    if (2===g && this.__lstHeirs__[ Relatives.mother]===0 && Preferences.school!==0) {
                        sh =  HeirsFractions.none;
                        Common.detail(displays["_gmotherF0My_"]);
                    }
                    else if (1===g && Preferences.school!==0) {
                        sh = HeirsFractions.sixth;
                        Common.detail(displays["_gmotherF6y_"]);
                    }
                    else {
                        sh = new Fraction(1,12);
                        Common.detail(displays["_gmother6_"]);
                    }
                }
                else if (Preferences.school===Mazhab.Zahiri) {
                    //Gets what the  Relatives.mother this.gets: 1/3 or 1/6.
                    if ((numsib)>2) sh = HeirsFractions.sixth;
                    else sh = HeirsFractions.third;
                    Common.detail(displays["_gmother36Z_"]);
                }
                else {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_gmotherF6M0_"]);
                }
                break;
            case Relatives.gmotherM:
                this.citeRule("_Grandmother_");
                if (this.__lstHeirs__[ Relatives.mother]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_gmother0_"]);
                }
                else if (Preferences.school === Mazhab.Jaafari) {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_gmotherJ_"]);
                }
                else if (this.__lstHeirs__[Relatives.gmotherF]>0) {
                    let g = UI.getGrandMaGeneration();
                    if (1===g && (Preferences.school===Mazhab.Hanafi || Preferences.school===Mazhab.Hanbali)) {
                        sh =  HeirsFractions.none;
                        Common.detail(displays["_gmotherM0Fy_"]);
                    }
                    else if (2===g) {
                        sh = HeirsFractions.sixth;
                        Common.detail(displays["_gmotherM6y_"]);
                    }
                    else {
                        sh = new Fraction(1,12);
                        Common.detail(displays["_gmother6_"]);
                    }
                }
                else {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_gmotherM6F0_"]);
                }
                break;
            case  Relatives.sister:
                this.citeRule("_FullSister_");
                if (this.__lstHeirs__[ Relatives.father]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_sisters0_"]);
                }
                else if (Preferences.school === Mazhab.Jaafari &&  this.isHigherTierJ( Relatives.sister)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_higherTierJ_"]);
                }
                else if (this.__lstHeirs__[ Relatives.gfather]>0 && (Preferences.school===Mazhab.Hanafi || Preferences.school===Mazhab.Zahiri || Preferences.school===Mazhab.Ibadhi)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_sisters0HZI_"]);
                }
                else if (this.isGFS() && (Preferences.school===Mazhab.Egypt || this.isMSB())) {
                    sh = Fraction.multiply(HeirsFractions.toshare(), HeirsFractions.half);	//this.agnates in Zaid's and Ali's opinions
                    Common.detail(displays["_sistersMSB_"]);
                }
                else if (Preferences.school===0 && this.isKalala() && !this.isFemaleDesc() && (this.__lstHeirs__[ Relatives.husband]+this.__lstHeirs__[ Relatives.wife])>0) {	//4:12
                    Common.detail(displays["_kalala1_"]);
                    this.citeRule("_Kalala_");
                    let numsis = n-0 + this.__lstHeirs__[Relatives.sisterF];
                    let numbro = this.__lstHeirs__[ Relatives.brother]-0 + this.__lstHeirs__[Relatives.brotherF]-0;
                    let numsib = numbro + numsis-0 + this.__lstHeirs__[Relatives.siblingM]-0;
                    if (n===1 && numsib===1) sh = HeirsFractions.sixth;	//One  Relatives.sister and no other siblings
                    else {	//Sisters and a number of other siblings
                        let multis = new Fraction(n, numsib);
                        sh = Fraction.multiply(multis, HeirsFractions.third);
                        let multib = new Fraction(this.__lstHeirs__[ Relatives.brother], numsib);	//equal ratio to  Relatives.sister
                         this.gets( Relatives.brother, Fraction.multiply(multib, HeirsFractions.third));
                        let multibf = new Fraction(this.__lstHeirs__[Relatives.brotherF], numsib);	//equal ratio to  Relatives.sister
                         this.gets(Relatives.brotherF, Fraction.multiply(multibf, HeirsFractions.third));
                        let multism = new Fraction(this.__lstHeirs__[Relatives.siblingM], numsib);	//equal ratio to  Relatives.sister
                         this.gets( Relatives.brother, Fraction.multiply(multism, HeirsFractions.third));
                    }
                }
                else if (this.__lstHeirs__[ Relatives.brother]>0) {
                    sh = Fraction.multiply(HeirsFractions.toshare(), HeirsFractions.half);
                     this.gets( Relatives.brother, HeirsFractions.toshare());
                    Common.detail(displays["_sisters12_"]);
                }
                else if (this.isFemaleDesc()) {
                    sh = HeirsFractions.toshare();
                    this.fasab = true;
                    Common.detail(displays["_fasabasis_"]);
                }
                else if (n===1) {
                    sh = HeirsFractions.half;
                    Common.detail(displays["_sisters2_"]);
                }
                else {
                    sh = HeirsFractions.twothirds;
                    Common.detail(displays["_sisters23_"]);
                }
                break;
            case Relatives.sisterF:
                this.citeRule("_HalfSister_");
                if (this.__lstHeirs__[ Relatives.father]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_sistersF0_"]);
                }
                else if (Preferences.school === Mazhab.Jaafari &&  this.isHigherTierJ(Relatives.sisterF)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_higherTierJ_"]);
                }
                else if (this.__lstHeirs__[ Relatives.gfather]>0 && (Preferences.school===Mazhab.Hanafi || Preferences.school===Mazhab.Zahiri || Preferences.school===Mazhab.Ibadhi)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_sistersFHZI_"]);
                }
                else if (Preferences.school===0 && this.isKalala() && !this.isFemaleDesc() && (this.__lstHeirs__[ Relatives.husband]+this.__lstHeirs__[ Relatives.wife])>0) {
                    Common.detail(displays["_kalala1_"]);
                    this.citeRule("_Kalala_");
                    let numsib = this.__lstHeirs__[ Relatives.brother]+this.__lstHeirs__[ Relatives.sister]+this.__lstHeirs__[Relatives.brotherF]+n+this.__lstHeirs__[Relatives.siblingM];
                    if (n===1 && numsib===1) sh = HeirsFractions.sixth;	//One  Relatives.sister from  Relatives.father and no other siblings
                    //Handled at  Relatives.sister above
                    else sh = this.has(Relatives.sisterF);
                }
                else if (this.isMaleDesc() || this.__lstHeirs__[ Relatives.brother]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_sistersF0m_"]);
                }
                else if (this.fasab) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_fasabasisF_"]);
                }
                else if ((this.__lstHeirs__[Relatives.brotherF]===0) && (this.__lstHeirs__[ Relatives.sister]>1) && !this.isFemaleDesc()) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_sistersF0nofasaba1_"]);
                }
                else if ((this.__lstHeirs__[ Relatives.sister]===1) && this.isFemaleDesc()) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_sistersF0nofasaba2_"]);
                }
                //Counting her in agnation with  Relatives.gfather in Zaid's doctrine comes later
                if (this.isGFS() && (Preferences.school===Mazhab.Egypt && this.isMSB())) {
                    sh = Fraction.multiply(HeirsFractions.toshare(),HeirsFractions.half);	//this.agnates in Zaid's and Ali's opinions
                    Common.detail(displays["_sistersFMSB_"]);
                }
                else if (this.__lstHeirs__[Relatives.brotherF]>0) {
                    sh = Fraction.multiply(HeirsFractions.toshare(), HeirsFractions.half);
                     this.gets(Relatives.brotherF, HeirsFractions.toshare());
                    Common.detail(displays["_sistersF12_"]);
                }
                else if (this.isFemaleDesc() && this.__lstHeirs__[ Relatives.sister]===1) {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_sistersFfasaba_"]);
                }
                else if (this.isFemaleDesc()) {
                    sh = HeirsFractions.toshare();
                    this.fasab = true;
                    Common.detail(displays["_sistersFshare_"]);
                }
                else if (n===1) {
                    sh = HeirsFractions.half;
                    Common.detail(displays["_sistersF2_"]);
                }
                else {
                    sh = HeirsFractions.twothirds;
                    Common.detail(displays["_sistersF23_"]);
                }
                break;
            case Relatives.siblingM:
                this.citeRule("_SiblingM_");
                Common.debug(this.__lstHeirs__[ Relatives.brother]===0? "No full brothers" : this.__lstHeirs__[ Relatives.brother]+" full  Relatives.brother(s)");
                if (Preferences.school === Mazhab.Jaafari &&  this.isHigherTierJ(Relatives.siblingM)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_higherTierJ_"]);
                }
                else if (this.__lstHeirs__[ Relatives.gfather]>0 && (this.isMSB())) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_siblingsM0MSB1_"]);
                }
                else if ((this.__lstHeirs__[ Relatives.daughter]>0 || this.__lstHeirs__[ Relatives.gdaughter]>0) && (Preferences.school===Mazhab.Hanafi || this.isMSB())) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_siblingsM0MSB2_"]);
                }
                else if (Preferences.school===0 && this.isKalala() && !this.isFemaleDesc() && (this.__lstHeirs__[ Relatives.husband]+this.__lstHeirs__[ Relatives.wife])>0) {
                    Common.detail(displays["_kalala1_"]);
                    this.citeRule("_Kalala_");
                    let numsib = this.__lstHeirs__[ Relatives.brother]+this.__lstHeirs__[ Relatives.sister]+this.__lstHeirs__[Relatives.brotherF]+this.__lstHeirs__[Relatives.sisterF]+n;
                    if (n===1 && numsib===1) sh = HeirsFractions.sixth;	//One sibling from  Relatives.mother and no other siblings
                    //Handled at  Relatives.sister above
                    else sh = this.has(Relatives.siblingM);
                }
                else if (n===1) {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_Relatives.siblingM6_"]);
                }
                else if (this.isMushtarika()) {
                    sh = HeirsFractions.toshare();
                    Common.detail(displays["_mushtarika_"]);
                }
                else {
                    sh = HeirsFractions.third;
                    Common.detail(displays["_siblingsM3_"]);
                }
                break;
            default:
                Common.debug("this.calculateHeirsSharesOfNamedHeirs() called with an unnamed heir, " + this.formatHeir(heirIdx));
                sh =  HeirsFractions.none;
        }
         this.gets(heirIdx, sh);
        Common.detail(displays["_fincalcnamed1_"] + " " + this.formatHeir(heirIdx, true, 'G') );
        Common.detail(displays["_sumsofar_"]);
        this.sumUp();
        this.logSumAndRemain("ns"+heirIdx);
    }

    /**
     * Calculates inheritance shares for the this.agnate heirs
     * @param {number} heirIdx Index of the heir for whom to calculate inheritance
     * @return {void} (no return value, modifies this.shares)
     * @see @link this.shares
     * @version 2.0
     * @since September 2020
     */
    calculateHeirsSharesOfAgnatedHeirs(heirIdx) {
         Common.ensure(heirIdx>0 && heirIdx<=Relatives.treasury, heirIdx + displays["_invalidheir_"]);
        let n = this.__lstHeirs__[heirIdx];
        if (n===0) {
            this.shares[heirIdx] =  HeirsFractions.none;
            return;
        }
        if (n<=2) Common.detail("<br/>" + displays["_calcagnate1_"] + " " + this.formatHeir(heirIdx, true, 'G'));
        else Common.detail("<br/>" + displays["_calcagnate1_"] + " " + this.formatHeir(heirIdx, true, 'G') + " (" + n + ")");
        let sh =  HeirsFractions.none;
        switch(heirIdx) {
            case  Relatives.son:
                this.citeRule("_Son_");
                sh = HeirsFractions.toshare();
                Common.detail(displays["_sons_"]);
                this.depriveRange( Relatives.brother, Relatives.treasury, displays["_sondeprives_"]);
                 this.deprive( Relatives.gdaughter, displays["_sondeprives_"]);
                 Common.ensure(sh && !Fraction.isZero(sh), displays["_xsons_"]);
                break;
            case  Relatives.gson:
                this.citeRule("_Grandson_");
                this.depriveRange( Relatives.sister, Relatives.siblingM, displays["_gsondeprives_"]);
                if (this.__lstHeirs__[ Relatives.son]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_gsons0_"]);
                }
                else sh = HeirsFractions.toshare();
                Common.detail(displays["_gsons_"]);
                break;
            case  Relatives.father:
                this.citeRule("_Father_");
                this.depriveRange( Relatives.brother, Relatives.sisterF, displays["_fatherdeprives_"]);
                if (Preferences.school !== 0)
                     this.deprive(Relatives.gmotherF, displays["_frdeprivesgmf_"]);
                if (this.isMaleDesc()) {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_father6_"]);
                }
                else if (this.isFemaleDesc()) {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_father6a_"]);
                }
                else if (((this.__lstHeirs__[ Relatives.husband]*1+this.__lstHeirs__[ Relatives.wife]*1)>0) && this.__lstHeirs__[ Relatives.mother]>0) {
                    //Al-Gharraia, or the Two Omarias
                    //Do nothing. It was taken care of at  Relatives.mother above
                    sh = this.has( Relatives.father);
                }
                else {
                    sh = HeirsFractions.toshare();
                    Common.detail(displays["_father_"]);
                }
                 Common.ensure(sh && !Fraction.isZero(sh), displays["_xfather_"]);
                break;
            case  Relatives.gfather:
                this.citeRule("_Grandfather_");
                if (Preferences.school===Mazhab.Hanafi) {
                    this.depriveRange( Relatives.brother,  Relatives.sister, displays["_gfdeprives_"]);
                    this.depriveRange(Relatives.brotherF, Relatives.sisterF, displays["_gfdeprives_"]);
                }
                /*else if (Preferences.school !== 0)
                     this.deprive(Relatives.sisterF, displays["_gfdeprives_"]);*/
                if (this.__lstHeirs__[ Relatives.father]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_gfather0_"]);
                }
                else if (Preferences.school === Mazhab.Jaafari &&  this.isHigherTierJ( Relatives.gfather)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_higherTierJ_"]);
                }
                else if (this.isMaleDesc()) {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_gfather6_"]);
                }
                else if (this.isFemaleDesc()) {
                    sh = HeirsFractions.sixth;
                    Common.detail(displays["_gfather6a_"]);
                }
                else if (this.isGFS()) {
                    if ((this.__lstHeirs__[ Relatives.mother]*1+this.__lstHeirs__[Relatives.gmotherF]*1+this.__lstHeirs__[Relatives.gmotherM]*1)>0 && (this.__lstHeirs__[ Relatives.husband]*1+this.__lstHeirs__[ Relatives.wife]*1)>0) {
                        sh = HeirsFractions.sixth;
                        Common.detail(displays["_akdaria_"]);
                        //To be followed, for the later schools, by redivision then agnation
                    }
                    else if (Preferences.school === Mazhab.Jaafari &&  this.isHigherTierJ( Relatives.gfather)) {
                        sh =  HeirsFractions.none;
                        Common.detail(displays["_higherTierJ_"]);
                    }
                    //That test is only repeated for good programming discipline :-)
                    else if (Preferences.school !== Mazhab.Jaafari) {
                        sh = HeirsFractions.toshare();
                        Common.detail(displays["_gfather_"]);
                    }
                    else sh = HeirsFractions.sixth;
                }
                else if (Preferences.school !== Mazhab.Jaafari) {
                    sh = HeirsFractions.toshare();
                    Common.detail(displays["_gfather_"]);
                }
                else sh = HeirsFractions.sixth;	//Default, e.g., Mazhab.Jaafari
                break;
            case  Relatives.brother:
                this.citeRule("_FullBrother_");
                if (this.isMaleDesc() || this.__lstHeirs__[ Relatives.father]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_brothers0_"]);
                }
                else if ((Preferences.school===Mazhab.Hanafi || Preferences.school===Mazhab.Zahiri) && this.__lstHeirs__[ Relatives.gfather]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_brothers0HZ_"]);
                }
                else if (Preferences.school === Mazhab.Jaafari &&  this.isHigherTierJ( Relatives.brother)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_higherTierJ_"]);
                }
                else if (Preferences.school===0 && this.isKalala() && !this.isFemaleDesc() && (this.__lstHeirs__[ Relatives.husband]+this.__lstHeirs__[ Relatives.wife])>0) {
                    //Verse 4:12
                    Common.detail(displays["_kalala1_"]);
                    this.citeRule("_Kalala_");
                    if (n+this.__lstHeirs__[ Relatives.sister]+this.__lstHeirs__[Relatives.brotherF]+this.__lstHeirs__[Relatives.sisterF]+this.__lstHeirs__[Relatives.siblingM]===1)
                        sh = HeirsFractions.sixth;
                    //else handled at  Relatives.sister above
                    else sh = this.has( Relatives.brother);
                }
                else {
                    sh = HeirsFractions.toshare();
                    Common.detail(displays["_brothers_"]);
                }
                break;
            case Relatives.brotherF:
                this.citeRule("_HalfBrother_");
                if (this.isMaleDesc() || this.__lstHeirs__[ Relatives.father]>0) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_brothersF0_"]);
                }
                else if (Preferences.school === Mazhab.Jaafari &&  this.isHigherTierJ( Relatives.gfather)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_higherTierJ_"]);
                }
                else if (Preferences.school===0 && this.isKalala() && !this.isFemaleDesc() && (this.__lstHeirs__[ Relatives.husband]+this.__lstHeirs__[ Relatives.wife])>0) {
                    //Verse 4:12
                    Common.detail(displays["_kalala1_"]);
                    this.citeRule("_Kalala_");
                    // noinspection JSUnusedLocalSymbols
                    let numsib = this.__lstHeirs__[ Relatives.brother]+n+this.__lstHeirs__[ Relatives.sister]+n+heirs[Relatives.sisterF]+this.__lstHeirs__[Relatives.siblingM];
                    if (n+this.__lstHeirs__[ Relatives.brother]+n+this.__lstHeirs__[ Relatives.sister]+n+heirs[Relatives.sisterF]+this.__lstHeirs__[Relatives.siblingM]===1)
                        sh = HeirsFractions.sixth;	//One  Relatives.brother from  Relatives.father and no other siblings
                    //else handled at Relatives.sisterF above
                    else sh = this.has(Relatives.brotherF);
                }
                else if (this.__lstHeirs__[ Relatives.sister]>0 && this.fasab) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_brothersF0a_"]);
                }
                else sh = HeirsFractions.toshare();
                Common.detail(displays["_brothersF_"]);
                break;
            default:	//e.g., Relatives.uncle, etc.
                Common.debug("this.calculateHeirsSharesOfAgnatedHeirs() called with " + this.formatHeir(heirIdx, false, 'G'));
                if (heirIdx === Relatives.relativeM) {
                    this.citeRule("_relativeM_");
                    if (Preferences.school===Mazhab.Maliki || Preferences.school===Mazhab.Shafii || Preferences.school===Mazhab.Zahiri) {
                        sh =  HeirsFractions.none;
                        Common.detail(displays["_relativesM0MSZ_"]);
                    }
                }
                this.citeRule("_Agnates_");
                if (Preferences.school === Mazhab.Jaafari &&  this.isHigherTierJ(heirIdx)) {
                    sh =  HeirsFractions.none;
                    Common.detail(displays["_higherTierJ_"]);
                }
                else if (Preferences.school!==0 && this.fasab && (this.__lstHeirs__[ Relatives.sister]>0 || this.__lstHeirs__[Relatives.sisterF]>0)) {
                    sh =  HeirsFractions.none;
                    Common.detail(this.formatHeir(heirIdx, true, '') + " " + displays["_agnates0a_"]);
                }
                else sh = HeirsFractions.toshare();	//Asaba
                for (let i=heirIdx-1; i>= Relatives.son; i--) {		//Closer Asaba?
                    if (this.isMaleAgnate(i) && this.__lstHeirs__[i]>0) {
                        sh =  HeirsFractions.none;
                        Common.detail(this.formatHeir(i, true, '') + " " + displays["_agnates0_"]);
                        break;
                    }
                }
        }
         this.gets(heirIdx, sh);
        Common.detail(displays["_fincalcagnate1_"] + " " + this.formatHeir(heirIdx, true, 'G') );
        Common.detail(displays["_sumsofar_"]);
        this.sumUp();
        this.logSumAndRemain("as"+heirIdx);
    }

    /**
     *	"Awl" (oversubscription), when this.sum of this.shares > 1
     */
    redivide() {
        if (! Common.ensure(this.isOversubscribed(), displays["_wrongrediv_"] + " " +  this.toLangFraction(this.sum))) return;
        if (Preferences.allowAwl===false) {
            Common.detail(displays["_noawl_"]);
            let excess = Fraction.subtract(this.sum, HeirsFractions.whole);
            Common.detail(displays["_excess_"] + " " + toString(excess));
            if (!Fraction.isLt(this.has(Relatives.sisterF), excess)) {	//can she have less than the excess?
                 this.gets(Relatives.sisterF, Fraction.subtract(this.has(Relatives.sisterF), excess));
                Common.detail(displays["_sistersF-ZJ_"]);
            }
            else if (!Fraction.isLt(this.has( Relatives.sister), excess)) {
                 this.gets( Relatives.sister, Fraction.subtract(this.has( Relatives.sister), excess));
                Common.detail(displays["_sisters-ZJ_"]);
            }
            else if (!Fraction.isLt(this.has( Relatives.gdaughter), excess)) {
                 this.gets( Relatives.gdaughter, Fraction.subtract(this.has( Relatives.gdaughter), excess));
                Common.detail(displays["_gdaughters-ZJ_"]);
            }
            else {
                 this.gets( Relatives.daughter, Fraction.subtract(this.has( Relatives.daughter), excess));
                Common.detail(displays["_daughters-ZJ_"]);
            }
        }
        else {
            Common.detail(displays["_rediv_"]);
            if (Fraction.isNegative(this.has( Relatives.father))) {
                 this.gets( Relatives.father, HeirsFractions.third, displays["_father3awl_"]);
                Common.detail(displays["_fathersum_"]);
                this.sumUp();
            } //Can that happen?
            if (Fraction.isNegative(this.has( Relatives.gfather))) {
                if (Preferences.school===Mazhab.Egypt) {
                     this.gets( Relatives.gfather, HeirsFractions.sixth, displays["_gfather6awl_"]);
                }
                else {
                     this.gets( Relatives.gfather, HeirsFractions.third, displays["_gfather3awl_"]);
                }
                Common.detail(displays["_gfathersum_"]);
                this.sumUp();
            }
            //The above can happen, e.g.,  Relatives.husband,  Relatives.mother,  Relatives.gfather, 2 sisters
            for (let i=this.firstHeir; i<=this.lastHeir; i++) {
                if ((!this.isVoid(i)) && Fraction.isGt(this.has(i), HeirsFractions.none)) {
                     this.gets(i, Fraction.divide(this.has(i), this.sum));
                }
            }
        }
        if (! Common.ensure(this.addsUp())) Common.detail(displays["_redivfail_"]);
        else Common.detail(displays["_redivok_"]);
        this.logSumAndRemain("rediv");
    }


    /**
     *	Agnation
     **/
    /**
     *	Whether an heir is a male this.agnate
     *	@param	hIdx int, index of the heir
     *	@return	boolean, true if h is a male this.agnate, false otherwise
     */
    isMaleAgnate(hIdx) {
        return (hIdx===Relatives.son) || (hIdx===Relatives.gson) || (hIdx===Relatives.father) || (hIdx===Relatives.gfather) || (hIdx===Relatives.brother) || (hIdx===Relatives.brotherF) ||
            (hIdx===Relatives.nephew) || (hIdx===Relatives.nephewF) || (hIdx===Relatives.uncle) || (hIdx===Relatives.uncleF) || (hIdx===Relatives.cousin) || (hIdx===Relatives.cousinF);
    }

    /**
     *	Whether an heir is a female this.agnate
     *	@param	hIdx int, index of the heir
     *	@return	boolean, true if h is a female this.agnate, false otherwise
     */
    isFemaleAgnate(hIdx) {
        return (hIdx===Relatives.daughter) || (hIdx===Relatives.gdaughter) || (hIdx===Relatives.sister) || (hIdx===Relatives.sisterF);
    }

    /**
     *	Whether the heir list supports female agnation
     *	@return	boolean: true if the heir list this.has a female agnation combination, false otherwise
     */
    hasFemaleAgnates() {
        return (
            (this.__lstHeirs__[Relatives.daughter]>0 && ((!this.isVoid(Relatives.sister)) || (!this.isVoid(Relatives.sisterF))) ) ||
            (this.__lstHeirs__[Relatives.gdaughter]>0 && ((!this.isVoid(Relatives.sister)) || (!this.isVoid(Relatives.sisterF))) )
        );
    }

    /**
     *	Whether an heir is eligible for agnation
     *	@param	hIdx int, index of the heir
     *	@return	boolean: true if h is eligible for agnation, false otherwise
     */
    agnates(hIdx) {
        let b = (this.__lstHeirs__[hIdx]>0) && (this.isMaleAgnate(hIdx) || this.isFemaleAgnate(hIdx));
        return (b && !this.isVoid(hIdx) && Fraction.isNegative(this.has(hIdx)));
    }

    /**
     *	Get the weight in agnation of an heir
     *	@param	hIdx int, index of the heir
     *	@return	int: multiplier of the agnation ratio of h, e.g., 2 if their ratio is 2:1
     */
    getAgnateWeight(hIdx) {
        if (this.isToShare(hIdx))
            return (this.isMaleAgnate(hIdx)? 2 : (this.isFemaleAgnate(hIdx)? 1 : 0))*1;
        else return 0;
    }

    /**
     *	Sums up the weights of all eligible agnate heirs.
     *	This function calculates the total weight of agnate heirs for distribution.
     *	It does not sum the shares themselves, only their relative weights.
     *	@return	int: the total weight of agnate heirs
     *	@see	#this.getAgnateWeight(int)
     */
    sumUpAgnateWeights() {
        let sumweight = 0*1;  // Initialize the sum of weights
        for (let i=0; i<this.__lstHeirs__.length; i++) {
            if (this.isToShare(i)) {  // Check if the heir is eligible to share
                sumweight += this.__lstHeirs__[i] * this.getAgnateWeight(i);  // Sum up the weight of eligible heirs
            }
        }
        Common.detail(displays["_sumagn_"] + " " + sumweight);  // Log the computed sum of weights
        return sumweight;
    }

    /**
     *	Gets the portion of agnate share for a specific heir, not the share itself.
     *	To compute the actual share, multiply this portion by the remainder.
     *	@param	hIdx int, index of the agnate heir, e.g., Relatives.brother
     *	@return	Rational: the agnate share portion for h, e.g., 2/5
     */
    getAgnatePortion(hIdx) {
        let sumweight = this.sumUpAgnateWeights();  // Compute the total weight of agnate heirs
        return new Fraction(this.__lstHeirs__[hIdx]*this.getAgnateWeight(hIdx), sumweight);  // Calculate and return the agnate share portion
    }

    /**
     *	Checks if any heir is eligible to share by agnation.
     *	@return	boolean
     */
    isAgnation() {
        let ret = false;
        for (let i=Relatives.son; i<Relatives.treasury; i++)	{  // Check for any agnates
            ret = this.agnates(i) || ret;
        }
        return ret;
    }

    /**
     *	Calculates the agnation share of a fractional amount between two agnate heirs.
     *	For example, agnateWith(HeirsFractions.sixth,Relatives.brother,Relatives.sister) calculates the share between a brother and a sister.
     *	@param	amount Rational, fraction being agnated, e.g., HeirsFractions.sixth
     *	@param	h1 int, index of the first heir category
     *	@param	h2 int, index of the second heir category
     *	@return	Rational: the agnation portion of amount for h1
     *	@see	#this.getAgnateWeight(int)
     */
    agnateWith(amount, h1, h2) {
        return Fraction.multiply(new Fraction(this.__lstHeirs__[h1]*this.getAgnateWeight(h1),(this.__lstHeirs__[h2]*this.getAgnateWeight(h2)+this.__lstHeirs__[h1]*this.getAgnateWeight(h1))), amount);
    }

    /**
     *	Handles agnation shares in cases involving grandfather-siblings.
     *	Calculates the agnation share of a fractional amount for an agnate heir.
     *	@param	amount Rational, fraction being agnated, e.g., HeirsFractions.third
     *	@param	hIdx int, index of the heir category
     *	@param	countGrandFathers boolean, whether to include the grandfather in the count
     *			Set to false if the grandfather's share was already set
     *	@return	Rational: the agnation share of amount for h
     */
    agnateGrandFatherAndSiblings(amount, hIdx, countGrandFathers) {
        if (this.isToShare(hIdx)) {
            let denom = this.isToShare(Relatives.brother)? this.__lstHeirs__[Relatives.brother]*this.getAgnateWeight(Relatives.brother) : 0;
            denom += this.isToShare(Relatives.brotherF)? this.__lstHeirs__[Relatives.brotherF]*this.getAgnateWeight(Relatives.brotherF) : 0;
            denom += this.isToShare(Relatives.sister)? this.__lstHeirs__[Relatives.sister]*this.getAgnateWeight(Relatives.sister) : 0;
            denom += this.isToShare(Relatives.sisterF)? this.__lstHeirs__[Relatives.sisterF]*this.getAgnateWeight(Relatives.sisterF) : 0;
            if (countGrandFathers===true && this.isToShare(Relatives.gfather))
                denom += this.__lstHeirs__[Relatives.gfather]*this.getAgnateWeight(Relatives.gfather);
            Common.debug("this.agnateGrandFatherAndSiblings: Denominator = " + denom);
            if (denom>1) {
                let ret = Fraction.multiply(new Fraction(this.__lstHeirs__[hIdx]*this.getAgnateWeight(hIdx), denom), amount);
                Common.debug("this.agnateGrandFatherAndSiblings('" + toString(amount) + "'," + hIdx + "," + countGrandFathers + ")=" + toString(ret));
                return ret;
            }
            else return amount;	//precaution against an improper call
        }
        else return this.has(hIdx);
    }

    /**
     *	Process agnation for grandfather-and-siblings cases according to the rulings attributed to Zaid (RA)
     *	and followed by the Maliki, Shafii, and Hanbali juristic schools.
     */
    processGrandFatherAndSiblingsZ() {
        // Maliki, Shafii, and Hanbali juristic schools follow rulings attributed to Zaid (RA).
        // These schools count siblings from the father in the agnation count but
        // give their shares to the full siblings.
        this.explain("_GFSibsZ_");
        this.sumUp();  // Ensure the sums are calculated before proceeding
        this.logSumAndRemain("gfsibsz");
        let agnateShareGF = this.agnateGrandFatherAndSiblings(this.remain, Relatives.gfather, true);
        let thirdRemain = Fraction.multiply(this.remain, HeirsFractions.third);
        Common.debug("Grandfather agnation share: " + toString(agnateShareGF) + ", 1/3rd of remainder: " + toString(thirdRemain));

        if (this.isGFB()) {
            // Handle the grandfather-and-siblings cases by
            // giving the grandfather the better of 1/3 of the remainder, agnate share, or 1/6 of the estate.
            // Favor full brothers by giving them the shares of half-brothers.
            // Attributed to Zaid (RA).
            this.gets(Relatives.gfather, Fraction.max(Fraction.max(agnateShareGF, thirdRemain), HeirsFractions.sixth));
            Common.detail(displays["_gfather3ag6Z_"]);
            // The grandfather's share may have exceeded the agnate share, so recalculate the remainder.
            Common.detail(displays["_sumaggfather_"]);
            this.sumUp();
            this.logSumAndRemain("gfbz");

            let agnateShareBR = this.agnateGrandFatherAndSiblings(this.remain, Relatives.brother, false);
            let agnateShareBF = this.agnateGrandFatherAndSiblings(this.remain, Relatives.brotherF, false);
            let agnateShareSR = this.agnateGrandFatherAndSiblings(this.remain, Relatives.sister, false);
            let agnateShareSF = this.agnateGrandFatherAndSiblings(this.remain, Relatives.sisterF, false);

            if (this.__lstHeirs__[Relatives.brother] > 0) {
                this.gets(Relatives.brother, Fraction.add(Fraction.add(agnateShareBR, agnateShareBF), agnateShareSF));
                this.gets(Relatives.brotherF, HeirsFractions.none);
                this.gets(Relatives.sister, agnateShareSR);
                this.gets(Relatives.sisterF, HeirsFractions.none);
            } else if (this.__lstHeirs__[Relatives.brotherF] > 0) {  // No full brothers
                if (this.__lstHeirs__[Relatives.sister] > 0) {
                    if (this.__lstHeirs__[Relatives.sister] > 1) {
                        if (Fraction.isLt(this.remain, HeirsFractions.twothirds)) {
                            this.gets(Relatives.brotherF, agnateShareBF);
                            Common.detail(displays["_brothersF23agZ_"]);
                            this.gets(Relatives.sister, Fraction.add(agnateShareSR, agnateShareSF));
                            this.gets(Relatives.sisterF, HeirsFractions.none);
                            Common.detail(displays["_sistersagZ_"]);
                        } else {  // Two-thirds are available
                            this.gets(Relatives.sister, HeirsFractions.twothirds);
                            this.gets(Relatives.brotherF, this.remain);
                            // Should always be zero since the grandfather took a third
                            this.gets(Relatives.sisterF, HeirsFractions.none);
                            Common.detail(displays["_sisters23agZ_"]);
                        }
                    } else {  // One full sister
                        if (Fraction.isLt(this.remain, HeirsFractions.half)) {
                            this.gets(Relatives.sister, Fraction.add(agnateShareSR, agnateShareSF));
                            this.gets(Relatives.brotherF, agnateShareBF);
                            this.gets(Relatives.sisterF, HeirsFractions.none);
                            Common.detail(displays["_sistersagZ_"]);
                        } else {  // Half is available
                            this.gets(Relatives.sister, HeirsFractions.half);
                            this.gets(Relatives.brotherF, this.remain);
                            this.gets(Relatives.sisterF, HeirsFractions.none);
                            Common.detail(displays["_sisters23agZ_"]);
                        }
                    }
                } else {  // No full siblings
                    this.gets(Relatives.brotherF, this.remain);
                    this.gets(Relatives.sisterF, HeirsFractions.none);
                    Common.detail(displays["_brothersFZ_"]);
                }
            }
            // Else do nothing because it will be taken care of under isGFS next
        } else if (this.isGFS()) {  // Handled differently by guaranteeing a minimum of a third to the grandfather
            // Handle the grandfather-and-sisters case by
            // giving the grandfather the better of 1/3 of the remainder or the agnate share.
            // Maliki, Shafii, and Hanbali juristic schools follow the rulings attributed to Zaid (RA).
            // These schools count half-sisters from the father in the agnation count but
            // give their shares to the full sisters.
            this.gets(Relatives.gfather, Fraction.max(agnateShareGF, thirdRemain));
            Common.detail(displays["_gfather3Z_"]);
            // The grandfather's share may have exceeded the agnate share, so recalculate the remainder.
            Common.detail(displays["_sumaggfather_"]);
            this.sumUp();
            this.logSumAndRemain("gfsz");

            let agnateShareSR = this.agnateGrandFatherAndSiblings(this.remain, Relatives.sister, false);
            let agnateShareSF = this.agnateGrandFatherAndSiblings(this.remain, Relatives.sisterF, false);

            if (this.__lstHeirs__[Relatives.sister] > 1)
                this.gets(Relatives.sister, Fraction.min(HeirsFractions.twothirds, agnateShareSR));
            else if (this.__lstHeirs__[Relatives.sister] === 1)
                this.gets(Relatives.sister, Fraction.min(HeirsFractions.half, agnateShareSR));
            Common.detail(displays["_sisters23ag2Z_"]);
            this.gets(Relatives.sisterF, this.remain);
        }
        this.sumUp();
        this.logSumAndRemain("gfsibszc");
    }

    /**
     *	Process agnation for grandfather-and-siblings cases according to the rulings attributed to Ali (RA)
     *	and followed by the Hanafi, Zahiri, and Shii juristic schools as well as Egyptian law.
     */
    processGrandFathersAndSiblings() {
        // Handle the grandfather-and-siblings cases by
        // giving the grandfather the better of 1/3 of the remainder, the agnate share, or 1/6 of the estate.
        this.explain("_GFSibs_");
        this.sumUp();  // Ensure the sums are calculated before proceeding
        let agnateShareGF = this.agnateGrandFatherAndSiblings(this.remain, Relatives.gfather, true);
        let thirdRemain = Fraction.multiply(this.remain, HeirsFractions.third);
        Common.debug("Grandfather agnation share: " + toString(agnateShareGF) + ", 1/3rd of remainder: " + toString(thirdRemain));

        if (this.isGFB()) {
            // Check that 1/6 is not a required minimum in Egyptian law
            if (Preferences.school === Mazhab.Egypt) {
                this.gets(Relatives.gfather, Fraction.max(Fraction.max(agnateShareGF, thirdRemain), HeirsFractions.sixth));
                Common.detail(displays["_gfatheragA_"]);
                // The grandfather's share may have exceeded the agnate share, so recalculate the remainder.
                Common.detail(displays["_sumaggfather_"]);
                this.sumUp();
                this.logSumAndRemain("gfb");

                let agnateShareBR = this.agnateGrandFatherAndSiblings(this.remain, Relatives.brother, false);
                let agnateShareBF = this.agnateGrandFatherAndSiblings(this.remain, Relatives.brotherF, false);
                let agnateShareSR = this.agnateGrandFatherAndSiblings(this.remain, Relatives.sister, false);
                let agnateShareSF = this.agnateGrandFatherAndSiblings(this.remain, Relatives.sisterF, false);
                this.gets(Relatives.brother, agnateShareBR, displays["_regagshare_"]);
                this.gets(Relatives.brotherF, agnateShareBF, displays["_regagshare_"]);
                this.gets(Relatives.sister, agnateShareSR, displays["_regagshare_"]);
                this.gets(Relatives.sisterF, agnateShareSF, displays["_regagshare_"]);
            } else {  // Hanafi, Zahiri, Ibadhi
                Common.detail(displays["_gfatheragSibH_"]);
                this.gets(Relatives.gfather, this.remain);
                this.gets(Relatives.brother, HeirsFractions.none);
                this.gets(Relatives.brotherF, HeirsFractions.none);
                this.gets(Relatives.sister, HeirsFractions.none);
                this.gets(Relatives.sisterF, HeirsFractions.none);
            }
        } else if (this.isGFS()) {
            // Handle the grandfather-and-sisters case by
            // giving the grandfather the better of 1/3 of the remainder or the agnate share.
            Common.detail(displays["_gfatheragAsis_"]);
            if (Preferences.school === Mazhab.Egypt) {
                this.gets(Relatives.gfather, Fraction.max(agnateShareGF, thirdRemain));
                // The grandfather's share may have exceeded the agnate share, so recalculate the remainder.
                Common.detail(displays["_sumaggfather_"]);
                this.sumUp();
                let agnateShareSR = this.agnateGrandFatherAndSiblings(this.remain, Relatives.sister, false);
                let agnateShareSF = this.agnateGrandFatherAndSiblings(this.remain, Relatives.sisterF, false);
                this.gets(Relatives.sister, agnateShareSR, displays["_regagshare_"]);
                this.gets(Relatives.sisterF, agnateShareSF, displays["_regagshare_"]);
            } else {  // Hanafi, Zahiri, Ibadhi
                Common.detail(displays["_gfatheragSibH_"]);
                this.gets(Relatives.gfather, this.remain);
                this.gets(Relatives.sister, HeirsFractions.none);
                this.gets(Relatives.sisterF, HeirsFractions.none);
            }
        }
    }

    /**
     *	Agnation calculations
     *
     *	@since	September 2020
     */
    agnate() {
        //We don't get here unless this.sum is less than 1
        if (!this.isAgnation()) {
            Common.debug("this.agnate(): No this.agnates. Returning");
            return true;
        }
        //Divide remainder among this.agnates by their ratios
        //All of them are marked by -ve this.shares by now
        Common.detail(displays["_applyag_"]);
        //var rsum = HeirsFractions.none;	//no longer used
        //var j;
        let special = (this.__lstHeirs__[Relatives.father] !== 0) && (!this.isMaleDesc()) && (!this.isFemaleDesc());
        if (special) {
            this.gets(Relatives.father, HeirsFractions.toshare());		//his named share will be added later
            Common.detail(displays["_fatherag_"]);
        }
        let specialg = (!this.isVoid(Relatives.gfather)) && (!this.isMaleDesc()) && (!this.isFemaleDesc());
        if (specialg) {
            this.gets(Relatives.gfather, HeirsFractions.toshare());		//his named share will be added later
            Common.detail(displays["_gfatherag_"]);
        }
        //Sum up again, Relatives.father's or Relatives.gfather's share may have changed
        if (this.__lstHeirs__[Relatives.father]>0 || this.__lstHeirs__[Relatives.gfather]>0)
            Common.detail(displays["_sumfathers_"]);
        this.sumUp();
        Common.debug("Commencing agnation...");

        let gfSibsProcessed = false;
        for (let i=this.firstHeir; i<=this.lastHeir; i++) {
            if (!Fraction.isNegative(this.has(i) /*|| isDeprived(i)*/)) continue;
            if (!gfSibsProcessed && (this.isGFB()||this.isGFS()) && this.isMSB()) {	//Zaid (RA)
                //Handle the grandfather-and-siblings cases by
                //giving Relatives.gfather the better of 1/3 of remainder, this.agnate share or 1/6 of estate
                //Siblings this.agnate in the remainder after Relatives.gfather as follows,
                //Full brothers also get the share of HeirsFractions.half brothers who are deprived
                //Full sisters also get the share of HeirsFractions.half sisters who are deprived
                //Attributed to Zaid (RA)
                Common.debug("Processing GFSibsZ (Zaid)");
                this.processGrandFatherAndSiblingsZ();
                gfSibsProcessed = true;
            }
            else if (!gfSibsProcessed && (this.isGFB()||this.isGFS()) && Preferences.school===Mazhab.Egypt) {	//Ali (RA)
                //Handle the grandfather-and-siblings cases by
                //giving Relatives.gfather the better of 1/3 of remainder, this.agnate share or 1/6 of estate
                //Siblings get this.agnate share of the remainder after Relatives.gfather
                Common.debug("Processing GFSibs (Ali)");
                this.processGrandFathersAndSiblings();
                gfSibsProcessed = true;
            }
            else {	//includes Mazhab.Jaafari because HeirsFractions.toshare() mark not placed for Asaba
                //HeirsFractions.toshare() mark still on, therefore not yet given a share, so calculate it now
                let agnateShare = Fraction.multiply(this.getAgnatePortion(i), this.remain);
                if (this.__lstHeirs__[i]*1===1)
                    Common.detail(this.formatHeir(i, true, '') + ": " + displays["_agnateshare_"] + " = " + this.toLangFraction(agnateShare));
                else Common.detail(this.formatHeir(i, false, '') + ": " + displays["_agnateshare_"] + " = " + this.toLangFraction(agnateShare));
                if (this.isMushtarika()) {
                    //Handle the Mushtarika case
                    this.gets(i, agnateShare, displays["_regagshare_"]);		//i's share in agnation
                    //That's all we need. Everything else appears to have been taken care of already
                    //at end of each loop, this.sum up to recalculate the remainder
                }
                else {
                    this.gets(i, agnateShare, displays["_regagshare_"]);	//i's share in agnation (Ali's/Egyptian law)
                    //at end of each loop, this.sum up to recalculate the remainder
                }
                Common.detail(displays["_sumcalcag1_"] + " " + this.formatHeir(i, true, 'G') );
                this.sumUp();	//updates this.remain
                this.logSumAndRemain("ag");
            }
        }
        if (special || specialg) {
            Common.detail(displays["_sumcalcfathers_"]);
            if (!this.addsUp()) {
                if (special===true) {
                    let v = Fraction.add(this.has(Relatives.father), HeirsFractions.sixth);	//his named share added
                    this.gets(Relatives.father, Fraction.min(v, this.remain));
                }
                else if (specialg===true && !this.isGFB()) {
                    let vg = Fraction.add(this.has(Relatives.gfather), HeirsFractions.sixth);	//his named share added
                    this.gets(Relatives.gfather, Fraction.min(vg, this.remain));
                }
                Common.detail(displays["_agplusnamed_"]);
            }
            else Common.detail(displays["_addsupfathers_"]);
        }

        Common.detail(displays["_sumfatherfdec_"]);
        if (!this.addsUp()) {		//e.g., case of Relatives.father with fdec
            this.logSumAndRemain("fdec");
            Common.detail(displays["_notaddupag_"] + " (" + this.getHeirList() + ")");

            for (let k=this.firstHeir; k<=this.lastHeir; k++) {
                if ( this.isMaleAgnate(k) && !this.isVoid(k) ) {
                    this.gets(k, Fraction.add(this.remain, this.has(k)));
                    //he also this.gets the rest
                    if (this.__lstHeirs__[k] === 1) Common.detail(this.formatHeir(k, true, '') + " " + displays["_getsrem_"]);
                    else Common.detail(this.formatHeir(k, true, '') + " " + displays["_getrem_"]);
                    break;
                }
            }
        }
        else Common.detail(displays["_nofatherfdec_"]);

        for (let h=this.firstHeir; h<=this.lastHeir; h++) {
            if ((this.__lstHeirs__[h]>0) && Fraction.isNegative(this.has(h))) {
                this.gets(h, HeirsFractions.none, displays["_lastdeprive_"]);
                //if he didn't get it by now, he doesn't. e.g., Relatives.brotherF or Relatives.sisterF
            }
        }

        //By now this.sum is either 1 or less. If <1, maybe female Asaba applies
        Common.detail(displays["_sumfasaba_"]);
        this.sumUp();
        if (this.isUndersubscribed()) {
            //See if there is female agnation
            let halfremain = Fraction.multiply(this.remain, HeirsFractions.half);
            if (!this.hasFemaleAgnates()) {
                Common.detail(displays["_nofasaba_"]);
            }
            else if ((this.__lstHeirs__[Relatives.daughter]>0) && (!this.isVoid(Relatives.sister))) {
                this.gets(Relatives.daughter, halfremain);
                this.gets(Relatives.sister, halfremain);
            }
            else if ((this.__lstHeirs__[Relatives.daughter]>0) && (!this.isVoid(Relatives.sisterF))) {
                this.gets(Relatives.daughter, halfremain);
                this.gets(Relatives.sisterF, halfremain);
            }
            else if ((!this.isVoid(Relatives.gdaughter)) && (!this.isVoid(Relatives.sister))) {
                this.gets(Relatives.gdaughter, halfremain);
                this.gets(Relatives.sister, halfremain);
            }
            else if ((!this.isVoid(Relatives.gdaughter)) && (!this.isVoid(Relatives.sisterF))) {
                this.gets(Relatives.gdaughter, halfremain);
                this.gets(Relatives.sisterF, halfremain);
            }
        }
        else Common.detail(displays["_notundersub_"]);
        this.sumUp();
        this.logSumAndRemain("agc");
        Common.detail(displays["_finagn_"]);
        return true;	//Agnation complete
    }

//lookup.js is loaded before this
    /**
     *	Looks for a case whose pattern matches the entered Fraction.one and if found, returns its representing JSON object
     *	@return	JSON object representing the matching case, in the format:
     *			{"id":id, "hl":hl, "hc":hc, "sc":sc, "rv":rv, "aw":aw, "sh":sh, "ex":ex}
     *			If object doesn't have them, rv defaults to "1", sc defaults to "1", aw defaults to "1"
     *			If case is not found in the lookup list (lookup.js), null is returned
     *	@see	#LookupPatterns
     *	@since	August 2020
     *	
     */
    getMatchingCase() {
        //Save unnecessary lookup
        //if (this.__lstHeirs__[Relatives.gfather]===0) return null;
        //if ((this.__lstHeirs__[Relatives.gfather]>0) && !this.isMSB()) return null;
        let spec = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
        for (let i=0; i<spec.length; i++)
            spec[i] = this.__lstHeirs__[i];
        Common.debug("Looking up among special case patterns for the case of '" + spec);
        //console.log("Patterns" + JSON.stringify(lookupPatterns) + "\n");
        //lookupPatterns is an array of pattern objects defined in lookup.js and loaded before this file
        for (let i=0; i<Preferences.lookupPatterns.length; i++) {
            let entry = Preferences.lookupPatterns[i];
            Common.debug("Comparing with pattern " + entry.id + ": " + JSON.stringify(entry));
            //var sc = /[2-4]/;	//We already excluded the others
            let sc = /[0-9]/;
            if (entry.sc) sc = new RegExp(entry.sc);
            let matchschool = sc.test(Preferences.school.toString());
            if (!matchschool) {
                Common.debug("Schools don't match: " + sc.toString() + " vs " + Preferences.school);
                Common.debug("sc:" + sc + ".test(" + Preferences.school.toString() + "):" + matchschool);
                continue;
            }
            let rv = (Preferences.allowRuddToSpouses? "2" : (Preferences.allowRudd? "1" : "0"));
            //console.log(entry);
            if (entry.rv) {
                if (!entry.rv.Fraction.equals(rv)) {
                    console.log("Reversion rules don't match: " + entry.rv.toString() + " vs " + rv);
                    continue;
                }
            }
            let aw = (Preferences.allowAwl? "1" : "0");
            if (entry.aw) {
                if (!entry.aw.Fraction.equals(aw)) {
                    Common.debug("Redivision rules don't match: " + entry.aw.toString() + " vs " + aw);
                    continue;
                }
            }
            let hn = (entry.hl).split(",");	//hn is an array of constant names of heir indeces
            let hl = [];
            for (let j=0; j<hn.length; j++)
                // eslint-disable-next-line no-eval
                hl.push(eval(hn[j]));
            //hl is an array of int heir indeces
            if (!Common.inArray(hl, this.firstHeir)) {
                Common.debug("No match. First heir, " + this.firstHeir + ", is not in heir list: " + hl.toString());
                continue;
            }
            let hc = (entry.hc).split(",");		//hc is an array of int: special case heir counts
            //console.log("hc array: " + hc + "\n");
            let kace = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
            //Replace non-zeroes in kace
            //console.log(hl.length + "\n");
            for (let j=0; j<hl.length; j++) {
                let val = hl[j]-0;
                //heir index at element j of hl, e.g., hn[0]=Relatives.gfather -> hl[0]=val=12
                Common.debug("hl["+j+"]=" + val);
                kace[val] = hc[j];	//e.g., kace[12]=1
            }
            //Match kace with spec
            let matchheirs = true;
            for (let k=0; k<kace.length; k++) {
                Common.debug(k + " kace: " + kace[k] + " spec: " + spec[k]);
                if ((""+kace[k]).startsWith(">")) {		//works likewise with >=
                    // eslint-disable-next-line no-eval
                    let b = eval(spec[k] + "*1" + kace[k] + "*1");	//e.g., eval(2*1>1*1)
                    Common.debug(k + (!b? ": no " : " ") + "match");
                    if (!b) matchheirs = false;
                }
                else if (kace[k] !== spec[k]) {
                    Common.debug(k + ": no match");
                    matchheirs = false;
                }
                else console.log(k + ": match\n");
            }
            if (matchheirs && matchschool) {
                Common.debug("Pattern matching case found. Id: " + entry.id);
                sc = null;
                return entry;
            }
        }
        return null;
    }

    /**
     *	Process a special case directly
     *	@param caseToProcess a JSON object representing the special case, structured as follows:
     *		{"id":id, "hl":hl, "hc":hc, "sh":sh, "sc":sc, "rv":rv, "ex":ex}, where
     *		id: string, a unique identification,
     *		hl: string, comma-separated list of heir categories, e.g., "Relatives.gfather,Relatives.brother",
     *		hc: string, comma-separated list of heir counts in each category of hl in order, e.g., "1,2",
     *		sc: string of an int, juristic Preferences.school. Can be a RegEx, e.g., "[2-4]". Default: "0"
     *		rv: string of an int, reversion level,
     *			"2": Preferences.allowRuddToSpouses, "1": Preferences.allowRudd (default), "0": don't allow
     *		sh: string, comma-separated list of shares of said heirs in order, as rational fractions, e.g.,
     *			"1/3,2/3",
     *		ex: string, an explanation.
     *	@return boolean: true if case to process was successfully processed, false otherwise
     */
    processSpecialCase(caseToProcess) {
        console.log("Processing special case " + JSON.stringify(caseToProcess) + "...\n");
        let hl = caseToProcess.hl;
        let hc = caseToProcess.hc;
        let shrstr = caseToProcess.sh;
        let ex = caseToProcess.ex;
        let id = caseToProcess.id;
        if (id && id.indexOf('Z')===0) this.explain("_ZGfSibs_");
        let nh = hc.split(",");		//Array of int heir counts
        let hn = hl.split(",");		//Array of string constants of heir indeces
        console.log("Matched this.shares string: " + shrstr + "\n");
        if (shrstr && shrstr.length >0) {
            let shrs = shrstr.split(',');
            for (let i=0; i<shrs.length; i++) {
                let val = HeirsFractions.none;
                Common.debug("shrs[" + i + "] = " + shrs[i]);
                //See if it's already a rational fraction
                if (shrs[i].indexOf('=') !== 0) val = Fraction.toRational(shrs[i]);
                else {
                    Common.debug("val[" + i + "] is a formula");
                    // eslint-disable-next-line no-eval
                    Common.debug("nh[" + i + "] = " + eval(nh[i]*1));
                    let formula = shrs[i].substring(1).replace(/;/g, ",");
                    Common.debug("Formula = " + formula + "\n");
                    // eslint-disable-next-line no-eval
                    val = eval(formula);
                    Common.debug("Formula evaluated to " + toString(val));
                }
                Common.debug("val[" + i + "] = " + toString(val));

                // eslint-disable-next-line no-eval
                this.gets(eval(hn[i]), val);
                Common.detail("(" + displays["_specialcase_"] + id + ")");
                // eslint-disable-next-line no-eval
                Common.debug(this.formatHeir(eval(hn[i])) + " getting " + toString(val));
            }
            this.explain(ex);
            return true;
        }
        else return false;
    }

    /**
     *	Adjust all computed shares by redivision if a Relatives.bequest has been specified
     */
    adjustInBequestCase() {
        if (Fraction.isGt(this.bequestShare, HeirsFractions.none)) {
            let multiplier = Fraction.subtract(HeirsFractions.whole, this.bequestShare);
            for (let h=Relatives.son; h<=Relatives.treasury; h++) {
                if (!Fraction.isZero(this.has(h))) {
                    //Bug fix: this.isVoid() checks this.__lstHeirs__ which doesn't apply to Relatives.treasury
                    Common.debug("Reducing share of " + h + " from " + toString(this.has(h)) + " because of Relatives.bequest");
                    this.gets(h, Fraction.multiply(this.has(h), multiplier));
                    Common.detail(this.formatHeir(h, true, '') + ": " + displays["_reducedbq_"]);
                }
            }
            this.gets(Relatives.bequest, this.bequestShare);	//redundant
        }
    }

    /**
     *	@return	a JSON object string representation of the case at hand
     */
    caseToString() {
        let hl = [];
        let hc = [];
        let chers = [];
        let sc = Preferences.school;
        let rv = (Preferences.allowRuddToSpouses? 2: (Preferences.allowRudd? 1: 0));
        let aw = (Preferences.allowAwl? 1 : 0);
        for (let h=Relatives.son; h<Relatives.treasury; h++) {
            if (this.__lstHeirs__[h]>0) {
                hl.push(h);
                hc.push(this.__lstHeirs__[h]);
                chers.push(toString(this.has(h)));
            }
        }
        return '{"id":"Present", "hl":"' + hl.join(",") + '", "hc":"' + hc.join(",") + '", "sh":"' + chers.join(",") + '", sc="' + sc + '", "rv":"' + rv + '", "aw":' + aw + '", "ex":""}';
    }

    /**
     *	@return	a JSON object representation of the case at hand
     */
    caseToJSON() {
        return JSON.parse(this.caseToString());
    }

    /**
     *	@return	a testcase-like array representation of the case at hand
     */
    caseToEnum() {
        let hc = [];
        let sc = Preferences.school;
        let rv = (Preferences.allowRuddToSpouses? 2: (Preferences.allowRudd? 1: 0));
        let aw = (Preferences.allowAwl? 1 : 0);
        let bq = !Fraction.isZero(this.bequestShare)? toString(this.bequestShare) : 0;
        hc.push(bq? ("'" + bq + "'") : 0);
        for (let h=Relatives.son; h<Relatives.treasury; h++)
            hc.push(this.__lstHeirs__[h]);
        hc.push(rv);
        hc.push(sc);
        hc.push(aw);
        return hc;
    }

    /**
     *	Main share calculation logic
     *	@return	true if share calculation was successful, false otherwise
     *			If false, an assertion message is output explaining the cause of failure

     *	@since	August 2020
     */
    calculateHeirsShares() {
        let rsum = HeirsFractions.none;
        let r1 = HeirsFractions.none;
        let r2 = HeirsFractions.none;
        let j = HeirsFractions.none;

        for (let i=0; i<=Relatives.treasury; i++) this.shares[i] = HeirsFractions.none;

        this.getHeirRange();			//sets this.firstHeir and this.lastHeir
        if (!this.assertTrue((this.firstHeir>=0) && (this.lastHeir<Relatives.treasury), displays["_rangefail_"])) return false;

        //Mazhab.Jaafari tiers. Deprive all heirs in tiers lower than the highest tier where anyone survived
        if (Preferences.school===Mazhab.Jaafari) this.depriveJ();

        //No heirs
        if (this.lastHeir === 0) {
            for (let i=Relatives.son; i<Relatives.treasury; i++) this.shares[i]=HeirsFractions.none;
            if (!Fraction.isZero(this.bequestShare)) Common.detail(displays["_bequest1_"]);
            this.gets(Relatives.treasury, Fraction.subtract(HeirsFractions.whole,this.bequestShare));
            Common.detail(displays["_treasury1_"]);
            return true;
        }
        //Sole heir
        else if (this.lastHeir === this.firstHeir) {
            for (let i=Relatives.son; i<=Relatives.treasury; i++) this.shares[i]=HeirsFractions.none;
            if (this.isSpouse(this.lastHeir)){
                if (Preferences.allowRuddToSpouses===true) {
                    this.gets(this.lastHeir, Fraction.subtract(HeirsFractions.whole,this.bequestShare));
                }
                else if (this.lastHeir === Relatives.husband) {
                    this.gets(Relatives.husband, Fraction.multiply(HeirsFractions.half, Fraction.subtract(HeirsFractions.whole,this.bequestShare)));
                    this.gets(Relatives.treasury, Fraction.multiply(HeirsFractions.half, Fraction.subtract(HeirsFractions.whole,this.bequestShare)));
                }
                else if (this.lastHeir === Relatives.wife) {
                    this.gets(Relatives.wife, Fraction.multiply(HeirsFractions.quarter, Fraction.subtract(HeirsFractions.whole,this.bequestShare)));
                    this.gets(Relatives.treasury, Fraction.multiply(new Fraction(3,4), Fraction.subtract(HeirsFractions.whole,this.bequestShare)));
                }
            }
            else if (Preferences.allowRudd===false && Common.inArray(Heirs.namedHeirs, this.lastHeir)) {
                this.calculateHeirsSharesOfNamedHeirs(this.lastHeir);	//1/6, 1/3, 1/2 or 2/3
                let rbq = Fraction.subtract(HeirsFractions.whole,this.bequestShare);
                this.gets(this.lastHeir, Fraction.multiply(this.has(this.lastHeir), rbq));
                this.gets(Relatives.treasury, Fraction.subtract(HeirsFractions.whole, this.has(this.lastHeir)));
                Common.detail(displays["_treasury3_"]);
            }
            else {	//Rudd is allowed, or Asaba
                this.gets(this.lastHeir, Fraction.subtract(HeirsFractions.whole,this.bequestShare));
            }
            if (!Fraction.isZero(this.bequestShare)) Common.detail(displays["_bequest1_"]);
            return true;
        }
        //More than Fraction.one heir
        else {
            //First, look for a matching special case
            let machingCase = this.getMatchingCase();
            //var machingCase = null;
            if (machingCase!==null) {
                return Common.ensure(this.processSpecialCase(machingCase)===true, displays["_specialfailed_"]);
            }
            //If not found, calculate
            else {
                Common.debug("Case is not in special cases");
                Common.detail("<br>" + displays["_calcnamed_"]);
                for (let i=0; i<Heirs.namedHeirs.length; i++) this.calculateHeirsSharesOfNamedHeirs(Heirs.namedHeirs[i]);
                Common.detail("<br/>" + displays["_fincalcnamed_"]);
                for (let j=0; j<Heirs.agnateHeirs.length; j++) this.calculateHeirsSharesOfAgnatedHeirs(Heirs.agnateHeirs[j]);
                Common.detail("<br/>" + displays["_fincalcagnate_"]);
                if (!this.sanityCheck()) return false;

                Common.detail("<br/>" + displays["_sumoverunder_"]);
                //Awl (oversubscription)
                if (this.isOversubscribed()) {
                    this.logSumAndRemain("aw");
                    Common.detail(displays["_isoversub_"]);
                    this.redivide();
                    //Sanity check:
                    Common.detail(displays["_addsup_"]);
                    if (!this.assertTrue(this.addsUp(), displays["_redivfail_"])) return false;
                    else Common.detail(displays["_redivok_"]);
                }
                //Agnation or undersubscription
                else if (this.isUndersubscribed()) {
                    if (!this.agnate()) return false;
                    //this.sum of this.shares must be <=1 by now
                    if (this.isUndersubscribed()) {
                        //No this.agnates; Fraction.divide remainder between named heirs, "Rudd" (reversion)
                        this.logSumAndRemain("rv");
                        if (Preferences.school === Mazhab.Maliki || Preferences.school===Mazhab.Zahiri) {
                            if (Preferences.allowRudd === false)
                                Common.detail(displays["_noruddMZ_"]);
                            else
                                Common.detail(displays["_userrudd_"]);
                        }
                        else if (Preferences.allowRudd === false)
                            Common.detail(displays["_usernorudd_"]);
                        if (Preferences.allowRuddToSpouses === true)
                            Common.detail(displays["_userrudd2_"]);
                        else Common.detail(displays["_rudd2C_"]);
                        if (Preferences.allowRudd === true || Preferences.allowRuddToSpouses === true) {
                            Common.detail(displays["_noagrudd_"]);
                            rsum = HeirsFractions.none;
                            for (let k=this.firstHeir; k<=this.lastHeir; k++) {
                                if ((this.__lstHeirs__[k]>0) && this.isRevertTo(k))
                                    rsum = Fraction.add(rsum, this.has(k));
                            }
                            Common.debug("calcualteShares: Sum of parts is: " + toString(rsum));
                            for (let h=this.firstHeir; h<=this.lastHeir; h++) {
                                if ((this.__lstHeirs__[h]>0) && this.isRevertTo(h)) {
                                    j = this.has(h);
                                    r1 = Fraction.divide(j, rsum);
                                    r2 = Fraction.multiply(r1, this.remain);	//additional share
                                    if (this.__lstHeirs__[h]===1)
                                        Common.detail(this.formatHeir(h, true, '') + ": " + displays["_ruddextra_"] + displays["_comma_"] + " " + toString(r2));
                                    else Common.detail(this.formatHeir(h, true, '') + ": " + displays["_ruddextra_"] + displays["_comma_"] + " " + toString(r2));
                                    this.gets(h, Fraction.add(j, r2));
                                }
                            }
                        }
                        else {
                            this.gets(Relatives.treasury, this.calculateRemainder());
                            Common.detail(displays["_norudd_"]);
                        }
                        Common.ensure(this.addsUp(), displays["_notfullrudd_"]);
                    }
                    //Common.ensure(this.addsUp(), displays["_notfullagrudd_"]);	//any left TOSHARE will be voided later
                }
                if (!this.sanityCheck()) return false;
                else Common.debug("Done with calculation");
            }
            if (!this.sanityCheck()) return false;
            else Common.debug("Finished with calculations");
        }

        //this.sum = HeirsFractions.whole, so make sure any -ve share marks left are voided
        for (let i=this.firstHeir; i<=this.lastHeir; i++) {
            if ((this.__lstHeirs__[i]-0)>0 && Fraction.isNegative(this.has(i))) {
                Common.debug(this.formatHeir(i, true, '') + " is still set to share after applying all the rules. Setting their share to HeirsFractions.none");
                this.gets(i, HeirsFractions.none);
                Common.detail(displays["_finaldeprive_"]);
            }
        }
        if (!this.sanityCheck()) return false;

        Common.detail(displays["_finalsum_"]);
        if (this.addsUp()) {
            Common.detail(displays["_doesaddup_"]);
        }
        else {
            Common.detail(displays["_treasury4_"]);
            this.gets(Relatives.treasury, this.calculateRemainder());
            Common.detail(displays["_sumtreasury_"]);
            this.sumUp();
            this.logSumAndRemain("this.sum");
        }

        //Final sanity checks
        if (!this.assertTrue(Fraction.isOne(this.sum), displays["_sumfail_"] + ": " + toString(this.sum) + "\n" + this.caseToEnum())) {
            return false;
        }

        this.adjustInBequestCase();		//Includes the Relatives.treasury

        if (!this.sanityCheck()) return false;

        Common.detail(displays["_theEnd_"]);
        return true;
    }



}


export default CaseStatus;