import Mazhab from "./Define/Mazhab";
import Common from "./Common";
import {JSONcookie} from "./Output/JSONcookie";

class Preferences {


    static theLanguage = "";



    /**
     *	User preference whether to allow reversion
     */
    static allowRudd = true;
    /**
     *	User preference whether to allow reversion to spouses
     */
    static allowRuddToSpouses = false;
    /**
     *	User preference whether to allow redivision
     */
    static allowAwl = true;


    static school = Mazhab.Hanafi;



    /**
     *	@return	string value of the translation cookie, e.g., "/en/de", or "" if reset
     */
    static getTranslationCookie() {
        return Common.getCookieValue("googtrans");
    }

    /**
     *	@return	string Language code of the target value specified in the translation cookie, or "" if reset
     */
    static getTranslationCookieTargetLanguage() {
        let tc = Preferences.getTranslationCookie();
        if (tc) return tc.substring(4);
        else return "";
    }

    /**
     *	@return	string language code for the page language, passed in the query string
     */
    static getPageLanguage() {
        let args = Common.getArgs();
        if (args && args["lang"]) return args["lang"];
        let langcode = Preferences.getTranslationCookieTargetLanguage();
        if (langcode) return langcode;
        else return document.body.lang;
    }

    /**
     *	Sets the lang attribute for the page, passed in the query string or specified in resource bundle
     */
    static setPageLanguage() {
        if (!document.body.lang)
            document.body.lang = Preferences.getPageLanguage();
    }

    /**
     *	@return	boolean: if page language direction is RTL, passed in the query string
     */
    static isPageLanguageDirectionRTL() {
        let ret = false;
        let args = Common.getArgs();
        if (args) {
            let dir = args["dir"];
            if (dir) ret = (dir==="rtl");
        }
        return ret;
    }

    /**
     *	Expires the translation cookie
     */
    static expireTranslationCookie() {
        JSONcookie.del("googtrans");
    }

    /**
     *	Sets the translation cookie value
     *	@param	targetLanguageCode string, e.g., 'fr' for French, or "" to expire the cookie
     */
    static setTranslationCookie(targetLanguageCode) {
        if ("en"!==targetLanguageCode) {
            if ("" === targetLanguageCode) Preferences.expireTranslationCookie();
            else Common.setCookie("googtrans", "/en/" + targetLanguageCode);
        }
    }


    /**
     *	Patterns of special cases as an array of JSON objects. Each object is structured as follows:
     *	{"id":id, "hl":hl, "hc":hc, "sc":sc, "sh":sh, "ex":ex}, where
     *	id: an identifier
     *	hl: heir list, using constants, ordered by the heirs indeces, e.g., "gafther,brother"
     *	hc: count of heirs listed in hl in the same order, e.g., "1,2".
     *		Count may begin with the greater-than sign, e.g., ">2" or ">=0"
     *	sc: index of juristic school, e.g. 3 for Shafii
     *		Index may be specified as a regular expression, e.g., "[2-4]" for Maliki, Shafii or Hanbali
     *	sh: Shares of heirs, expressed as textual rational fractions and
     *		listed in hl in the same order, e.g., "1/3,2/3"
     *		Any given share could be specified as a formula by starting it with an equals sign
     *		followed by Javascript code that can be evaluated at run time with the #eval(string) function.
     *		Commas in the code must be enterd as semicolons	to distinguish them from commas that separate the shares,
     *		e.g., "=new Rational(1;12)" yields the Rational object 1/12
     *	ex:	Id string of entry key into the global explanations associative array. May be blank
     *		If Id starts with capital letter Z, it refers to of the grandfather-siblings case using Zaid's rulings,
     *		and if it starts with capital letter A, it refers to one of the grandfather-siblings using Ali's rulings
     *	@see	#explanations

     *	@since	September 2020
     */
    static lookupPatterns = [];


}

export default Preferences;