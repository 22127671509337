class Relatives {

    static bequest = 0;

    static son = 1;

    static daughter = 2;

    static father = 3;

    static mother = 4;

    static husband = 5;

    static wife = 6;

    static brother = 7;

    static sister = 8;

    static siblingM = 9;

    static gson = 10;

    static gdaughter = 11;

    static gfather = 12;

    static gmotherF = 13;

    static uncle = 14;

    static uncleF = 15;

    static brotherF = 16;

    static sisterF = 17;

    static relativeM = 18;

    static nephew = 19;

    static nephewF = 20;

    static gmotherM = 21;

    static cousin = 22;

    static cousinF = 23;

    static servant = 24;

    static treasury = 25;

    static numcats = 26;

}

export default Relatives;