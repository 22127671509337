import React from 'react';
import NumInput from "../NumInput/NumInput";

const Siblings = props => {

    return (
        <tbody>
        <tr>
            <th>Heirs</th>
            <th>Number</th>
            <th>Share</th>
            <th>Total ($)</th>
        </tr>
        <tr>
            <td>Full brothers</td>
            <td>
                <NumInput name="num7" id="num7" size="2" tabIndex="7"/>
            </td>
            <td id="get7">&nbsp;</td>
            <td id="get7usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Full sisters</td>
            <td>
                <NumInput name="num8" id="num8" size="2" tabIndex="8"/>
            </td>
            <td id="get8">&nbsp;</td>
            <td id="get8usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Maternal half-siblings</td>
            <td>
                <NumInput name="num9" size="2" tabIndex="9" id="num9"/>
            </td>
            <td id="get9">&nbsp;</td>
            <td id="get9usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Paternal half-brothers</td>
            <td>
                <NumInput name="num16" size="2" tabIndex="16" id="num16"/>
            </td>
            <td id="get16">&nbsp;</td>
            <td id="get16usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Paternal half-sisters</td>
            <td>
                <NumInput name="num17" size="2" tabIndex="17" id="num17"/>
            </td>
            <td id="get17">&nbsp;</td>
            <td id="get17usd">&nbsp;</td>
        </tr>
        </tbody>
    );

};

export default Siblings;
