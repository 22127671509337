/**
 * Rational number arithmetic operations.
 */
class Fraction {

    /**
     * Constructor for creating a rational number with a numerator and a denominator.
     *
     * @constructor
     * @param {number} numerator - Numerator of the fraction.
     * @param {number} denominator - Denominator of the fraction.
     */
    constructor(numerator, denominator) {
        this.num = numerator;
        this.denom = denominator;
    }

    /**
     * Represents zero as a rational fraction.
     * @return {Fraction} Fraction(0, 1)
     */
    static zero() {
        return new Fraction(0, 1);
    }

    /**
     * Represents one as a rational fraction.
     * @return {Fraction} Rational(1, 1)
     */
    static one() {
        return new Fraction(1, 1);
    }

    /**
     * Checks if a numerator divides a denominator without a remainder.
     * @param {number} numerator - Numerator to check.
     * @param {number} denominator - Denominator to check.
     * @return {boolean} Whether numerator divides denominator evenly.
     */
    static divides(numerator, denominator) {
        return (numerator % denominator) === 0;
    }

    /**
     * Finds the greatest common divisor (GCD) of two rational fractions x and y.
     * @param {Fraction} x - First rational fraction.
     * @param {Fraction} y - Second rational fraction.
     * @return {number} GCD of x and y.
     */
    static gcd(x, y) {
        let denomX = x.denom;
        let denomY = y.denom;
        if (Fraction.divides(denomX, denomY)) return denomX;
        else if (Fraction.divides(denomY, denomX)) return denomY;
        else return denomX * denomY;
    }

    /**
     * Checks if a rational number is negative.
     * @param {Fraction} x - Rational fraction to check.
     * @return {boolean} True if x is negative, otherwise false.
     */
    static isNegative(x) {
        return x.num < 0;
    }

    /**
     * Negates a rational number.
     * @param {Fraction} x - Rational fraction to negate.
     * @return {Fraction} Negated x.
     */
    static negate(x) {
        x.num = -(x.num);
        return x;
    }

    /**
     * Checks if a rational number is zero.
     * @param {Fraction} x - Rational fraction to check.
     * @return {boolean} True if x is zero, otherwise false.
     */
    static isZero(x) {
        return x.num === 0;
    }

    /**
     * Checks if a value is a valid rational fraction.
     * @param {*} x - Value to check if it's a valid rational fraction.
     * @return {boolean} True if x is a valid rational fraction, otherwise false.
     */
    static isRational(x) {
        return (x instanceof Fraction) && (!isNaN(x.num)) && (!isNaN(x.denom)) && (x.denom > 0);
    }

    /**
     * Simplifies a fraction by reducing its numerator and denominator to their simplest form.
     * @param {Fraction} x - Rational fraction to simplify.
     * @return {Fraction} Simplified rational fraction.
     */
    static simplify(x) {
        let isNeg = Fraction.isNegative(x);
        let numerator = isNeg ? -(x.num) : x.num;
        let denominator = x.denom;
        let smaller = Math.min(numerator, denominator);
        if (Fraction.divides(numerator, denominator)) {
            numerator /= denominator;
            denominator = 1;
        } else if (Fraction.divides(denominator, numerator)) {
            denominator /= numerator;
            numerator = 1;
        } else {
            for (let i = 2; i <= smaller; i++) {
                while (Fraction.divides(numerator, i) && Fraction.divides(denominator, i)) {
                    numerator /= i;
                    denominator /= i;
                }
                smaller = Math.min(numerator, denominator);
            }
        }
        let simplifiedFraction = new Fraction(1, 1);
        simplifiedFraction.num = isNeg ? -numerator : numerator;
        simplifiedFraction.denom = denominator;
        return simplifiedFraction;
    }

    /**
     * Multiplies two rational numbers.
     * @param {Fraction} x - First rational number.
     * @param {Fraction} y - Second rational number.
     * @return {Fraction} Product of x and y.
     */
    static multiply(x, y) {
        let product = new Fraction(1, 1);
        product.num = x.num * y.num;
        product.denom = x.denom * y.denom;
        return Fraction.simplify(product);
    }

    /**
     * Divides a rational number by another.
     * @param {Fraction} x - Numerator rational number.
     * @param {Fraction} y - Denominator rational number.
     * @return {Fraction} x divided by y.
     */
    static divide(x, y) {
        let division = new Fraction(1, 1);
        division.num = x.num * y.denom;
        division.denom = x.denom * y.num;
        return Fraction.simplify(division);
    }

    /**
     * Adds two rational numbers.
     * @param {Fraction} x - First rational number.
     * @param {Fraction} y - Second rational number.
     * @return {Fraction} Sum of x and y.
     */
    static add(x, y) {
        let gcdXY = Fraction.gcd(x, y);
        let sum = new Fraction(1, 1);
        sum.num = x.num * gcdXY / x.denom + y.num * gcdXY / y.denom;
        sum.denom = gcdXY;
        return Fraction.simplify(sum);
    }

    /**
     * Subtracts one rational number from another.
     * @param {Fraction} x - First rational number.
     * @param {Fraction} y - Second rational number.
     * @return {Fraction} y subtracted from x.
     */
    static subtract(x, y) {
        let gcdXY = Fraction.gcd(x, y);
        let difference = new Fraction(1, 1);
        difference.num = x.num * gcdXY / x.denom - y.num * gcdXY / y.denom;
        difference.denom = gcdXY;
        return Fraction.simplify(difference);
    }

    /**
     * Finds the greater of two rational fractions.
     * @param {Fraction} x - First rational fraction.
     * @param {Fraction} y - Second rational fraction.
     * @return {Fraction} Rational fraction, the greater of the two fractions.
     */
    static max(x, y) {
        let difference = Fraction.subtract(x, y);
        if (Fraction.isNegative(difference)) return y;
        else return x;
    }

    /**
     * Finds the lesser of two rational fractions.
     * @param {Fraction} x - First rational fraction.
     * @param {Fraction} y - Second rational fraction.
     * @return {Fraction} Rational fraction, the lesser of the two fractions.
     */
    static min(x, y) {
        let difference = Fraction.subtract(y, x);
        if (Fraction.isNegative(difference)) return y;
        else return x;
    }

    /**
     * Checks if a rational fraction is less than one.
     * @param {Fraction} x - The rational fraction to check.
     * @return {boolean} True or false.
     */
    static isLtOne(x) {
        let simplifiedX = Fraction.simplify(x);
        return ((simplifiedX.num - simplifiedX.denom) < 0);
    }

    /**
     * Checks if a rational fraction is greater than one.
     * @param {Fraction} x - The rational fraction to check.
     * @return {boolean} True or false.
     */
    static isGtOne(x) {
        let simplifiedX = Fraction.simplify(x);
        return ((simplifiedX.num - simplifiedX.denom) > 0);
    }

    /**
     * Checks if a rational fraction is equal to one.
     * @param {Fraction} x - The rational fraction to check.
     * @return {boolean} True or false.
     */
    static isOne(x) {
        let simplifiedX = Fraction.simplify(x);
        return ((simplifiedX.num - simplifiedX.denom) === 0);
    }

    /**
     * Checks if one rational fraction is less than another.
     * @param {Fraction} x - First rational fraction.
     * @param {Fraction} y - Second rational fraction.
     * @return {boolean} True or false.
     */
    static isLt(x, y) {
        let difference = Fraction.subtract(x, y);
        return Fraction.isNegative(difference);
    }

    /**
     * Checks if one rational fraction is greater than another.
     * @param {Fraction} x - First rational fraction.
     * @param {Fraction} y - Second rational fraction.
     * @return {boolean} True or false.
     */
    static isGt(x, y) {
        let difference = Fraction.subtract(y, x);
        return Fraction.isNegative(difference);
    }

    /**
     * Checks if two rational fractions are equal.
     * @param {Fraction} x - First rational fraction.
     * @param {Fraction} y - Second rational fraction.
     * @return {boolean} True or false.
     */
    static equals(x, y) {
        let difference = Fraction.subtract(x, y);
        return Fraction.isZero(difference);
    }

    /**
     * Converts a string representation to a Rational fraction.
     * @param {string} s - String to convert.
     * @return {Fraction} Rational fraction.
     */
    static toRational(s) {
        if (!s) return Fraction.zero();
        if ("" === s) return Fraction.zero();
        if ("NONE" === s.toUpperCase()) return Fraction.zero();
        if ("0" === s) return Fraction.zero();
        if ("1" === s) return Fraction.one();
        let i = s.indexOf("/");
        if (i < 0) {
            console.warn("Cannot convert " + s + " to a rational fraction");
            return null;
        }
        let z = Fraction.one();
        z.num = parseInt(s.substring(0, i));
        z.denom = parseInt(s.substring(i + 1));
        if (z.denom < 0) {
            z.num = -(z.num);
            z.denom = -(z.denom);
        }
        return z;
    }

    /**
     * Converts a Rational fraction to a string representation.
     * @param {Fraction} x - Rational fraction to convert.
     * @return {string} String representation of the rational fraction.
     */
    static toString(x) {
        if ((x.num === -1) && (x.denom === 1)) return "TOSHARE";
        else if (Fraction.isZero(x)) return "NONE";
        else if (Fraction.isOne(x)) return "WHOLE";
        else return x.num + "/" + x.denom;
    }

    /**
     * Calculates the value of a fraction in USD based on a total value and the fraction.
     * @param {number} total - Total value.
     * @param {string} fraction - Fraction to calculate value for.
     * @return {number|string} Calculated value or special string.
     */
    static calculateFractionValueInUsd(total, fraction) {
        let suffix = '';

        // Handle special cases
        if (fraction === 'WHOLE') return total;
        if (fraction === '') return '';
        if (fraction === 'NONE' || fraction === 0 || !fraction || fraction === '0') return 0;
        if (fraction === 'TOSHARE') return '---';

        // Remove ' each' if it exists
        if (fraction.endsWith(' each')) {
            fraction = fraction.slice(0, -5);
            suffix = ' each';
        }

        // Validate and split the fraction string into numerator and denominator
        const parts = fraction.split('/');
        if (parts.length !== 2) return total + suffix;

        const numerator = Number(parts[0]);
        const denominator = Number(parts[1]);

        // Calculate the value
        const value = (total / denominator) * numerator;

        // Check for NaN and return total if NaN
        return (isNaN(value) ? 0 : Number(value.toFixed(2))) + suffix;
    }

    /**
     * Checks if a value is a valid rational fraction.
     * @param {*} x - Value to validate as a correct rational fraction.
     * @return {boolean} True if x is a valid rational fraction, otherwise false.
     */
    static isValidRational(x) {
        if ((x instanceof Fraction) && (isNaN(x.num) || isNaN(x.denom))) {
            console.warn("Rational::isValid(" + x.num + "/" + x.denom + ")\n");
            return false;
        } else if ((x instanceof Fraction) && (x.denom === 0)) {
            console.warn("Rational::toString(" + x.num + "/" + x.denom + ")\n");
            return false;
        } else if (!(x instanceof Fraction)) {
            console.warn("Non-Rational number passed to Rational::isValid(), " + x + "\n");
            return false;
        } else return true;
    }
}

export default Fraction;