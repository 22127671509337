import Fraction from "../../Fraction";

class HeirsFractions {

    static whole = new Fraction(1, 1);

    static none = new Fraction(0, 1);

    static twothirds = new Fraction(2, 3);

    static half = new Fraction(1, 2);

    static third = new Fraction(1, 3);

    static quarter = new Fraction(1, 4);

    static sixth = new Fraction(1, 6);

    static eighth = new Fraction(1, 8);



    /**
     * Returns a negative rational unit to indicate a share that will be defined by agnation
     */

    static toshare() {

        return new Fraction(-1, 1);

    }

}

export default HeirsFractions;