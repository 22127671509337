import React from 'react';
import styles from "./CheckInput.module.scss";
import './option-input.css';

const OptionInput = props => {

    return (
        <div className={styles.OptionInput}>
            <label>
                <input {...props} className={"option-input"} />
            </label>
        </div>
    );
    
};

OptionInput.defaultProps = {
    type: 'checkbox'
}

export default OptionInput;
