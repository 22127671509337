import React, {useState} from 'react';
import UI from "../../../lib/inheritance/Calculator/Output/UI"
import {Button} from "@mui/material";
import Alert from "./Alert";


const CalculatorSubmit = props => {

    const [openAlert, setOpenAlert] = useState(false);

    const [disableDetails, setDisableDetails] = useState(true);


    return (
        <>
            <div className="submit-tools">
                <Button
                    name="CalculateButton"
                    id="CalculateButton"
                    onClick={() => {
                        setTimeout(() => {
                            setOpenAlert(false);
                        }, 2600);
                        UI.totalUsd = null;
                        UI.clearResTable();
                        if (UI.calculate()) {
                            window.scrollTo(0, 0);
                            setOpenAlert(true);
                            setDisableDetails(false);
                            props.setActiveStep(7);
                        }
                        return true;
                    }}
                    variant={'contained'}
                    style={{backgroundColor: '#002E5D'}}
                >
                    Calculate
                </Button>

                <Button name="DetailsButton" id="DetailsButton" color={"warning"} disabled={disableDetails}
                        onClick={() => {
                            UI.displayResultExplanationNS();
                        }}>
                    Explain Result
                </Button>
                <Button
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    Reset
                </Button>
            </div>
            <Alert open={openAlert} handleOpen={setOpenAlert}/>
        </>
    );

};

export default CalculatorSubmit;
