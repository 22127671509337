import React from 'react';
import styles from "./Result.module.scss";

const Result = () => {

    return (
        <div>
            <h3>Inheritance Calculator Result</h3>
            <table id="res-tbl" className={styles.ResultTable}>
                <thead>
                <tr>
                    <th>Heirs</th>
                    <th>Number</th>
                    <th>Share</th>
                    <th>Total ($)</th>
                </tr>
                </thead>
                <tbody>

                </tbody>
            </table>
        </div>
    );

};

export default Result;
