import React from 'react';
import NumInput from "../NumInput/NumInput";

const Offsprings = props => {

    return (
        <tbody>
        <tr>
            <th>Heirs</th>
            <th>Number</th>
            <th>Share</th>
            <th>Total ($)</th>
        </tr>
        <tr>
            <td>Sons</td>
            <td>
                <NumInput name="num1" id="num1" size="2" tabIndex="1"/>
            </td>
            <td id="get1">&nbsp;</td>
            <td id="get1usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Daughters</td>
            <td>
                <NumInput name="num2" id="num2" size="2" tabIndex="2"/>
            </td>
            <td id="get2">&nbsp;</td>
            <td id="get2usd">&nbsp;</td>
        </tr>
        </tbody>
    );

};

export default Offsprings;
