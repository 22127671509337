import React from 'react';
import styles from "./Footer.module.scss";

const Footer = props => {

    return (
        <footer className={styles.Footer}>
            <span>© 2024 - Muslim.Estate. All Rights Reserved</span>
        </footer>
    );

};

export default Footer;
