import {displays} from "../Output/texts";

class Mazhab {

    static Hanafi = 1;

    static Maliki = 2;

    static Shafii = 3;

    static Hanbali = 4;

    static Egypt = 5;

    static Zahiri = 6;

    static Jaafari = 7;

    static Ibadhi = 8;

    static Zaidi = 9;

    static numschools = 9;

    static schoolNames = [displays["_none_"], displays["_Hanafi_"], displays["_Maliki_"], displays["_Shafii_"], displays["_Hanbali_"], displays["_Egypt_"], displays["_Zahiri_"], displays["_Jaafari_"], displays["_Ibadhi_"], displays["_Zaidi_"]];

}

export default Mazhab;