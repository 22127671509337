import React from 'react';
import NumInput from "../NumInput/NumInput";

const Descendants = () => {

    return (
        <tbody>
        <tr>
            <th>Heirs</th>
            <th>Number</th>
            <th>Share</th>
            <th>Total ($)</th>
        </tr>
        <tr>
            <td>Sons of sons</td>
            <td>
                <NumInput name="num10" id="num10" size="2" tabIndex="10"/>
            </td>
            <td id="get10">&nbsp;</td>
            <td id="get10usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Daughters of sons</td>
            <td>
                <NumInput name="num11" id="num11" size="2" tabIndex="11"/>
            </td>
            <td id="get11">&nbsp;</td>
            <td id="get11usd">&nbsp;</td>
        </tr>

        <tr>
            <td>&nbsp;</td>
        </tr>

        <tr>
            <td>Sons of full brothers</td>
            <td>
                <NumInput name="num19" id="num19" size="2" tabIndex="19"/>
            </td>
            <td id="get19">&nbsp;</td>
            <td id="get19usd">&nbsp;</td>
        </tr>
        <tr>
            <td>Sons of paternal half-brothers</td>
            <td>
                <NumInput name="num20" size="2" tabIndex="20" id="num20"/>
            </td>
            <td id="get20">&nbsp;</td>
            <td id="get20usd">&nbsp;</td>
        </tr>
        </tbody>
    );

};

export default Descendants;
