//Using JSON
/**
 *	A JSON object for cookie functios
 *	Functions: set(name, value, milliseconds), get(name), del(name) and not(name)
 */
export const JSONcookie = {

    set: function (name, value, milli) {
        let expires = "";
        if (milli) {
            var date = new Date();
            date.setTime(date.getTime() + milli);
            expires = "; expires=" + date.toGMTString();
        }
        document.cookie = name + "=" + JSON.stringify(value) + expires + "; path=/";
    },
    get : function(name) {
        var nameEQ = name + "=",
            ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);	//trim left
            if (c.indexOf(nameEQ) === 0)
                return JSON.parse(c.substring(nameEQ.length,c.length));
        }
        return null;
    },
    del : function(name) {
        var date = new Date();
        date.setTime(date.getTime() - 5000*60);		//5 minutes ago
        var expires = "; expires=" + date.toGMTString();
        document.cookie = name + "=" + expires + "; path=/";
    },
    not : function(name) {
        return document.cookie.indexOf(name+"=")<0;
    }

};

