import React, {useState} from 'react';

const OversubscriptionOpt = props => {

    const [selectedOption, setSelectedOption] = useState('1');

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
        <table id="Prefs3">
            <tbody>
            <tr>
                <td>
                    <b>Oversubscription preference:</b>
                    <p>
                        When the sum of distributed shares is <em>more</em> than the estate, how do you prefer to reset
                        the shares?
                    </p>
                </td>
            </tr>
            <tr>
                <td valign="top">
                    <input type="radio"
                           name="Oversub"
                           value="1"
                           checked={selectedOption === '1'}
                           onChange={handleChange}
                    />
                    Redivide all shares by their ratios to each other (Consensus)
                </td>
            </tr>
            <tr>
                <td valign="top">
                    <input type="radio"
                           name="Oversub"
                           value="0"
                           checked={selectedOption === '0'}
                           onChange={handleChange}
                    />
                    Reduce the share of the farthest female heir (Zahiri/Jaafari)
                </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
            </tr>
            </tbody>
        </table>

    );

};

export default OversubscriptionOpt;
