import {Route, Routes} from "react-router";
import MeiCalcForm from "./components/Modern/MeiCalcForm";

function App() {



    const routes = (

        <Routes>


            <Route path="/" element={<MeiCalcForm />}/>

        </Routes>

    );


    return (<>{routes}</>);

}

export default App;
