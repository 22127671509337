import React from 'react';
import Logo from '../../assets/images/logo.png';
import styles from "./Header.module.scss";
import Slide from "@mui/material/Slide";

const Header = props => {

    return (
        <Slide in direction={"down"} mountOnEnter unmountOnExit>
            <header className={styles.MeiHeader}>
                <img src={Logo} alt={'Muslim Estate'}/>
                <h3>Muslim Estate Inheritance Calculator</h3>
            </header>
        </Slide>
    );

};

export default Header;
